import React, { useState, useRef } from 'react';
import { useSetState, useMount } from 'react-use';
import { connect } from 'react-redux';
import { Link, graphql } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import { filter, find, forEach } from 'lodash'
import Form from 'react-dynamic-form'
import ProgressiveImage from "react-progressive-image"

import { Layout, MetaTags, Input } from '../components'

import { fetchUser, updateUser, getUser } from '../store/user'
import { getCart, fetchCart } from '../store/cart';
import { postData } from '../services/api';

import { container, padding, bgIcon, button } from '../styles/global';
import { media, isClient } from '../styles/utils';
import { black } from '../styles/colors';
import { inputStyle, red, error } from '../components/Input/Input';
import { handleResetPassword } from '../services/auth';
import { required, password } from '../utils/validators';

const ResetPassword = (props) => {
	const resetPasswordForm = useRef(null);

	const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
        error: false,
        serverMessage: ''
	})

	useMount(() => {
        props.fetchUser()
	})

	const renderSidebar = () => {
		return (
			<Sidebar>
			</Sidebar>
		)
	}

	const handleSubmit = (fields, resetForm) => {
        const { cart } = props;

        const cred = {
            username: fields.username.value
        }

		updateForm({submitting: true})

        handleResetPassword(cred)
            .then(response => {  

                if (response.error) {                    
                    updateForm({
                        error: response.message,
                        submitting: false
                    })

                    resetForm()

                } else {

                    updateForm({
                        serverMessage: response.message,
                        submitting: false
                    })

                    resetForm()

                }
        }) 
	}
	
	const renderForm = () => {
        const { error, submitting, formComplete, serverMessage } = formState;
        
        const resetPasswordFields = {
			fields: [
				{
					type: 'text',
					name: 'username',
					label: 'Username',
					validator: required,
					errorMessage: 'This field is required',
				}
			]
		}
		
		return (
			<FormWrapper>
				<Form
					ref={resetPasswordForm}
					data={resetPasswordFields}
					renderInput={<Input/>}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleSubmit}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					theme={'outline-black'}
					onClick={() => {
						resetPasswordForm.current && resetPasswordForm.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Reset Password'}
				</Button>

                <Info>{serverMessage}</Info>

			</FormWrapper>
		)
	}

	return (
        <Layout
            footer={true}
        >
			<Container>
				<Heading>Reset Password</Heading>
				<Content>
					{renderSidebar()}
					{renderForm()}
				</Content>
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``


const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	bgImage;
	transition: opacity 0.45s ease;
`

const Button = styled.div`
    ${button}
`


// Layout

const Container = styled.div`
	${container}
	${padding}
	max-width: 940px;
	flex-direction: column;


	> ${Heading} {
		word-spacing: 100vw;
		font-size: 70px;
		font-weight: 500;
		line-height: 71px;
		margin-top: 109px;
		padding-left: 40%;
	}
`

const Content = styled.div`
	display: flex;
	margin-top: 98px;
	padding-bottom: 155px;
`

// Sidebar

const Sidebar = styled.div`
	flex: 1 0 calc(50% - 20px);
	margin-right: 40px;

	${Heading} {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 5px;
	}	
`

// Form

const FormWrapper = styled.div`
	flex: 1 0 calc(50% - 20px);
	display: flex;	
	flex-direction: column;	

	${Button} {
		margin-top: 29px;
	}

    ${Info} {
		margin-top: 20px;
	}

	${media.tablet`
		max-width: calc(100vw - 350px);
	`}

	${media.phone`
		max-width: none;
	`}
`

const Section = styled.div`
	width: 100%;
	margin-bottom: 42px;	

	${Heading} {
		font-size: 20px;
		font-weight: 500;
	}	
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
        width: 100%;
        
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 17px;

		&.terms {
			.field-wrapper {
				border: none !important;
			}
		}
	}
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
`

const ThankYou = styled.div`
	margin-top: 32px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
    background-image: url(${require('../assets/icons/loading.svg').default});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`


export const query = graphql`
	query {
		wp {
			options {
				pageSlug
				pageTitle
				footerMenu {
					footerMenu {
						title
						link {
							title
							url
						}
					}
				}
				tradeApplicationOptions {
					tradeApplicationNotificationEmail
					tradeApplicationUseAdminNotificationEmail
				}
				tradeLogin {
					loginText
				}
			}
		}
	}
`

const mapDispatchToProps = dispatch => ({
	fetchCart() {
        dispatch(fetchCart())
    },
	fetchSettings() {
        dispatch(fetchSettings())
    },
    fetchUser() {
        dispatch(fetchUser())
    },
    updateUser(data) {
        dispatch(updateUser(data))
	},
})

const mapStateToProps = (state, props) => ({
	...getCart(state),
	...getUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
