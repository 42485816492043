import React from 'react'
import styled from 'styled-components'
import { forEach, filter, find } from 'lodash'

import { toTitleCase } from './utils';
import { media } from '../../styles/utils';

class CollectionSelectors extends React.Component {

	state = {
		activeSelected: null,
		options: [],
	}

	componentWillMount() {
		this.setInitialSelectState()
	}

	setInitialSelectState = () => {
		const { current, products } = this.props;

		let currLength = current.title?.split(' ').find(word => word.indexOf('mm') !== -1)
		let currFinish = current.title?.split('-')[1]?.trim()

		const options = products.map(product => {
			let length = product.title?.split(' ').find(word => word.indexOf('mm') !== -1)
			let finish = product.title?.split('-')[1]?.trim()

			return {
				product,
				length,
				finish,
			}
		})

		this.setState({
			options,
			activeSelected: [currLength, currFinish]
		})
	}

	handleSelectChange = (name, value) => {
		const { options, activeSelected } = this.state;

		let currLength = activeSelected[0]
		let currFinish = activeSelected[1]

		if (name === 'length') {
			currLength = value
		}
		if (name === 'finish') {
			currFinish = value
		}
		
		const selected = options.find(option => option.length === currLength && option.finish === currFinish)

		if (selected) this.props.onChange(selected)
	}

	renderOptions = () => {
		const { activeSelected, options } = this.state;

		return ['length', 'finish'].map((key, keyIndex) => {
			const selectState = activeSelected[keyIndex] || false;

			// Get all unique options for key in options
			const selectOptions = options.map(e => e[key]).filter((v,i,a) => a.indexOf(v) === i).map((option, i) => {

				return (
					<option
						key={i}
						value={option}
					//disabled={!noStock && variantMatch.length ? false : true}
					>
						{option}
						{/* {noStock ? ' — Out of stock' : ''} */}
					</option>
				)
			})

			return (
				<SelectContainer
					key={keyIndex}
				>
					<Title>
						{selectState ? `${toTitleCase(key)} - ${selectState}` : `Select a ${toTitleCase(key)}`}
					</Title>

					<Select
						value={selectState}
						onChange={(e) => this.handleSelectChange(key, e.target.value)}
					>
						<option
							disabled={!selectState}
							value={false}
						>
							{!selectState ? `Select a ${toTitleCase(key)}` : `Reset selection`}
						</option>

						{selectOptions}
					</Select>
				</SelectContainer>
			)
		})
	}

	render() {
		return (
			<Container>
				{this.renderOptions()}
			</Container>
		)
	}
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
		margin: 0 0 10px;
`

// Shared

const Title = styled.div``

// Selects

const SelectContainer = styled.div`
	position: relative;
	height: 44px;
	display: flex;
	align-items: center;

	border: 1px solid #9F9F9F;
	border-radius: 0;
	padding-top: 1px;
	padding-left: 16px;
	font-size: 16px;
	
	background: white;
	box-sizing: border-box;
	user-select: none;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translateY(-50%);
		height: 8px;
		width: 8px;
		background-image: url(${require('../../assets/icons/select-arrow.svg').default});
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	${media.phone`
		max-width: none;
	`}
`

const Select = styled.select`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0; 
	padding: 0;
	min-height: 44px;

	&:focus {
		outline: none;
	}
`

export default CollectionSelectors

