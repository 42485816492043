import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate, Link, useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { useMount } from 'react-use';

import { isAuthenticated, logout  } from '../../services/auth'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { black } from '../../styles/colors';

const Footer = (props) => {
    const graphData = useStaticQuery(query)
    const data = {} // JSON.parse(graphData.wordpressGlobal.acf_json)

    const scrollToTop = () => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }
    
    const renderFooterNav = () => {    
        const items = data.footer_menu && data.footer_menu.map((item, i) => {

            if(item.url.includes('mailto:') || item.url.includes('https:') || item.url.includes('http:')){ 

                return (
                    <NavItemExternal 
                        key={i}
                        href={item.url}
                    >
                        {item.title}
                    </NavItemExternal>
                )

            } else {

                return (
                    <NavItem 
                        key={i}
                        to={item.url}
                    >
                        {item.title}
                    </NavItem>
                )
            }

            
        })
        return (
            <FooterNav>
                {items}

                {!isAuthenticated() && false && (
                    <NavItem
                        to={'/trade-application'}
                    >
                        Trade Application
                    </NavItem>
                )}
                
                {!isAuthenticated() && false && (
                    <NavItem
                        to={'/login'}
                    >
                        Trade Login
                    </NavItem>
                )}

                {isAuthenticated() && (
                    <NavItem
                        to={'/account'}
                    >
                        Account
                    </NavItem>
                )}

                {isAuthenticated() && (
                    <NavItem
                        onClick={() => logout()}
                        to={'/'}
                    >
                        Logout
                    </NavItem>
                )}
                
            </FooterNav>
        )
    }
    
	return (
        <Wrapper>
            <Container
                extendWidth={props.footerExtendWidth}
            >
                <BackToTop
                    onClick={scrollToTop}
                >
                    Back to top
                </BackToTop>
                {renderFooterNav()}
            </Container>
        </Wrapper>
    )
}


// Layout

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;

    ${media.tablet`
        display: none;
    `}
`

const Container = styled.div`
    ${padding}
    max-width: calc(940px + 30px);
    width: 100%;
    font-size: 18px;
    display: flex;

    ${props => {
        if (props.extendWidth) return css`
            max-width: calc(1280px + 30px);

            ${media.desktopSmall`
                max-width: calc(940px + 30px);
            `}
        `
    }}
`

// Back To Top

const BackToTop = styled.div`
    margin-right: auto;
    cursor: pointer;
`

// Footer Nav

const FooterNav = styled.div``
const NavItem = styled(Link)`
    &:not(:last-child) {
        margin-right: 49px;
    }
`
const NavItemExternal = styled.a`
    &:not(:last-child) {
        margin-right: 49px;
    }
`


const query = graphql`
    query FooterQuery {
        wp {
            options {
                pageSlug
                pageTitle
                footerMenu {
                    footerMenu {
                        title
                        link {
                            title
                            url
                        }
                    }
                }
                tradeApplicationOptions {
                    tradeApplicationNotificationEmail
                    tradeApplicationUseAdminNotificationEmail
                }
                tradeLogin {
                    loginText
                }
            }
        }
    }
`

export default Footer
