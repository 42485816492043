import React, {Component} from 'react'
import styled from 'styled-components'
import { media } from '../../styles/utils'

import { Input, Wrapper, Error } from '../InputText/InputText'

class InputTel extends Component {

    render() {
        return (
            <Wrapper>
                <Input
                    className={this.props.className}
                    type="email"
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChangeValue}
                    onBlur={this.props.onBlur}
                    error={this.props.error && this.props.showErrors}
                    onKeyPress={this.props.onKeyPress}
                    required
                />

                {this.props.error && (
                    <Error>
                        {this.props.errorMessage}
                    </Error>
                )}
            </Wrapper>
        )
    }
}

export default InputTel;
