import React from "react"

const defaults = {
    headerTheme: 'black',
    headerBelow: false,
    splashActive: true,
    setHeaderContext: () => {},
}

export const HeaderContext = React.createContext({
    ...defaults,
});

class ContextWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    setHeaderContext = (context) => {
        this.setState({
            ...this.state,
            ...context
        })
    }

    state = {
        ...defaults,
        setHeaderContext: this.setHeaderContext,
    }

	render() {
		const { children } = this.props
	
		return (
			<HeaderContext.Provider
                value={this.state}
            >
                {children}
            </HeaderContext.Provider>
		)
	}
}

export function withHeader(Component) {
    return function ThemeComponent(props) {
        return (
            <HeaderContext.Consumer>
                {context => <Component {...props} {...context} />}
            </HeaderContext.Consumer>
        )
    }
}

export default ContextWrapper