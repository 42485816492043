import React, { Component } from 'react'
import { required, email, phone, number, password } from '../utils/validators'
import { getBillingShippingFields } from './checkout';

export const editDetails = {
    sections: [
        {
            fields: [
                {
                    type: 'firstName',
                    name: 'firstName',
                    label: 'First Name',
                    validator: required,
                    errorMessage: 'Please enter a valid name',
                },
                {
                    type: 'lastName',
                    name: 'lastName',
                    label: 'Last Name',
                    validator: required,
                    errorMessage: 'Please enter a valid name',
                },
                {
                    type: 'email',
                    name: 'email',
                    label: 'Email',
                    validator: email,
                    errorMessage: 'Please enter a valid email',
                },
            ]
        },
        {
            heading: 'Change Password (Optional)',
            fields: [
                {
                    type: 'password',
                    name: 'currentPassword',
                    label: 'Current Password',
                    validator: (value, fields) => {
                        if (!value) return true;
                        if (value) return password(value)
                    },
                    errorMessage: 'Must be at least 8 characters, with a symbol, upper, lower case letters and a number',
                },
                {
                    type: 'password',
                    name: 'newPassword',
                    label: 'New Password',
                    validator: (value, fields) => {
                        if (!value) return true;
                        if (value) return password(value)
                    },
                    errorMessage: 'Must be at least 8 characters, with a symbol, upper, lower case letters and a number',
                },
        
            ]
        },
    ]
}

export const editAddress = {
    sections: [
        {
            heading: 'Billing Fields',
            fields: [
                ...getBillingShippingFields('billing'),
            ],
        },
        {
            heading: 'Shipping Fields',
            fields: [
                ...getBillingShippingFields('shipping'),
            ],
        },
    ]
}