import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { maxWidth, indentedBlock } from '../../../pages/studio';

class BlockText extends React.Component {

	render() {
		const { content, columns } = this.props;

		return (
			<Container>
				<Text
					columns={columns || 2}
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</Container>
		)
	}
}

const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${padding};
	${container}
`

const Text = styled.div`
	font-size: 20px;
	line-height: 24px;
	${indentedBlock}

	${props => css`
		columns: ${props.columns};
	`}

	${media.phone`
		font-size: 16px;
		line-height: 22px;
		columns: 1;
	`}

`

BlockText.wrapper = css`
	display: flex;
	justify-content: center;
`

export default BlockText
