import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css, createGlobalStyle } from 'styled-components';
import { shuffle, forEach, sample } from 'lodash';
import { motion } from 'framer-motion'
import ProgressiveImage from "react-progressive-image";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useStaticQuery, graphql } from "gatsby"
import prefetchImages from 'prefetch-image';

import { BackgroundImage } from '../../components'

import { updateQuantity } from '../../store/cart';
import { hoverState, bgImage } from '../../styles/global';
import { withHeader } from '../Header/HeaderContext';
import { isClient } from '../../styles/utils';
import { black } from '../../styles/colors';

class Splash extends Component {

    state = {
        splashActive: false,
        mounted: false,
    }

    shouldHide = () => {
        const excludeSplash = ['/product/', '/products/', '/preview/', '/checkout']
        const pathName = window.location.pathname;

        let hide = false;

        forEach(excludeSplash, (route) => {
            if (pathName.includes(route)) hide = true
        })

        return hide
    }

    componentDidMount() {
        const { data } = this.props;

        if (isClient()) {
            let splash = document.getElementById('splash')

            if (sessionStorage.introSeen == 'true' || this.shouldHide()) {
                if (splash) {
                    splash.classList.add('transition')
                    splash.classList.remove('active')
                }

                this.props.setHeaderContext({
                    splashActive: false
                })

                setTimeout(() => {
                    sessionStorage.introSeen = true;
                }, 650);

                enableBodyScroll()
            } else {
                const images = shuffle(data.allWpProject.nodes.map(node => node.heroImage?.heroImage?.mediaItemUrl).filter(el => el));

                this.setState({
                    image: sample(images)
                })

                if (splash) {
                    splash.classList.add('active');
                }

                setTimeout(() => {
                    this.setState({
                        splashActive: true
                    })
                }, 3000)

                // this.prefetchImages()
                disableBodyScroll()
            }
        }

        this.timer = setTimeout(
            this.seenSplash,
            1000,
        )
    }

    prefetchImages = () => {
        const { data } = this.props;
        const images = data.allWpProject.nodes.map(node => node.heroImage?.heroImage?.mediaItemUrl).filter(el => el);

        // Prefetch and hide splash on complete after 1s

        prefetchImages(images)
    }

    seenSplash = () => {
        if (sessionStorage.introSeen !== 'true') {

            clearInterval(this.timer);

            let splash = document.getElementById('splash')

            if (splash) {
                splash.classList.add('transition')
                splash.classList.remove('active')
            }

            this.props.setHeaderContext({
                splashActive: false
            })

            setTimeout(() => {
                sessionStorage.introSeen = true;
            }, 650);

            enableBodyScroll()
        }
    }

    renderLogo = () => {
        const { splashActive } = this.state;

        return (
            <LogoWrapper>
                <Logo
                    to={'/'}
                />
                { /*
                <LogoLine
                    active={splashActive}
                    {...animatedLogoLine}
                />
        */ }
            </LogoWrapper>
        )
    }

    renderImage = (data) => {
        const { image, splashActive } = this.state;
        if (!image) return;

        return (
            <BGImage
                as={BackgroundImage}
                image={image}
                width={'3000px'}
                disableSrcSet
                disableQualityByDPR
                fadeIn
                style={{
                    transform: splashActive ? 'scale(1.0)' : 'scale(1.02)',
                }}
            />
        )
    }

    render() {
        const { splashActive } = this.state;

        return (
            <>
                <SplashStyles />
                <Wrapper
                    id={'splash'}
                    active={splashActive}
                >
                    {this.renderLogo()}
                    {this.renderImage()}
                </Wrapper>
            </>
        )
    }

}

// Layout

const Wrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${black};
    z-index: 20;
    opacity: 0;
    pointer-events: none;
`

const SplashStyles = createGlobalStyle`
    #splash {
	    opacity: 0;
        pointer-events: none;
        
		&.active {
            opacity: 1;
            pointer-events: all;
		}

		&.transition {
            transition: opacity 0.65s ease;
		}
    }
`

// Logo

const Logo = styled.div`	
	display: flex;
	background-image: url(${require('../../assets/images/ARCHIER_LOGO_WHITE-2024.svg').default});
	width: 214px;
	height: 37px;
	${bgImage}
	cursor: pointer;
	z-index: 2;
`

const animatedLogoLine = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.85,
            yoyo: Infinity,
            ease: 'easeInOut'
        }
    },
}


const LogoLine = styled(motion.div)`
	width: 28px;
	height: 6px;
	background: white;

	position: absolute;
	right: 57px;
	bottom: 30px;

	transition: transform 0.4s ease;
    z-index: 1;
    transform: translateY(0); 
`

const LogoWrapper = styled.div`
	position: absolute;
	left: 30px;
	bottom: 0;
	padding-bottom: 30px;
	overflow: hidden;
	z-index: 2;
`

// Image

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: 
        opacity 1s ease,
        transform 2s ease !important;

    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

const query = graphql`
    query SplashQuery {
        allWpProject {
            nodes {
                heroImage {
                    heroImage {
                        mediaItemUrl
                    }
                }
            }
        }
    }
`

export const renderSplashWithData = () => {
    const data = useStaticQuery(query);
    const Component = withHeader(Splash)
    return (
        <Component
            data={data}
        />
    )
}

export default renderSplashWithData;
