import { 
    BlockText,
    BlockTextGrid,
    BlockImages,
    BlockImageSingle,
    BlockTitle,
    BlockAwardsList,
    BlockImageText,
    BlockFAQ,
    BlockProductImages,
    BlockAccordion,
    BlockEmbed,
    BlockHeroImage,
} from './templates'

import Block from './templates/BlockTemplate';

export const resolveBlock = (layout) => {

    //console.log(layout)
    let block;

    // Map layout strings to blocks

    let key = layout
    if (key.indexOf('Template_') > -1 || key.indexOf('WpProject_') > -1) {
        let keys = key.split('_')
        key = keys[keys.length - 1].toLowerCase()
    }

    //console.log(key)

    const blocks = {
        'text': BlockText,
        'image': BlockImageSingle,
        'images': BlockImages,
        'title': BlockTitle,
        'heading': BlockTitle,
        'textgrid': BlockTextGrid,
        'awardslist': BlockAwardsList,
        'image_text': BlockImageText,
        'imagetext': BlockImageText,
        'fullwidthimage': BlockImageText,
        'full_width_image': BlockImageText,
        'productimages': BlockProductImages,
        'product_images': BlockProductImages,
        'faq': BlockFAQ,
        'accordion': BlockAccordion,
        'typeform': BlockEmbed,
        'heroimage': BlockHeroImage,
    }

    return blocks[key]
}


