import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import { BackgroundImage } from '../../../components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { maxWidth } from '../../../pages/studio';
import { getImageCrop } from '../../../utils';

class BlockImages extends React.Component {

	state = {
	
	}
	
	render() {
        const { largeImage, smallImage, position, useSmallImage } = this.props;

        return (
            <Container
                position={position}
            >
                {/* Small Image */}

                {useSmallImage && smallImage.mediaItemUrl && (
                    <BGImage
                        as={BackgroundImage}
                        image={smallImage.mediaItemUrl}
                        width={'400px'}
                        height={'600px'}
                        order={position == 'small-right' && 2}
                        size={'small'}
                        disableSrcSet
                        disableQualityByDPR
                   />
                )}

                {/* Large Image */}

                {largeImage?.mediaItemUrl && (
                    <BGImage
                        as={BackgroundImage}
                        image={largeImage.mediaItemUrl}
                        size={'large'}
                        width={'1000px'}
                        disableSrcSet
                        disableQualityByDPR
                    />
                )}
            </Container>
		)
	}
}

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    /* Alignment  */

    ${props => {
        if (props.position == 'small-right') return css`
           justify-content: flex-start;
        `
    }}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    transition: opacity 0.45s ease;
    width: 100%;

    /* Size */

    ${props => {
        if (props.size == 'small') return css`
            max-width: 22vw;
            padding-bottom: 35%;
            margin-right: 30px;

            ${media.phone`
                margin-right: 10px;
            `}
        `
    }}

    ${props => {
        if (props.size == 'large') return css`
            max-width: 65vw;
            padding-bottom: 46%;
        `
    }}

    /* Order */

    ${props => {
        if (props.order == 2) return css`
            order: 2;
            margin-left: 30px;
            margin-right: 0;

            ${media.phone`
                margin-left: 10px;
            `}
        `
    }}

    ${media.tablet`
        padding-bottom: 66%;
        max-width: none;
    `}
`

BlockImages.wrapper = css`
    ${media.tablet`
        margin-bottom: 32px;
    `}

    ${media.phone`
        margin-bottom: 20px;
    `}
`

export default BlockImages
