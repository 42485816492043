import { combineReducers } from 'redux'
import localForage from 'localforage'

// Fetch Settings   

const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

let defaultData = null

const user = (state = null, action) => {
    switch (action.type) {
        case FETCH_USER_SUCCESS:
            return action.response  

        default: 
            return state
    }  
}

const reducer = combineReducers({
    user
})

export const fetchUser = () => dispatch => {
    dispatch({
        type: FETCH_USER_REQUEST
    })

    localForage.getItem('user')
        .then(user => {
            dispatch({
                type: FETCH_USER_SUCCESS,
                response: user || defaultData
            }) 
        })
}

export const updateUser = (data) => dispatch => {
    
    dispatch({
        type: FETCH_USER_REQUEST
    })

    defaultData = {
        ...defaultData,
        ...data,
    }
    
    const newData = Object.assign({}, defaultData, data)

    // Save new data to local storage

    localForage.setItem('user', newData)

    dispatch({
        type: FETCH_USER_SUCCESS,
        response: newData
    })
}


export const getUser = state => state.user

export default reducer

