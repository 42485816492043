import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { forEach, range } from 'lodash'
import ProgressiveImage from "react-progressive-image";
import { motion, AnimatePresence } from 'framer-motion'

import { Layout, MetaTags } from '../components'

import { container, padding, bgImage, hoverState } from '../styles/global';
import { grey } from '../styles/colors';
import { feedGroups } from '../data/in-progress';
import { indentedBlock } from '../pages/studio';
import { media } from '../styles/utils';

const InProgress = (props) => {
	let instagram = [...props.pageContext.feed]
	//JSON.parse(props.data.allWordpressInprogress.nodes[0].feed_json)
	const [activeImage, setActiveImage] = useState(false)
	
	const renderPageTitle = () => {
		return (
			<HeroSection
				key={'insta-link'}
				{...animatedHeroSection}
			>
				<Heading>Works In Progress.</Heading>
				<InstaLink
					href={'https://www.instagram.com/archier_/?hl=en'}
					target={'_blank'}
				>
					@archier_
				</InstaLink>
			</HeroSection>
		)
	}

	const renderImageCaption = () => {
		return (
			<HeroSection
				key={activeImage.id}
				{...animatedHeroSection}
			>
				<Heading
					dangerouslySetInnerHTML={{__html: activeImage.caption}}
				/>
			</HeroSection>
		)
	}

	const renderHero = () => {
		return (
			<Hero>
				<AnimatePresence
					exitBeforeEnter={true}
				>
					{activeImage ? renderImageCaption() :renderPageTitle()}
				</AnimatePresence>
			</Hero>
		)
	}

	const renderImage = (image) => {
		if (!image) return;

		return (
			<Image 
				key={image.id}
				href={image.permalink}
				target={'_blank'}
				image={image.media_url} 
				onMouseEnter={() => setActiveImage(image)}
				onMouseLeave={() => setActiveImage(false)}
				fade={activeImage && activeImage.id !== image.id}
			/>
		)
	}

	const renderGroups = () => {
		const items = feedGroups.map((group, i) => {
			const images = range(group.maxImages).map(item => {
				return renderImage(instagram.shift())
			})

			return (
				<Group
					key={i}
					style={group.style}
				>
					{images}
				</Group>
			) 
		})
		
		return (
			<Feed>{items}</Feed>
		)
	}

	return (
		<Layout
			footer={true}
			meta={{
				title: 'In Progress - Archier'
			}}
		>
			<Wrapper>
				{renderHero()}
				{renderGroups()}
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``

const Image = styled.a`
    transition: opacity 0.4s ease;
    background-color: rgba(0,0,0,0.1);
	background-image: url(${props => props.image});
	${bgImage}

	width: 320px;
	height: 320px;
	margin: 0 20px;

	${props => {
		if (props.fade) return css`
			opacity: 0.5 !important;
		`
	}}

	${media.tablet`
		width: auto;
		flex: 0 1 calc(50% - 5px);
	`}

	${media.phone`
		width: auto;
		height: 240px;
		margin-bottom: 24px;
		flex: 0 1 calc(100%);
	`}
`

// Layout

const Wrapper = styled.div`
    flex: 1;
    display: flex;
	flex-direction: column;
	padding-bottom: 180px;

	${media.tablet`
		${padding}
		padding-bottom: 48px;
	`}
`

// Hero

const InstaLink = styled.a`
	${hoverState}
`

const Hero = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;

	font-size: 32px;
	line-height: 38px;

	${indentedBlock}

	position: fixed;
	top: 24px;
	z-index: 10;

	${media.tablet`
		position: relative;
	`}
`

// Hero Section (Animated)

const animatedHeroSection = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.25,
		}
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.15,
		}
	},
}

const HeroSection = styled(motion.div)`
	${Heading} {
		max-width: 577px;
	}
`


// Feed

const Feed = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin-top: 180px;

	${media.tablet`
		flex-flow: column nowrap;
		margin-top: 64px;
	`}
`

// Group

const Group = styled.div`
	display: flex;
	justify-content: center;
	flex: 1 0 100%;
	box-sizing: border-box;

	${media.tablet`
		margin-top: 11px !important;
		padding: 0 !important;
		justify-content: space-between !important;
	`}
`

export const query = graphql`
	query InProgress {
		wpPage(slug: { eq: "in-progress" } ) {
			title
		}
	}
`

export default InProgress
