import React from 'react'
import styled from 'styled-components'

import { container, padding } from '../../../styles/global'
import { indentedBlock } from '../../../pages/studio';
import { PopupButton, Widget } from '@typeform/embed-react'

const BlockEmbed = (props) => {
    const { typeformFormId, redirectTarget } = props

    const renderTypeForm = () => {
        return (
            <Wrap>
                <Widget
                    className={'typeform-embed'}
                    redirectTarget={redirectTarget || '_blank'}
                    id={typeformFormId}
                    style={{ width: '100%' }}
                />
            </Wrap>
        )
    }

    //return renderTypeForm()

    const renderPopupButton = () => {
        return (
            <PopupButton
                id={typeformFormId}
                redirectTarget={redirectTarget || '_blank'}
                //open='load'
                className="typeform-button">
                Submit Enquiry
            </PopupButton>
        )
    }


    return (
        <Container>
            <Inner>
                {typeformFormId && renderPopupButton()}
            </Inner>
        </Container>
    )
}

const Wrap = styled.div`
    .typeform-embed {
        height: calc(100vh - 50px);
        width: calc(100vw - 50px);
        position: fixed;
        top: 25px;
        left: 0;
        right: 0;
        z-index: 40;
        border-radius: 0;
    }
`

const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${padding};
	${container};

    .typeform-button {
        border: 2px solid #000;
        padding: 12px 16px 10px;
        border-radius: 2px;
        font-weight: 500;
        font-size: 24px;
        background: none;
        cursor: pointer;
        color: #000;
        transition: all 0.21s ease;
        font-family: 'Baestica';

        &:hover {
            background: #000;
            color: #fff;
        }

        &:active {
            background: #333;
        }
    }
`

const Inner = styled.div`
	${indentedBlock}
`


export default BlockEmbed
