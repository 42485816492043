import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';

import { Block } from '../../'

import { media, isClient } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { brownGrey } from '../../../styles/colors';

class BlockAccordion extends React.Component {

	state = {
	
	}


	renderSections = () => {
		const { content, inAccordion, collapsed } = this.props;
		if (!content.length) return
		
		const sections = content.map((section, i) => {
			return (
				<Section
					key={i}
				>
					<SectionHeader
						size={section.size}
						inAccordion={inAccordion}
						className={'accordion-header'}
					>
						<Description>
							<Heading>{section.heading}</Heading>
						</Description>

						<AccordionItemState>
							{({expanded}) => expanded ? '–' : '+'}
						</AccordionItemState>
					</SectionHeader>
			
					<SectionPanel
						className={'accordion-content'}
						key={`${i}_${section.heading}`}
					>
						<Description
							dangerouslySetInnerHTML={{__html: section.content}}
						/>
					</SectionPanel>
				</Section>
			)
		})

		return (
			<Accordion
				allowMultipleExpanded={true}
				allowZeroExpanded={true}
			>
				{sections}
			</Accordion>
		)
	}
	
	render() {
		return (
            <>
				{this.renderSections()}
			</>
		)
	}
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
`

// Section

const Section = styled(AccordionItem)`
	display: flex;
	flex-direction: column;
`

const SectionHeader = styled(AccordionItemButton)`
	border-top: 1px solid black;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 5px;	
	user-select: none;
	cursor: pointer;
	
	&:focus {
		outline: none;
	}
`

const SectionPanel = styled(AccordionItemPanel)`
	${Description} {
		padding-top: 6px;
		padding-bottom: 20px;
		font-size: 14px;
	}
`

const Toggle = styled.div``

BlockAccordion.wrapper = css`

`

export default BlockAccordion
