import { css } from 'styled-components'

export const feedGroups = [
    {
        maxImages: 2,
    },
    {
        maxImages: 2,
        style: {
            flex: '0 1 50%',
            marginTop: 170,
        }
    },
    {
        maxImages: 2,
        style: {
            flex: '0 1 50%',
            justifyContent: 'flex-end',
            marginTop: 127,
        }
    },
    {
        maxImages: 2,
        style: {
            flex: '0 1 70%',
            justifyContent: 'flex-end',
            marginTop: 147,
            paddingRight: 128,
        }
    },
    {
        maxImages: 1,
        style: {
            flex: '0 1 30%',
            marginTop: 48,
            paddingRight: 128,
        }
    },
    {
        maxImages: 2,
        style: {
            // flex: '0 1 50%',
            marginTop: 180,
        }
    },
]	