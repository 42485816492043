import { combineReducers } from 'redux'
import settings from './settings'
import user from './user'
import { default as cart } from './cart'
import modal from './modal'

const reducer = combineReducers({
    settings,
    cart,
    modal,
    user
})

export default reducer
