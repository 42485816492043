import React from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image";

import { Layout, CartIcon, BackgroundImage } from '../components'

import { padding, bgImage } from '../styles/global';
import { grey } from '../styles/colors';
import { media } from '../styles/utils';

class Products extends React.Component {

    matchProducts = (ref) => {
        if (!ref) return [];
        const { allShopifyCollection } = this.props.data;
        
        if (ref.__typename == 'WpTemplate_Products_Productlisting_Listing_DoubleRow') {
            return [
                allShopifyCollection.nodes.find(node => node.title.indexOf(ref.product1.title) !== -1),
                allShopifyCollection.nodes.find(node => node.title.indexOf(ref.product2.title) !== -1)
            ].filter(item => item)
        }

        if (ref.__typename == 'WpTemplate_Products_Productlisting_Listing_SingleRow') {
            return [
                allShopifyCollection.nodes.find(node => node.handle == ref.product.slug || node.title == ref.product.title),
            ].filter(item => item)
        }
        return []
    }

    renderMobileHeader = () => {
        return (
            <MobileHeader>
                Products
            </MobileHeader>
        )
    }

    renderProductGrid = () => {
        const { allShopifyCollection, wpPage } = this.props.data;
        if (!allShopifyCollection.nodes.length) return

        const items = wpPage?.template?.productListing?.listing?.map((row, i) => {
            const layout = row.__typename == 'WpTemplate_Products_Productlisting_Listing_DoubleRow' ? 'half' : 'full-width';

            const gridItems = this.matchProducts(row)

            return gridItems.map((item, i) => {

                if (!item?.products?.length > 0) return;

                let uri = `/collection/${item.handle}`
                if (item.products.length === 1 || true) {
                    uri = `/product/${item.products[0].handle}`
                }

                return (
                    <GridItem
                        key={i}
                        to={uri}
                        layout={layout}
                    >
                        <BGImage 
                            as={BackgroundImage}
                            image={item.image?.src || item.products[0].featuredImage?.src} 
                            width={'940px'}
                        />

                        <Heading>{item.title}</Heading>
                        <Subheading>{item.description}</Subheading>
                    </GridItem>
                )
            })
        })

        return (
            <Grid>
                {items}
            </Grid>
        )
    }
	
	render() {       
		return (
			<Layout
                meta={{
                    title: 'Store - Archier'
                }}
                footer={true}
            >
				<Wrapper>
                    <CartIcon/>
                    {this.renderMobileHeader()}
					{this.renderProductGrid()}
				</Wrapper>
			</Layout>	
		)
	}
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    transition: opacity 0.45s ease;
    background-color: rgba(0,0,0,0.1);
	background-image: url(${props => props.image});
	${bgImage}
`

// Layout

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;

    ${media.tablet`
        padding-bottom: 64px;
        flex-direction: column;
        ${padding}
    `}
`

// Mobile Header

const MobileHeader = styled.div`
    font-size: 28px;
    font-weight: 500;
    margin: 40px 0 32px;
    display: none;

    ${media.tablet`
        display: flex;
    `}
`

// Grid

const Grid = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 940px;
    width: 100%;
    margin-top: 158px;

    ${media.phone`
        flex-flow: column nowrap;
        margin-top: 0px;
    `}
`

const GridItem = styled(Link)`
    display: flex;
    flex-direction: column;
    margin-bottom: 84px;

    ${Heading} {
        margin-top: 20px;
        font-size: 28px;
        font-weight: 500;
    }

    ${Subheading} {
        font-size: 20px;
        color: ${grey};
        margin-top: 6px;
    }

    ${BGImage} {
       width: 100%;
       height: 625px;
    }

    ${props => {
        if (props.layout == 'half') return css`
            flex: 0 1 calc(50% - 13.5px);
        `
    }}

    ${props => {
        if (props.layout == 'full-width') return css`
            flex: 0 1 100%;
        `
    }}

    ${media.tablet`
        margin-bottom: 38px;
        
        ${BGImage} {
            height: 350px;
        }
    `}

    ${media.phone`
        margin-right: 0;

        ${BGImage} {
            height: 272px;
        }
    `}
`

export const query = graphql`
	query ProductsQuery {


        wpPage(slug: {eq: "products"}) {
            title
            template {
              ... on WpTemplate_Products {
                productListing {
                  listing {
                    __typename
                    ... on WpTemplate_Products_Productlisting_Listing_SingleRow {
                      product {
                        ... on WpProduct {
                          title
                          slug
                        }
                      }
                    }
                    ... on WpTemplate_Products_Productlisting_Listing_DoubleRow {
                      product1 {
                        ... on WpProduct {
                          title
                          slug
                        }
                      }
                      product2 {
                        ... on WpProduct {
                          title
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        allShopifyCollection {
            nodes {
                handle
                title
                description
                image {
                    src
                }
                products {
                    id
                    title
                    handle
                    description
                    featuredImage {
                        src
                    }
                }
            }
        }
        wp {
            options {
                pageSlug
                pageTitle
                footerMenu {
                    footerMenu {
                        title
                        link {
                            title
                            url
                        }
                    }
                }
                tradeApplicationOptions {
                    tradeApplicationNotificationEmail
                    tradeApplicationUseAdminNotificationEmail
                }
                tradeLogin {
                    loginText
                }
            }
        }
	}
`

export default Products
