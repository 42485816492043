import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'


class InputText extends Component {

    render() {
        return (
            <Wrapper>
                <Input
                    className={this.props.className}
                    type="text"
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onBlur={this.props.onBlur}
                    onChange={this.props.onChangeValue}
                    error={this.props.error && this.props.showErrors}
                    required
                />
                {this.props.error  && (
                    <Error>
                        {this.props.errorMessage}
                    </Error>
                )}
            </Wrapper>
        )
    }
}

export const Wrapper = styled.div`
    width: 100%;
`

export const Error = styled.div`
    font-size: 12px;
    margin-top: 8px;
    color: red;

    ${props => {
        if (props.theme == 'white') {
            return css`
                color: white;
            `
        }
    }}
`
export const Input = styled.input`
    appearance: none;
    box-shadow: none;
    display: flex;
    flex: 1;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    transition: border 0.20s ease;

    ${media.tablet`
        height: 48px;
    `}

    &, &::placeholder {
        font-size: 17px;
        line-height: 1.24;
    }

    &:focus {
        outline: none;
    }

    padding-left: 15px;
    box-sizing: border-box;

    /* Themes */

    ${props => {
        if (props.theme == 'mako') {
            return css`
                border: solid 1px #dadada;
                background: white;


                &, &::placeholder {
                    color: #6f727d;
                    line-height: 44px;
                }

                &:hover {
                    border: solid 1px #c2bfbf;
                }

                &:focus {
                    border: solid 1px #ee0b4f;
                    color: ${mako};
                }

                ${props => {
                    if (props.error) {
                        return css`
                            &, &::placeholder {
                                color: black;
                            }
                        `
                    }
                }}
            `
        }

        if (props.theme == 'white') {
            return css`
                border: solid 1px white;
                background: red;

                &, &::placeholder {
                    color: white;
                    line-height: 35px;
                }

                &:hover {
                    border: solid 1px ${rgba('white', 0.7)};
                }

                &:focus {
                    border: solid 1px white;
                }
            `
        }
    }}
`

export default InputText;
