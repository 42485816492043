import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import { useMount } from 'react-use';
import { media } from '../../styles/utils'
import { hoverState, bgIcon } from '../../styles/global'

import { fetchCart, getCart, addToCart } from '../../store/cart'
import { fetchSettings, updateSettings, getSettings } from '../../store/settings'
import { getCartQuantity } from './utils';

const CartIcon = (props) => {

    useMount(() => {
        props.fetchCart()
        props.fetchSettings()
    })
    
    const toggleCart = () => {
        props.updateSettings({
			cartActive: true
		})
    }
    

    const renderBadge = () => {
        const { cart } = props;
        const cartQuantity = getCartQuantity(cart);        
        if (!cartQuantity) return;

        return (
            <Badge>{cartQuantity}</Badge>
        )
    }
    
	return (
        <Wrapper
            onClick={toggleCart}
            closeVisible={props.closeVisible}
        >
            <Icon/>
            {renderBadge()}
        </Wrapper>
    )
}


// Layout

const Wrapper = styled.div`
    display: flex;
    position: absolute;
	right: 24px;
	top: 28px;
    z-index: 10;
    cursor: pointer;
    ${hoverState}

    ${media.tablet`
        display: none;
    `}

    ${props => {
        if (props.closeVisible) return css`
            right: 77px;

            /* Show on tablet product page */

            ${media.tablet`
                display: flex;
            `}

            ${media.phone`
                display: flex;
                top: 10px;
                right: 60px;
            `}
        `
    }}   
`

const Icon = styled.div`
    background-image: url(${require('../../assets/icons/cart-icon.svg').default});
    height: 38px;
    width: 39px;
    ${bgIcon};

    ${media.phone`
       width: 30px;
       height: 30px;
    `}
`

// Badge

const Badge = styled.div`
    position: absolute;
    right: -4px;
    top: 0;

    width: 21px;
    height: 21px;
    background: black;
    border-radius: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: white;
    font-weight: 500;
    font-size: 13px;
    padding-top: 2px;
    box-sizing: border-box;
`


const mapDispatchToProps = dispatch => ({
    fetchCart() {
        dispatch(fetchCart())
    },
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    ...getCart(state),
    settings: getSettings(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);
