import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';
import { black } from './colors';

export const containerMaxWidth = '1440px';

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: ${black};
        font-family: 'Baestica';
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
    }

    a, p, div {
        color: black;
    }

    b, strong {
        font-weight: 600;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    p:first-child {
		margin-top: 0;
	}
`;

export const container = css`
    display: flex;
	height: 100%;
	width: 100%;
    max-width: ${containerMaxWidth};
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 30px; 
    padding-right: 30px;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 24px; 
        padding-right: 24px;
    `}

    ${media.phone`
        padding-left: 11px; 
        padding-right: 11px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const videoEmbed = css`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`
export const button = css`
	background: ${black};
	height: 44px;
	
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	width: 100%;
    cursor: pointer;
`