import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import { BackgroundImage } from '../../../components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { maxWidth, indentedBlock } from '../../../pages/studio';
import { getImageCrop } from '../../../utils';

const BlockImageSingle = (props) => {
    const { image, use_caption, caption } = props;

    if (!image.mediaItemUrl) return <></>

    return (
        <Container>
            <BGImage
                as={BackgroundImage}
                image={image.mediaItemUrl}
            />

            {use_caption && caption.length && (
                <Caption
                    dangerouslySetInnerHTML={{ __html: caption }}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    ${padding};
`

const BGImage = styled.div`
    ${bgImage};
    ${indentedBlock};
    background-image: url(${props => props.image});  
    transition: opacity 0.45s ease;
    width: 100%;
    height: 665px !important;

    ${media.phone`
        height: 272px !important;
    `}

`
const Caption = styled.div`
    ${indentedBlock}
    padding-left: 0;
    margin-top: 12px;

    a {
        text-decoration: underline;
    }
`

BlockImageSingle.wrapper = css`

`

export default BlockImageSingle
