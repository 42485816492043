import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { lightGrey, black } from '../../styles/colors'
import { bgIcon, bgImage } from '../../styles/global'

const Select = (props) => {
    const { value, options, onChange, validator } = props;

    const renderOptions = () => {
        if (!options) return;

        return options.map((option, i) => {
            return (
                <option 
                    key={i}
                    value={option.value}
                    disabled={option.disabled}
                >
                    {option.label}
                </option>
            )
        })
    }

    return (
        <Wrapper>
            <SelectInput
                value={value}
                onChange={(event) => onChange(event.target.value)}
            >
                {renderOptions()}
            </SelectInput>
        </Wrapper>
    )
}


export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    user-select: none;
`

const SelectInput = styled.select``

export default Select;