import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { lightGrey, black } from '../../styles/colors'
import { bgIcon, bgImage } from '../../styles/global'

class Checkbox extends Component {

    toggle = () => {
        const { onChange, value, defaultValue } = this.props;
        onChange(value !== null ? !value : !defaultValue, true);
    }

    render() {
        const { value, label, defaultValue } = this.props;
        return (
            <Wrapper>
                <Container>
                    <Box
                        active={value !== null ? value : defaultValue}
                        onClick={this.toggle}
                    />
                    <Label
                        className={'label'}
                        onClick={this.toggle}
                    >   
                        {label}
                    </Label>
                </Container>
            </Wrapper>
        )
    }
}


export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    user-select: none;
`

const Container = styled.div`
    display: flex;
    align-items: flex-start;
`

const Label = styled.div`
    transform: translateY(1px);
    cursor: pointer;
    line-height: 21px;
`

const Box = styled.div`
    flex: 1 0 16px;
    max-width: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid #9F9F9F;
    position: relative;
    margin-right: 10px;
    cursor: pointer;

    ${props => {
        if (props.active) return css`
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url(${require('../../assets/icons/check.svg').default});
                ${bgImage};
                width: 14px;
                height: 11px;
            }
        `
    }}
`

export default Checkbox;