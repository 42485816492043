import React from 'react'
import { connect } from 'react-redux'
import { Link, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { forEach, filter, find } from 'lodash'
import ProgressiveImage from "react-progressive-image"
import NumberFormat from 'react-number-format'
import { motion, AnimatePresence } from 'framer-motion'

import { Layout, Metatags, VariantSelectors, CollectionSelectors, Block, CartIcon } from '../components'

import { fetchCart, getCart, addToCart } from '../store/cart'
import { fetchSettings, updateSettings, getSettings } from '../store/settings'

import { grey, black } from '../styles/colors'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { postData, fetchData } from '../services/api'
import { isAuthenticated } from '../services/auth'
import { getNumberScaleProps, getPriceProps, getPricingData } from '../utils/product'
import { width, media } from '../styles/utils'
import { filterByAttribute } from '../components/VariantSelectors/utils'

class ProductSingle extends React.Component {

	state = {
		activeVariant: null,
		activeThumbnail: null,
		activeTab: 'description',
		downloads: [],
	}

	componentDidMount() {
		this.fetchDownloads();
		this.setPreviousOptions()
	}

	setPreviousOptions = () => {
		const { settings } = this.props
		if (!settings.previousProduct) return this.setActiveVariant(this.props.data.shopifyProduct.variants[0])

		const { variants } = this.props.data.shopifyProduct
		let results = variants
		console.log(settings.previousProduct.selectedOptions)

		forEach(settings.previousProduct.selectedOptions, (option, i) => {
			results = filterByAttribute(results, option.value)
			console.log(option)
		})

		if (results.length) {
			return this.setActiveVariant(results[0])
		}

		return this.setActiveVariant(this.props.data.shopifyProduct.variants[0])
	}

	fetchDownloads = async () => {
		const { shopifyProduct } = this.props.data;
		const downloads = shopifyProduct.metafields.find(el => el.key === 'downloads')
		if (!downloads) return;
		const list = JSON.parse(downloads.value)

		// fetch file from shopify api

		try {
			const response = await fetch(`https://archier-products.myshopify.com/api/2023-10/graphql`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Shopify-Storefront-Access-Token': '5fd894c9ab0ed9b783793b0fe5664aa8'
				},
				body: JSON.stringify({
					query: `query ($ids: [ID!]!) {
					nodes(ids: $ids) {
							... on GenericFile {
									alt
									id
									mimeType
									originalFileSize
									url
									previewImage {
											src
									}
							}
					}
				}
				`,
					variables: {
						ids: list
					}
				})
			})

			const json = await response.json()

			this.setState({ downloads: json?.data?.nodes })
		} catch (e) {
			console.log(e)
		}

	}

	fetchProduct = (reset) => {
		const { slug } = this.props.pageContext;
		if (this.props.previewData) return;

		fetchData(`/pda/product/${slug}`)
			.then(response => {
				let data = response;
				this.setData(data)
			})
	}

	setData = (data) => {

		// Set activeVariant if simple product

		let activeVariant = this.state.activeVariant;

		if (data.product?.product_type == 'simple') {
			activeVariant = {
				id: data.wordpress_id,
				image: data.product.featured_image
			}
		}

		this.setState({
			activeThumbnail: this.state.activeThumbnail || data.image,
			activeVariant: activeVariant,
			data: {
				...data,
				//acf: JSON.parse(data.acf_json),
				//product: JSON.parse(data.product_json),
			},
		})
	}

	getShippingZones = () => {
		const { acf } = this.state.data;

		let shippingZones = {}

		forEach(acf.shipping_prices_other_countries, (zone) => {
			shippingZones[zone.country] = parseFloat(zone.price)
		})

		return {
			shipping: {
				australiaMelbourneMetro: parseFloat(acf.shipping_prices_au_melb_metro),
				australiaOther: parseFloat(acf.shipping_prices_au_other),
				WA: parseFloat(acf.shipping_prices_au_wa),
				NT: parseFloat(acf.shipping_prices_au_nt),
				...shippingZones
			}
		}
	}

	addToCart = () => {
		const { activeVariant, data } = this.state;
		const { updateSettings, addToCart } = this.props;
		const pricingData = getPricingData(activeVariant, data);

		console.log(activeVariant)

		addToCart(activeVariant)

		/*
		addToCart({
			...data,
			...activeVariant,
			pricing: pricingData,
			id: activeVariant.id,
			variant: true,
			quantity: 1,
			...this.getShippingZones()
		})
		*/

		updateSettings({
			cartActive: true
		})
	}

	setActiveVariant = (variant) => {

		this.setState({
			activeVariant: variant,
			activeThumbnail: variant
		})
	}

	toggleTab = (slug) => {
		this.setState({
			activeTab: slug,
		})
	}

	renderPrice = () => {
		const { activeVariant, data } = this.state;
		const { shopifyProduct } = this.props.data;
		const pricingData = getPricingData(activeVariant, shopifyProduct);

		/*
			priceRangeV2 {
				maxVariantPrice {
					amount
					currencyCode
				}
				minVariantPrice {
					amount
					currencyCode
				}
			}
			*/

		if (!pricingData?.variant) return;

		return (
			<Price>
				<NumberFormat
					value={pricingData?.variant?.price}
					prefix={'$'}
					{...getNumberScaleProps()}
				/>
			</Price>
		)
	}

	renderDownloads = () => {
		const { downloads } = this.state;

		console.log(downloads)

		if (!downloads) return;

		const re = /(?:\.([^.]+))?$/;
		const filename = /^.*[\\/]/


		return downloads.map((item, i) => {
			return (
				<Download
					key={i}
				>
					<Heading>{ item.alt || item.url?.replace(filename, '')?.split('.')[0]?.replace(/_/gi, ' ') }</Heading>

					<Subheading
						as={'a'}
						target={'_blank'}
						href={item.url}
					>
						Download {re.exec(item.url)[1]?.split('?')[0]?.toUpperCase()}
					</Subheading>
				</Download>
			)
		})
	}

	renderTabs = () => {
		let { activeTab, data } = this.state;

		const { shopifyProduct } = this.props.data;

		const tabs = [
			{
				title: 'Description',
				slug: 'description',
				content: shopifyProduct.descriptionHtml
			}
		]

		if (!shopifyProduct.metafields?.length) return;

		const metakeys = 'details,shipping,downloads'.split(',')

		metakeys.forEach((key) => {
			const meta = find(shopifyProduct.metafields, { key: key })
			if (!meta) return;

			let content = JSON.parse(meta.value)
			if (meta.type === 'rich_text_field') {
				content = content.children.map((item) => {
					return item.children.map((item) => {
						if (item.bold) {
							return `<p><strong>${item.value}</strong></p>`
						}
						return `<p>${item.value}</p>`
					}).join('')
				}).join('')
			}
			//if (meta.type === 'list.file_reference') meta.value = JSON.parse(meta.value)

			tabs.push({
				title: key.charAt(0).toUpperCase() + key.slice(1),
				slug: key,
				content: content
			})
		})

		const navItems = tabs.map((item, i) => {
			return (
				<TabNavItem
					key={i}
					active={item.slug == activeTab}
					onClick={() => this.toggleTab(item.slug)}
				>
					{item.title}
				</TabNavItem>
			)
		})

		// Match tab content

		activeTab = find(tabs, { slug: activeTab })

		return (
			<Tabs>
				<TabNav>{navItems}</TabNav>
				<AnimatePresence
					exitBeforeEnter={true}
				>
					{activeTab.slug === 'downloads' && (
						<TabContent
							key={activeTab.slug}
							{...animatedTab}
						>
							{this.renderDownloads()}
						</TabContent>
					)}

					{activeTab.slug !== 'downloads' && (
						<TabContent
							key={activeTab.slug}
							dangerouslySetInnerHTML={{ __html: activeTab.content }}
							{...animatedTab}
						/>
					)}

				</AnimatePresence>
			</Tabs>
		)
	}

	renderVariantImage = () => {
		const { activeThumbnail } = this.state;
		const { shopifyProduct } = this.props.data;
		const thumbnail = activeThumbnail?.image || shopifyProduct?.featuredImage
		if (!thumbnail) return;

		return (
			<Block
				className={'variant-image'}
				layout={'product_images'}
				imageLayout={'full_width'}
				display_block={true}
				image={{
					...thumbnail
				}}
			/>
		)
	}

	renderImages = () => {
		const { activeThumbnail } = this.state;
		const { shopifyProduct } = this.props.data;
		const activeImage = activeThumbnail?.image || shopifyProduct?.featuredImage
		if (!shopifyProduct.media.length) return;

		const images = shopifyProduct.media.map((media) => media.image).filter((image) => image.src !== activeImage.src)

		return (
			<ProductImages>
				{this.renderVariantImage()}
				<Block
					layout={'product_images'}
					display_block={true}
					images={images}
				/>
			</ProductImages>
		)
	}

	renderInfo = () => {
		const { activeVariant } = this.state;

		const { shopifyProduct, shopifyCollection } = this.props.data;

		const { metafields } = shopifyProduct;
		const additionalFields = metafields.find(el => el.key === 'additional_fields') 

		return (
			<Info>
				<Heading>
					{shopifyCollection?.title || shopifyProduct.title}
					{this.renderPrice()}
				</Heading>

				{this.renderVariantImage()}

				{shopifyCollection.products?.length > 1 && (
					<CollectionSelectors
						current={shopifyProduct}
						products={shopifyCollection.products}
						onChange={(selected) => {
							console.log(activeVariant)
							// TODO get activeVariant.selectedOptions and pass to new product
							this.props.updateSettings({ previousProduct: activeVariant })
							navigate(`/product/${selected.product.handle}`)
						}}
					/>
				)}

				{shopifyProduct.variants && activeVariant && (
					<VariantSelectors
						defaults={activeVariant.selectedOptions}
						options={shopifyProduct.options}
						variants={shopifyProduct.variants}
						additionalFields={additionalFields}
						onMatch={(variant) => this.setActiveVariant(variant)}
						onChange={() => { }}
					/>
				)}

				<AddToCart
					onClick={this.addToCart}
					disabled={!activeVariant}
				>
					Add to cart — {this.renderPrice()}
				</AddToCart>

				{this.renderTabs()}


				{/*
                    
                {data.acf.add_to_cart_note_display && (
                    <Note
                        dangerouslySetInnerHTML={{__html: data.acf.add_to_cart_note}}
                    />
                )}
								*/}

			</Info>
		)
	}

	render() {
		const { shopifyProduct } = this.props.data;
		const { collectionHandle, collectionLength } = this.props.pageContext

		return (
			<Layout
				zIndex={20}
				meta={shopifyProduct.seo}
				hideMobileHeader={true}
				hideHeader={true}
			>
				<Wrapper>
					<CartIcon
						closeVisible
					/>
					<Close
						to={`/products`}
					/>

					{this.renderInfo()}
					{this.renderImages()}
				</Wrapper>
			</Layout>
		)
	}
}


// Shared

const Heading = styled.div`
	margin: 0 0 85px;

	${media.phone`
		margin: 0;
  `}
`
const Subheading = styled.div``


const Image = styled(ProgressiveImage)``;

const BGImage = styled.div`
    background: rgba(0, 0, 0, 0.2);
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	background: white;
	max-height: 100vh;

	${media.phone`
		${padding}
		max-height: none;
		flex-direction: column;
	`}
`

const Close = styled(Link)`
	background-image: url(${require('../assets/icons/close-black.svg').default});
	${bgImage};
	width: 32px;
	height: 32px;

	position: absolute;
	right: 24px;
	top: 32px;
	${hoverState};
	cursor: pointer;
	z-index: 2;

    ${media.phone`
        width: 24px;
        height: 24px;
        right: 10px;
    	top: 14px;
    `}
`

// Product Images

const ProductImages = styled.div`
	flex: 1;
	overflow-y: scroll;
  margin-top: 158px;
	display: flex;
	flex-wrap: wrap;

	${media.phone`
		margin-top: 48px;
		overflow: visible;

		.variant-image {
			display: none;
		}
	`}
`

// Info

const Price = styled.div``

const Note = styled.div``
const Info = styled.div`
	display: block;
	flex-direction: column;
	flex: 0 1 500px;
	padding: 30px 38px 30px 30px; 
	height: 100vh;
	box-sizing: border-box;
	overflow: scroll;

	position: sticky;
	top: 0;

	> ${Heading} {
		display: flex;
		justify-content: space-between;

		font-size: 38px;
		line-height: 44px;
		font-weight: 500;

		${Price} {
			font-size: 20px;
			font-weight: 500;
		}
	}

    ${Note}{
        margin-top: 40px;
        p{
            font-size: 12px;
            line-height: 1.4em;
        }
    }

	.variant-image {
		display: none;
	}

	${media.tablet`
		flex: 0 1 50%;

		> ${Heading} {
			font-size: 31px;
			line-height: 35px;
			flex-direction: column;

			${Price} {
				margin-top: 10px;
			}
		}
	`}

	${media.phone`
		padding: 15px 0 0 0;
		position: relative;
		flex: 0 1 100%;
		height: auto;

		.variant-image {
			display: block;
			width: 100vw;
			transform: translateX(-11px);

			.product-image {
				padding-bottom: 10px;
			}
		}

		> ${Heading} {
			${Price} {
				margin-top: 0px !important;
				margin-bottom: 24px;
			}
		}
	`}
`

// Tabs

const animatedTab = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.25,
		}
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.15,
		}
	},
}

const Tabs = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 60px;
	margin-bottom: auto;
	flex:

	${media.tablet`
		margin-top: 32px;
	`}

	${media.phone`
		width: 100vw;
		transform: translateX(-11px);
	`}
`

const TabNav = styled.div`
	display: flex;
	padding-bottom: 9px;
	border-bottom: 1px solid #CFCFCF;
	user-select: none;

	${media.phone`
		${padding};
		justify-content: space-between;
	`}
`

const TabNavItem = styled.div`
	font-size: 16px;

	&:not(:last-child) {
		margin-right: 33px;
	}

	/* Animation */

	opacity: 0.3;
	transition: opacity 0.35s ease;
	cursor: pointer;

	&:hover {
		opacity: 1;
		transition: opacity 0.15s ease;
	}

	${props => {
		if (props.active) return css`
			opacity: 1;
		`
	}}

	${media.phone`
		flex: 0;
		text-align: center;


		&:not(:last-child) {
			margin-right: 0;
		}
	`}
`

const TabContent = styled(motion.div)`
	padding-top: 24px;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 32px;

	${media.phone`
		${padding}
	`}
`

const Download = styled.div`
	margin-bottom: 20px;
	display: flex;	
	gap: 32px;
	align-items: center;
	justify-content: space-between;

	${Heading} {
		margin: 0;
	}

	${Subheading} {
		opacity: 0.3;
		min-width: 120px;
	}
`


// Add to Cart

const AddToCart = styled.div`
	min-height: 44px;
	width: 100%;

	background: ${black};
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 10px;
	padding-top: 2px;
	white-space: pre;
	
	cursor: pointer;

	&, * {
		color: white;
	}

	${props => {
		if (props.disabled) return css`
			pointer-events: none;
			opacity: 0.3;
		`
	}}

	${media.phone`
		max-width: none;
	`}
`

export const pageQuery = graphql`
	query ProductBySlug($id: String!, $collectionId: String!) {
		shopifyCollection(id: { eq: $collectionId }) {
			handle
			title
			description
			image {
					src
			}
			products {
					id
					title
					handle
					description
					featuredImage {
							src
					}
			}
		}
		shopifyProduct(id: { eq: $id }) {
      seo {
        description
        title
      }
      shopifyId
      productType
      storefrontId
			title
      handle
      description
      descriptionHtml
      featuredImage {
        src
				height
        width
      }
      metafields {
        value
        type
        namespace
        key
      }
      id
      options {
        name
        position
        values
        shopifyId
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        availableForSale
        compareAtPrice
        displayName
        id
        image {
          src
					height
          width
        }
        position
        price
        shopifyId
        sku
        storefrontId
        title
				selectedOptions {
					name
					value
				}
      }
      media {
        ... on ShopifyMediaImage {
          id
          image {
            src
						height
            width
          }
        }
      }
    }
  }   
`

const mapDispatchToProps = dispatch => ({
	fetchCart() {
		dispatch(fetchCart())
	},
	fetchSettings() {
		dispatch(fetchSettings())
	},
	addToCart(cartItem) {
		dispatch(addToCart(cartItem))
	},
	updateSettings(setting) {
		dispatch(updateSettings(setting))
	},
})

const mapStateToProps = (state, props) => ({
	...getCart(state),
	settings: getSettings(state)
})

ProductSingle.hideHeader = true;

export default connect(mapStateToProps, mapDispatchToProps)(ProductSingle);

