import React from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image";

import { Layout, Block } from '../components'
import { maxWidth } from '../pages/studio'

import { container, padding, bgImage, bgIcon } from '../styles/global';
import { grey } from '../styles/colors';
import { media } from '../styles/utils';
import BlockEmbed from '../components/Block/templates/BlockEmbed';

class DefaultPage extends React.Component {

	constructor(props) {
		super(props);
	}

	state = {

	}

	componentWillMount() {
		this.setData()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.slug !== this.props.slug) {
			this.setData();
		}
	}

	setData = (reset) => {
		this.setState({
			data: this.props.pageContext
		})
	}

	renderBlocks = (blocks) => {
		if (!blocks) return;

		return blocks.map((block, i) => {
			return (
				<Block
					layout={block.acf_fc_layout || block.__typename}
					key={i}
					{...block}
				/>
			)
		})
	}

	render() {
		const { data } = this.state;

		// check if hero_image exits
		const hasHero = !!data.template?.pageBlocks?.blocks?.find(block => block.__typename.indexOf('HeroImage') !== -1)

		return (
			<Layout
				meta={data?.seo}
				footer={true}
			>
				<Container hasHero={hasHero}>
					{ !hasHero && (
						<HeadingWrapper>
							<Heading>{data.title}</Heading>
						</HeadingWrapper>
					)}

					{data.template?.pageBlocks?.blocks && this.renderBlocks(data.template.pageBlocks.blocks)}
				</Container>
			</Layout>
		)
	}
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``


// Layout

const Container = styled.div`
	${container}
	
	display: flex;
	flex-direction: column;
	max-width: none;
	overflow-x: hidden;
	padding-top: 110px;
  padding-bottom: 300px;

	${media.phone`
		padding-top: 40px;
		padding-bottom: 128px;
	`}

	${props => {
		if (props.hasHero) return css`
			padding-top: 0;
				${media.phone`
				padding-top: 0px;
			`}
		`
	}}
`

// Heading

const HeadingWrapper = styled.div`
    ${container};
    justify-content: flex-end;
    padding-bottom: 120px;

    ${Heading} {
        font-size: 70px;
        font-weight: 500;
        width: 100%;
		transform: translateX(-4px);
        ${maxWidth}
        box-sizing: border-box;
    }

	${media.phone`
		padding-bottom: 48px;
		
		${Heading} {
			font-size: 28px;
			padding-left: 0px;
			transform: translateX(-2px);
			${padding}
		}
	`}
`




export default DefaultPage

