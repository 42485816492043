import { transformBillingShippingFields } from './checkout';

export const transformTradeData = (fields, user) => {

    const useBillingForShipping = fields.useBillingForShipping && fields.useBillingForShipping.value || false;
    
    let data = {
        billing: transformBillingShippingFields(fields, 'billing'),
        shipping: transformBillingShippingFields(fields, useBillingForShipping ? 'billing' : 'shipping'),
    }

    if(fields['password']) data.password = fields['password'].value

    if(fields[`businessName`]){
        data.businessDetails = {
            name: fields[`businessName`].value,
            type: fields[`businessType`].value,
            abn: fields[`abn`].value,
        }
    }

    if (user && user.customer_id) {
        data.customer_id = user.customer_id
    }

    return data
}

