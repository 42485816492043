import React from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image";

import { Layout } from '../components'

import { container, padding, bgImage, bgIcon, containerMaxWidth } from '../styles/global';
import { grey, black } from '../styles/colors';
import { media } from '../styles/utils';

class Contact extends React.Component {

    renderLocations = () => {
        const { wpPage } = this.props.data

        const items = wpPage?.template?.locations?.locations?.map((item, i) => {
            return (
                <Location key={i}>
                    <Subheading>{item.title} —</Subheading>
                    <Text
                        dangerouslySetInnerHTML={{__html: item.address}}
                    />
                    <Phone>{item.phone}</Phone>
                </Location>
            )
        })

        return (
            <Locations>{items}</Locations>
        )
    }

	render() {       
        const { wpPage } = this.props.data

		return (
			<Layout
                meta={wpPage?.seo}
                footer={true}
            >
				<Container>
                    <Info>
                        <Heading>Contact Us</Heading>
                        <Subheading>{wpPage?.template?.content?.heading}  —</Subheading>
                        <Text
                            dangerouslySetInnerHTML={{__html: wpPage?.template?.content?.text}}
                        />

                        {this.renderLocations()}
                    </Info>
				</Container>
			</Layout>	
		)
	}
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``


// Layout

const Container = styled.div`
	${container}
	max-width: 1220px;
    
	display: flex;
	flex-direction: column;
    align-items: flex-end;
	overflow-x: hidden;
    padding-top: 109px;
    padding-bottom: 128px;

    ${media.tablet`
        align-items: center;
        ${padding}
    `}

    ${media.phone`
        padding-top: 40px;
        height: 100%;
    `}
`


const Info = styled.div`
    max-width: 712px;
    width: 100%;

    ${Heading} {
        font-size: 70px;
    }
    
    ${Subheading} {
        font-size: 28px;
        font-weight: 500;
    }

    > ${Subheading} {
        margin-top: 66px;
    }
   
    ${Text} {
        margin-top: 15px;
        font-size: 24px;
        line-height: 34px;
        max-width: 507px;
        
        p {
            margin: 0;
            font-weight: 200;
        }

        a {
            font-weight: 500;
            border-bottom: 1px solid ${black};
            margin-top: 10px;
            display: inline-block;
        }
    }

    ${media.tablet`
        max-width: none;
    `}

    ${media.phone`
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        ${Heading} {
            font-size: 28px;
            font-weight: 500;
        }

        ${Subheading} {
            font-size: 24px;
        }

        ${Text} {
            p, a {
                font-size: 18px;
                line-height: 24px;
            }
        }
    `}
`

// Location

const Locations = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 96px;

    ${media.tablet`
        justify-content: flex-start;
    `}

    ${media.phone`
        justify-content: space-between;
        margin-top: 128px;
    `}
`

const Phone = styled.div``

const Location = styled.div`
    display: flex;
    flex-direction: column;

    margin-right: 32px;
    height: 220px;

    ${Phone} {
        margin-top: auto;
        font-size: 24px;
        line-height: 34px;
        max-width: 507px;
        font-weight: 200;
    }

    ${media.tablet`
        margin-right: 64px;
    `}
    
    ${media.phone`
        height: auto;
        margin-right: 0;

        ${Phone} {
            font-size: 18px;
            line-height: 24px;
            margin-top: 24px;
        }
    `}
`


export const query = graphql`
query {
    wpPage(slug: { eq: "contact" }) {
        seo {
            ...SeoData
        }
        template {
            ... on WpTemplate_Contact {
              templateName
              locations {
                locations {
                  address
                  phone
                  title
                }
              }
              content {
                heading
                text
              }
            }
          }
    }
}
`

export default Contact

