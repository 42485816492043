import React, { useState, useRef } from 'react';
import { useSetState, useMount } from 'react-use';
import { connect } from 'react-redux';
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import { filter, find, forEach } from 'lodash'
import Form from 'react-dynamic-form'
import ProgressiveImage from "react-progressive-image"

import { Layout, MetaTags, Input } from '../components'

import { fetchUser, updateUser, getUser } from '../store/user'
import { getCart, fetchCart } from '../store/cart'
import { fetchSettings, updateSettings, getSettings } from '../store/settings'
import { postData } from '../services/api';

import { container, padding, bgIcon, button } from '../styles/global';
import { media, isClient } from '../styles/utils';
import { black } from '../styles/colors';
import { inputStyle, red, error } from '../components/Input/Input';
import { handleLogin } from '../services/auth';
import { required, password } from '../utils/validators';
import { editAddress } from '../data/account';
import { formHeading, formContent, formSidebar } from './checkout';
import { maxWidth } from './studio';

const Login = (props) => {

    const graphData = useStaticQuery(query)
    const acf = {} // JSON.parse(graphData.wordpressGlobal.acf_json)

	const loginForm = useRef(null);

	const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
		error: false,
	})

	useMount(() => {
        props.fetchUser()
	})

	const renderSidebar = () => {
		const { cart } = props;

		return (
			<Sidebar>
				<Heading>Trade Login</Heading>
				<Description
					dangerouslySetInnerHTML={{__html: acf.login_text}}
				/>
			</Sidebar>
		)
	}

	const handleSubmit = (fields, resetForm) => {
        const { cart } = props;
        
		const cred = {
            username: fields.username.value,
            password: fields.password.value
        }

		updateForm({submitting: true})

        handleLogin(cred)
            .then(response => {  
                if (response.error) {                    
                    updateForm({
                        error: response.message,
                        submitting: false
                    })

                    resetForm()

                } else {
                    updateSettings({
                        isAuthenticated: true
                    })

                    props.updateUser(response.customer)
                    
                    resetForm()
                    navigate('/products')
                }
        }) 
	}
	
	const renderForm = () => {
		const { error, submitting, formComplete } = formState;
		
		const loginFields = {
			fields: [
				{
					type: 'text',
					name: 'username',
					label: 'Username',
					validator: required,
					errorMessage: 'This field is required',
				},
				{
					type: 'password',
					name: 'password',
					label: 'Password',
				},
			]
		}
    
		return (
			<FormWrapper>
				<Form
					ref={loginForm}
					data={loginFields}
					renderInput={<Input/>}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleSubmit}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					theme={'outline-black'}
					onClick={() => {
						loginForm.current && loginForm.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Login'}
				</Button>

                <ResetPassword
                    to="/reset-password"
                >
                    Reset Password
                </ResetPassword>

			</FormWrapper>
		)
	}

	return (
        <Layout
            footer={true}
        >
			<Container>
				<Heading>Trade Access</Heading>
				<Content>
					{renderSidebar()}
					{renderForm()}
				</Content>
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	bgImage;
	transition: opacity 0.45s ease;
`

const Button = styled.div`
    ${button}
`


// Layout

const Container = styled.div`
	${container}
	${padding}
	${maxWidth}
	flex-direction: column;

	> ${Heading} {
		${formHeading}
	}
`

const Content = styled.div`
	${formContent}
`

// Sidebar

const Sidebar = styled.div`
	${formSidebar}

	${Heading} {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 5px;
	}	

	> ${Description} {
		margin-top: 12px;
		font-size: 16px;
		line-height: 19px;
	}
`

// Form

const FormWrapper = styled.div`
	flex: 1 0 calc(50% - 20px);
	display: flex;	
	flex-direction: column;	

	${Button} {
		margin-top: 29px;
	}

	${media.tablet`
		max-width: calc(100vw - 350px);
	`}

	${media.phone`
		max-width: none;
	`}
`

const Section = styled.div`
	width: 100%;
	margin-bottom: 42px;	

	${Heading} {
		font-size: 20px;
		font-weight: 500;
	}	
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
        width: 100%;
        
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 17px;

		&.terms {
			.field-wrapper {
				border: none !important;
			}
		}

		&.creditCard {
			margin-bottom: 42px;

			.field > * {
				width: 100%;

				#cardNumber {
					flex: 1 0 60%;
				}

				#expiryDate {
					flex: 1 0 20%;
				}

				#CVC {
					flex: 1 0 20%;
				}
			}
		}
	}
`

const ResetPassword = styled(Link)`
    margin-top: 30px;

	${media.phone`
		align-self: center;
	`}
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
`

const ThankYou = styled.div`
	margin-top: 32px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
    background-image: url(${require('../assets/icons/loading.svg').default});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`


export const query = graphql`
	query LoginQuery {
		wp {
			options {
				pageSlug
				pageTitle
				footerMenu {
					footerMenu {
						title
						link {
							title
							url
						}
					}
				}
				tradeApplicationOptions {
					tradeApplicationNotificationEmail
					tradeApplicationUseAdminNotificationEmail
				}
				tradeLogin {
					loginText
				}
			}
		}
	}
`

const mapDispatchToProps = dispatch => ({
	fetchCart() {
        dispatch(fetchCart())
    },
	fetchSettings() {
        dispatch(fetchSettings())
    },
    fetchUser() {
        dispatch(fetchUser())
    },
    updateUser(data) {
        dispatch(updateUser(data))
	},
})

const mapStateToProps = (state, props) => ({
	...getCart(state),
	...getUser(state),
})


export default connect(mapStateToProps, mapDispatchToProps)(Login);
