export const scatterPositions = [
    {
        flex: '1 0 100%',
        fixed: true,
        marginTop: '16px',
    },
    {
        flex: '0 1 50%',
    },
    {
        flex: '0 1 45%',
    },
    {
        flex: '0 1 80%',
    },
    {
        flex: '0 1 20%',
    },
    {
        flex: '0 1 65%',
    },
    {
        flex: '0 1 75%',
        justifyContent: 'flex-end'
    },
    {
        flex: '0 1 30%',
    },
    {
        flex: '0 1 30%',
    },
    {
        flex: '0 1 35%',
        justifyContent: 'flex-end',
        fixed: true,
    },
    {
        flex: '0 1 90%',
        justifyContent: 'flex-end'
    },
    {
        flex: '0 1 20%',
    },
    {
        flex: '0 1 60%',
    },
    {
        flex: '0 1 50%',
    },
    {
        flex: '0 1 35%',
    },
    {
        flex: '0 1 90%',
        justifyContent: 'flex-end'
    },

    // Loop

    {
        flex: '1 0 100%',
        fixed: true,
        marginTop: '16px',
    },
    {
        flex: '0 1 50%',
    },
    {
        flex: '0 1 45%',
    },
    {
        flex: '0 1 80%',
    },
    {
        flex: '0 1 20%',
    },
    {
        flex: '0 1 65%',
    },
    {
        flex: '0 1 75%',
        justifyContent: 'flex-end'
    },
    {
        flex: '0 1 30%',
    },
    {
        flex: '0 1 30%',
    },
    {
        flex: '0 1 35%',
        justifyContent: 'flex-end',
        fixed: true,
    },
    {
        flex: '0 1 90%',
        justifyContent: 'flex-end'
    },
    {
        flex: '0 1 50%',
    },
    {
        flex: '0 1 60%',
    },
    {
        flex: '0 1 50%',
    },
    {
        flex: '0 1 35%',
    },
    {
        flex: '0 1 90%',
        justifyContent: 'flex-end'
    },
]

export const getRandomXYTransform = (i) => {
    if (scatterPositions[i].fixed == true) return;

    // X

    let x = Math.floor(Math.random() * 10) + 1;
    x *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;

    // Y

    let y = Math.floor(Math.random() * 10) + 1;
    y *= Math.floor(Math.random() * 2) == 1 ? 1 : -1;

    return {
        transform: `translate(${x}px, ${y}px)`
    } 					
}

export const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    if (!e) e = window.event;
    
    if (e.pageX || e.pageY) {
        posx = e.pageX;
        posy = e.pageY;
    }
    
    else if (e.clientX || e.clientY) 	{
        posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }

    return {
        x: posx,
        y: posy
    }
}
