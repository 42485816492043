import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components'

import { container, padding } from '../styles/global';
import { media } from '../styles/utils';

class OrderThankyou extends React.Component {

	state = {

	}

	render() {        

		return (
			<Layout>
				<Container>
                    <Content
                        dangerouslySetInnerHTML={{__html: `
                            Thanks for your order! 
                        `}}
                    />
				</Container>
			</Layout>	
		)
	}
}


const Container = styled.div`
	${container}
    ${padding}
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    align-self: center;

    ${media.phone`
        margin-top: -48px;
    `}
`

const Content = styled.div`
    font-size: 24px;

    ${media.phone`
        font-size: 16px;
    `}
`

export default OrderThankyou

