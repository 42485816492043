import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { forEach, reject, upperFirst } from 'lodash'
import { required, email, phone, number, password, excludeShipping, postcode } from '../utils/validators'
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector'
import { width } from '../styles/utils';
import { Checkbox, Input } from '../components';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import { inputStyle, red, error } from '../components/Input/Input';
import { EWAY_KEY } from '../constants';

const creditCardStyles = {
    fieldWrapper: {
        base: css`
            height: 44px;
            display: flex;
        `
    },
    inputWrapper: {
        base: css`
            ${inputStyle}
            border-radius: 0;
            border-width: 1px;
        `,
        focused: css`
            box-shadow: none;
            ${inputStyle}
        `,
        errored: css`
            ${inputStyle}
            border-color: ${red}; 
        `,
    },
    input : {
        base: css`
            ${inputStyle}
            border: 0;
            padding: 0;
        `
    },
    errorText: {
        base: css`
            ${error}
            margin-left: 0;
        `
    }
}   

const billingShippingFields = [
    {
        type: 'email',
        name: 'email',
        label: 'Email Address *',
        validator: email,
        errorMessage: 'Please enter a valid email'
    },
    {
        type: 'text',
        name: 'firstName',
        label: 'First Name *',
        validator: required
    },
    {
        type: 'text',
        name: 'lastName',
        label: 'Last Name *',
        validator: required,
    },
    {
        type: 'text',
        name: 'company',
        label: 'Company Name',
    },
    {
        type: 'tel',
        name: 'phone',
        label: 'Phone *',
        validator: required, // phone check?
        errorMessage: 'Please enter a valid phone number'
    },
    {
        type: 'text',
        name: 'address1',
        label: 'Address 1 *',
        validator: required,
    },
    {
        type: 'text',
        name: 'address2',
        label: 'Address 2',
    },
    {
        type: 'text',
        name: 'city',
        label: 'Suburb / City *',
        validator: required,
    },
    {
        name: 'country',
        label: 'Country *',
        validator: required,
        component: ({value, onChangeValue, validator}) => {
            return (
                <CountryDropdown
                    value={value}
                    valueType={'short'}
                    defaultOptionLabel={'Select country *'}
                    whitelist={['AU','NZ']}
                    onChange={(val) => onChangeValue(val, validator)}
                />
            )
        }
    },
    {
        name: 'state',
        label: 'State *',
        validator: required,
        component: ({value, onChangeValue, validator, fieldState, id}) => {
            const country = fieldState && fieldState[`${id.includes('billing') ? 'billing' : 'shipping'}Country`];
            return (
                <RegionDropdown
                    value={value}
                    countryValueType={'short'}
                    valueType={'short'}
                    blankOptionLabel={'Select region *'}
                    onChange={(val) => onChangeValue(val, validator)}
                    country={country && country.value}
                />
            )
        }
    },
    {
        type: 'text',
        name: 'postcode',
        label: 'Postcode *',
        validator: postcode,
        errorMessage: 'Please enter a valid post code'
    },
]

export const getBillingShippingFields = (type, exclusions) => {
    let fields = []
    let shippingExclude = ['phone', 'email'];

    forEach(billingShippingFields, (field) => {
        if (exclusions && exclusions.includes(field.name)) return;
        if (type == 'shipping' && shippingExclude.includes(field.name)) return;
        
        fields.push({
            ...field,
            validator: type == 'shipping' ? (...args) => excludeShipping(field.validator, ...args) : field.validator,
            name: `${type}${upperFirst(field.name)}`,
            fieldType: type,
        })
    })

    return fields
}


export const formData = {
    sections: [
        {
            heading: 'Billing Fields',
            fields: [
                ...getBillingShippingFields('billing'),
                {
                    name: 'useBillingForShipping',
                    defaultValue: true,
                    component: ({value, onChangeValue, validator}) => {
                        return (
                            <Checkbox
                                value={value}
                                label={'Use same address for shipping'}
                                onChange={(val) => onChangeValue(val, validator)}
                            />
                        )
                    }
                },
            ],
        },
        {
            heading: 'Shipping Fields',
            hide: (fieldState) => {
                const useBilling = fieldState['useBillingForShipping'];
                return useBilling && useBilling.value == true ? true : false;
            },
            fields: [
                ...getBillingShippingFields('shipping'),
            ],
        },
        {
            heading: 'Customer Note',
            fields: [
                {
                    type: 'textarea',
                    name: 'customerNote',
                    label: 'If you’re ordering a black or white Highline please use this box to let us know whether you’d like corresponding end blocks',
                },
            ],
        },
        {
            heading: 'Payment Details',
            fields: [
                {
                    type: 'text',
                    name: 'cardName',
                    label: 'Card Name',
                    validator: required
                },
                {
                    name: 'creditCard',
                    errorMessage: ' ',
                    validator: (value) => { 
                        return value && typeof value.error == 'undefined' || false
                    },
                    component: ({value, onChangeValue, validator}) => {
                        const { wrapperProps, meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();    
                        const error = meta.error;

                        // Merge input state into one object and encrypt values 

                        const onChange = (key, targetValue, error) => onChangeValue({
                            ...value, 
                            [key]: targetValue,
                            error
                        }, validator)

                        const inputs = [
                            {
                                baseProps: getCardNumberProps,
                                name: 'cardNumber',
                                encrypt: true,
                            },
                            {
                                baseProps: getExpiryDateProps,
                                name: 'cardExpiry',
                            },
                            {
                                baseProps: getCVCProps,
                                name: 'CVC',
                                encrypt: true,
                            },
                        ]
              
                        return (
                            <PaymentInputsWrapper
                                {...wrapperProps}
                                styles={creditCardStyles}
                            >
                                <svg {...getCardImageProps({ images })} />

                                {/* Inputs */}
                                
                                {inputs.map((input, i) => (
                                    <input
                                        key={i}
                                        {...input.baseProps({
                                            onBlur: (e) => onChange(
                                                input.name, 
                                                input.encrypt ? eCrypt.encryptValue(e.target.value.replace(/ /g, ''), EWAY_KEY) : e.target.value, 
                                                error
                                            ),
                                        })}
                                    />
                                ))}
                            </PaymentInputsWrapper>
                        )
                    }
                },
                {
                    name: 'termsAccepted',
                    defaultValue: false,
                    validator: (value) => { return value },
                    component: ({value, onChangeValue, validator}) => {
                        return (
                            <Checkbox
                                value={value}
                                label={'Yes, I agree to the terms and conditions and privacy policy*?'}
                                onChange={(val) => onChangeValue(val, validator)}
                            />
                        )
                    }
                },
                // {
                //     name: 'marketingAccepted',
                //     defaultValue: false,
                //     component: ({value, onChangeValue, validator}) => {
                //         return (
                //             <Checkbox
                //                 value={value}
                //                 label={'Yes, I would like to recieve promotional messages from Archier'}
                //                 onChange={(val) => onChangeValue(val, validator)}
                //             />
                //         )
                //     }
                // },
            ],
        },
    ],
}
