import React from 'react'
import { Link, graphql } from 'gatsby'
import { forEach, find, reject } from 'lodash'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image";
import { motion, AnimatePresence } from 'framer-motion'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import OutsideClick from 'react-outside-click-handler';

import { Layout, BackgroundImage } from '../components'
import { withHeader } from '../components/Header/HeaderContext';

import { container, padding, bgImage, bgIcon } from '../styles/global';
import { scatterPositions, getRandomXYTransform, getMousePos } from '../utils/home';
import { intersecting } from '../utils/intersect';
import { grey } from '../styles/colors';
import { media, breakpoints, isClient } from '../styles/utils';

class Index extends React.Component {

	constructor(props) {
		super(props);
	}

	state = {
		view: 'grid',
		scatterPositions: null,

		// Filters

		filtersActive: false,
		activeFilterSection: 'locations',
		activeFilters: [],

		// Project Hover

		activeProject: null,
		mousePos: {
			x: 0,
			y: 0
		},
		windowSize: {
			width: 0,
			height: 0
		}
	}

	componentWillMount = () => {
		this.setState({
			data: {
				projects: [...this.props.data.allWpProject.nodes],
				//...JSON.parse(this.props.data.allWordpressInfopages.nodes[0].acf_json)
			}
		})

		// Generate scatter positions 

		this.generateScatterPositions()
	}

	componentDidMount = () => {
		window.addEventListener('mousemove', e => this.getMousePos(e));
		window.addEventListener('resize', this.onResize());

		// Set initial mouse position & viewport size

		this.setState({
			windowSize: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			mousePos: {
				x: window.innerWidth / 2,
				y: window.innerHeight / 2,
			}
		})
	}

	toggleView = (view) => {
		sessionStorage.setItem('indexView', view)

		this.setState({
			view: view
		})
	}

	toggleProject = (slug) => {
		this.setState({
			activeProject: slug
		})
	}

	toggleFilters = () => {
		this.props.setHeaderContext({
			headerBelow: !this.state.filtersActive
		})

		this.setState({
			filtersActive: !this.state.filtersActive
		})
	}

	handleFilterChange = (filter) => {
		const { activeFilters } = this.state;
		const exists = find(activeFilters, { slug: filter.slug })

		let newFilters = []; // use activeFilters to enable multifilering

		if (exists) {
			newFilters = reject(activeFilters, { slug: filter.slug })
		} else {
			newFilters.push(filter)
		}

		this.setState({
			activeFilters: newFilters,
		})
	}

	matchFilters = (project) => {
		const { activeFilters } = this.state;
		const { locations, types, structures } = project;

		const categories = { locations, types, structures };

		let matches = [];

		forEach(categories, (cat) => {
			forEach(cat, (term) => {
				const match = find(activeFilters, { slug: term.slug });
				if (match) matches.push(term);
			})
		})

		return matches.length == activeFilters.length;
	}

	getMousePos = (e) => {
		if (this.state.view !== 'scatter' || !this.state.activeProject) return;

		this.setState({
			mousePos: getMousePos(e)
		})
	}

	onResize = (params) => {
		this.setState({
			windowSize: {
				width: window.innerWidth,
				height: window.innerHeight
			}
		})
	}

	getHoverTransform = () => {
		const { mousePos, windowSize } = this.state;
		const translateX = `translateX(${(mousePos.x - (windowSize.width / 2)) * 0.2}px)`
		const translateY = `translateY(${(mousePos.y - (windowSize.height / 2)) * 0.2}px)`

		return {
			transform: `${translateX} ${translateY}`
		}
	}

	generateScatterPositions = () => {
		let positions = [];

		forEach(scatterPositions, (pos, i) => {
			positions.push({
				...pos,
				...getRandomXYTransform(i)
			})
		})

		this.setState({
			scatterPositions: positions
		})
	}

	renderFilters = () => {
		const { data, filtersActive, activeFilters, activeFilterSection } = this.state;
		const activeSection = find(data.cats, { slug: activeFilterSection });

		const navItems = data.cats?.map((item, i) => {
			return (
				<FilterNavItem
					key={i}
					active={activeFilterSection == item.slug}
					onClick={() => this.setState({ activeFilterSection: item.slug })}
				>
					{`Filter by ${item.name}`}
				</FilterNavItem>
			)
		})

		const filters = activeSection?.items?.map((item, i) => {
			const active = find(activeFilters, { slug: item.slug });

			return (
				<Filter
					key={item.slug}
					active={active}
					onClick={() => this.handleFilterChange(item)}
				>
					{item.title}

					{/* Count */}

					{item.count && (
						<Count>{item.count}</Count>
					)}
				</Filter>
			)
		})

		return (
			<OutsideClick
				disabled={!filtersActive}
				onOutsideClick={this.toggleFilters}
			>
				<Filters
					active={filtersActive}
				>
					<Container>
						<Close
							onClick={this.toggleFilters}
						/>

						<FilterNav>
							{navItems}
						</FilterNav>

						<FilterList>
							{filters}
						</FilterList>
					</Container>
				</Filters>
			</OutsideClick>
		)
	}

	renderToolbar = () => {
		const { view, activeFilters } = this.state;

		const functions = [
			{
				type: 'filter',
				icon: require('../assets/icons/filter-type.svg').default,
				active: activeFilters.length,
				onClick: this.toggleFilters
			},
			// {
			// 	type: 'view-grid',
			// 	icon: require('../assets/icons/filter-grid.svg'),
			// 	active: view == 'grid',
			// 	onClick: () => this.toggleView('grid')
			// },
			// {
			// 	type: 'view-scatter',
			// 	icon: require('../assets/icons/filter-scatter.svg'),
			// 	active: view == 'scatter',
			// 	onClick: () => this.toggleView('scatter')
			// },
		]

		const items = functions.map((item, i) => {
			return (
				<Function
					key={i}
					type={item.type}
					icon={item.icon}
					onClick={item.onClick}
					active={item.active}
				/>
			)
		})

		return <Toolbar>{items}</Toolbar>
	}

	renderScatter = () => {
		const { data, scatterPositions, activeProject, activeFilters } = this.state;

		const items = data.projects?.map((item, i) => {
			const active = activeProject == item.slug;

			item = {
				...JSON.parse(item.acf_json),
				...item
			}

			const hasLink = item.disable_page_display !== true;

			return (
				<ScatterItem
					key={i}
					style={scatterPositions[i]}
					active={active}
					userHovering={activeProject}
					matchesFilter={this.matchFilters(item)}
					filtering={activeFilters.length}
					hasLink={hasLink}
				>
					<LinkHeading
						to={`/projects/${item.slug}`}
						onMouseEnter={() => hasLink && this.toggleProject(item.slug)}
						onMouseLeave={() => this.toggleProject(null)}
						as={hasLink ? Link : 'div'}
					>
						{item.title}
					</LinkHeading>
				</ScatterItem>
			)
		})


		return (
			<Scatter
				key={`view-scatter`}
				{...animatedView('scatter')}
			>
				{items}
				{this.renderHover()}
			</Scatter>
		)
	}

	renderHover = () => {
		const { activeProject, data } = this.state;
		const currentProject = find(data.projects, { slug: activeProject });

		return (
			<Hover>
				<TransitionGroup>
					{data.projects.map((project, i) => {
						if (activeProject == project.slug) {
							return this.renderHoverMedia(currentProject, i)
						}
					})}
				</TransitionGroup>
			</Hover>
		)
	}

	renderHoverMedia = (project, i) => {

		project = {
			...project,
			...JSON.parse(project.acf_json),
			location: project.locations.length && project.locations[0].name || '',
			type: project.types.length && project.types[0].name || '',
		}

		return (
			<CSSTransition
				key={i}
				timeout={750}
				classNames="item"
			>
				<Media>
					<Transform
						style={{
							...this.getHoverTransform()
						}}
					>
						{project.heroImage && (
							<HoverImage
								src={project.heroImage.heroImage.mediaItemUrl}
							/>
						)}

						<Caption
							dangerouslySetInnerHTML={{
								__html: `
								${project.type && `${project.type} <br/>`}
								${project.location && project.location}
							`}}
						/>
					</Transform>
				</Media>
			</CSSTransition>
		)
	}

	renderGrid = () => {
		const { data, activeFilters } = this.state;

		const items = data.projects?.map((item, i) => {

			item = {
				...item,
				//...JSON.parse(item.acf_json),
				type: item.types.nodes.length && item.types.nodes[0].name || '',
			}
			//console.log(item)

			const hasLink = !item.disable_page_display;

			if (!hasLink) return;

			return (
				<Project
					key={i}
					matchesFilter={this.matchFilters(item)}
					filtering={activeFilters.length}
					as={'div'}
					hasLink={hasLink}
					mobileHide={!hasLink}
				>
					<Link to={`/projects/${item.slug}`}>
						{item.heroImage?.heroImage?.mediaItemUrl && (
							<BackgroundImage
								image={item.heroImage.heroImage.mediaItemUrl}
								sizes={'450px'}
							/>
						)}

						<Heading>{item.title}</Heading>
					</Link>
					<Subheading>{item.type}</Subheading>
				</Project>
			)
		})

		return (
			<Grid
				key={`view-grid`}
				view={'grid'}
				{...animatedView('grid')}
			>
				{items}
			</Grid>
		)
	}

	renderViews = () => {
		const { view, windowSize } = this.state;
		const isTablet = windowSize.width <= breakpoints.tablet;

		return (
			<AnimatePresence>
				{view == 'scatter' && !isTablet ? this.renderScatter() : this.renderGrid()}
			</AnimatePresence>
		)
	}

	render() {
		return (
			<Layout
				meta={this.props.data?.wpPage?.seo}
				footer
				footerExtendWidth={true}
			>
				{this.renderFilters()}

				<Container>
					{this.renderToolbar()}
					{this.renderViews()}
				</Container>
			</Layout>
		)
	}
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
	transition: opacity 0.45s ease;
	background-image: url(${props => props.image});
	${bgImage}
`

// Layout

const Container = styled.div`
	${container}
	
	display: flex;
	flex-direction: column;
	max-width: none;
	overflow-x: hidden;
`

// Views

const animatedView = (view) => ({
	initial: {
		y: view == 'grid' ? 200 : 0,
		opacity: 1,
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.35,
		}
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.2,
		}
	},
})


// Grid

const Grid = styled(motion.div)`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	max-width: 1280px;
	align-self: flex-end;

	${media.desktopSmall`
		max-width: 940px;
	`}

	${media.tablet`
		${padding}
		max-width: none;
	`}
`

const Project = styled(Link)`
	display: flex;
	flex-direction: column;
	margin-bottom: 59px;
	flex: 0 1 calc(33.5% - 13.5px);

	${media.desktopSmall`
		flex: 0 1 calc(50% - 13.5px);
	`}

	${media.phone`
		flex: 0 1 100%;
	`}
	
	.bg-image {
		height: 350px;
		width: 100%;
	}

	${Heading} {
		font-size: 28px;
		font-weight: 500;
		margin-top: 20px;
	}

	${Subheading} {
		font-size: 20px;
		color: ${grey};
		margin-top: 4px;
	}

	${props => {
		if (props.filtering && !props.matchesFilter) return css`
			${BGImage} {
				filter: grayscale(1);
				opacity: 0.2 !important;
			}
		`
	}}

	${props => {
		if (props.hasLink) return css`
			&:hover {
				cursor: pointer;
			}
		`
	}}

	${props => {
		if (props.mobileHide) return css`
			${media.phone`
				display: none
			`}
		`
	}}
`

// Scatter

const Scatter = styled(motion.div)`
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	height: 100vh;
	overflow: hidden;
`

// Scatter Item

const LinkHeading = styled.div``

const ScatterItem = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	align-content: space-between;
	z-index: 2;
	transition: opacity 0.45s ease;
	opacity: 1;

	${LinkHeading} {
		font-size: 24px;
		font-weight: 500;
		border-bottom: 1px solid transparent;
		transition: all 0.45s ease;
		cursor: default;

		${props => {
		if (props.hasLink) return css`
				cursor: pointer;
				opacity: 1 !important;
				
				&:hover {
					border-bottom: 1px solid black;
				}
			`
	}}
	}

	&:first-child {
		flex: 1 0 80% !important;
	}

	${props => {
		if (!props.hasLink) return css`
			opacity: 0.5;			
		`
	}}

	${props => {
		if (props.active && props.hasLink) return css`
			opacity: 1;			
		`
	}}

	/* Active & Over Hover Image */

	${props => {
		if (props.overHoverImage) return css`
			${LinkHeading} {
				color: white;
				border-bottom: 1px solid white;
			}
		`
	}}

	/* Inactive & Hovering */

	${props => {
		if (props.userHovering !== null && !props.active) return css`
			opacity: 0.3;
			z-index: 1;
		`
	}}

	/* Filtering & Unmatched */

	${props => {
		if (props.filtering && !props.matchesFilter) return css`
			opacity: 0.3;
		`
	}}
`

// Project Hover

const Hover = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Media = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;

	will-change: opacity;
	transition: opacity 0.5s ease;

	&.item-enter-active,
	&.item-enter-done {
		opacity: 1;
	}

	&.item-exit {
		opacity: 0;
	}
`

const Transform = styled.div`
	transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
	will-change: transform;
`

const HoverImage = styled.img`
    width: auto;
    height: auto;
    max-width: 900px;
    max-height: 560px;
`

const Caption = styled.div`
	font-size: 16px;
	line-height: 19px;
	position: absolute;
	bottom: 17px;
	left: 17px;
	color: white;
`

// Toolbar

const Toolbar = styled.div`
	position: fixed;
	top: 20px;
	right: 20px;
	display: flex;
	z-index: 3;
`

const Function = styled.div`
	background-image: url(${props => props.icon});
	height: 18px;
	width: 17px;
	${bgIcon};
	cursor: pointer;

	/* Animation */

	opacity: 1;
	transition: opacity 0.25s ease;
	will-change: opacity;

	&:hover {
		opacity: 1;
	}

	${props => {
		if (props.active) return css`
			opacity: 1;
		`
	}}

	&:not(:last-child) {
		margin-right: 11px;
	}

	/* Filter */

	${props => {
		if (props.type == 'filter') return css`
			height: 24px;
			width: 24px;
			margin-right: 9px;
		`
	}}

	/* Filter Active */

	${props => {
		if (props.active && props.type == 'filter') return css`
			position: relative;	

			&::after {
				content: '';
				position: absolute;
				left: -2px;
				top: 7px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: black;
			}
		`
	}}

	${media.tablet`
		display: none;

		${props => {
			if (props.type == 'filter') return css`
				display: flex;
				width: 30px;
				height: 27px;
				opacity: 1;
				margin-right: 64px !important;
			`
		}}
	`}

`

// Filters

const Close = styled.div`
	background-image: url(${require('../assets/icons/filter-close.svg').default});
	height: 12px;
	width: 12px;
	${bgIcon}
`

const Filters = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background: #F4F4F4;
	z-index: 11;
	font-size: 18px;

	${Container} {
		${padding};
		padding-top: 30px;
		height: 126px;
		position: relative;

		${Close} {
			position: absolute;
			right: 20px;
			top: 20px;
			cursor: pointer;
		}
	}

	/* Animation */

	opacity: 0;
	transform: translateY(-100%);
	transition: 
		opacity 0.35s ease,
		transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

	${props => {
		if (props.active) return css`
			transform: none;
			opacity: 1;
			transition: 
				opacity 0.4s ease,
				transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
		`
	}}
`

// Filter Nav

const FilterNav = styled.div`
	display: flex;
`

const FilterNavItem = styled.div`
	display: flex;
	cursor: pointer;
	user-select: none;

	&:not(:last-child) {
		margin-right: 44px;
	}

	/* Active */

	opacity: 0.8;
	transition: 
		opacity 0.35s ease,		
		border 0.35s ease;

	${props => {
		if (props.active) return css`
			opacity: 1;
		`
	}}
`

// Filter List

const FilterList = styled.div`
	display: flex;
	margin-top: 20px;
`

const Count = styled.div`
	margin-left: 8px;
	font-size: 12px;

	position: absolute;
	right: -8px;
	top: -4px;
	transform: translateX(100%);
`

const Filter = styled(FilterNavItem)`
	border-bottom: 1px solid transparent;
	position: relative;

	&:not(:last-child) {
		margin-right: 32px;
	}

	${props => {
		if (props.active) return css`
			border-bottom: 1px solid black;
		`
	}}
`

export const query = graphql`
	query IndexQuery {
		wpPage(title: {eq: "Home"}) {
			seo {
        ...SeoData
      }	
		}
		allWpPage {
			nodes {
				title
				slug
			}
		}
		allWpProject(sort: {menuOrder: ASC}) {
			nodes {
				title
				slug
				heroImage {
					heroImage {
						mediaItemUrl
					}
				}
				types {
					nodes {
						name
						slug
					}
				}
				locations {
					nodes {
						name
						slug
					}
				}
				structures {
					nodes {
						name
						slug
					}
				}
			}
		}
	}
`

export default withHeader(Index)
