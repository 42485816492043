import React from 'react'
import { Provider } from 'react-redux'
import store from './src/store/configureStore'
import { GlobalStyles } from './src/styles/global';
import { Splash } from './src/components';
import HeaderContext from './src/components/Header/HeaderContext';

export default ({ element, props  }) => {
    return (
        <Provider 
            store={store}
        >   
            <HeaderContext>
                <GlobalStyles/>
                {element}
            </HeaderContext>
        </Provider>
    )
}