import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { connect } from 'react-redux'

import { Layout, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'

import EditDetails from '../templates/account/edit-details'

const PrivateRouter = ({ children }) => (
	<Location>
	  	{({ location }) => (
            <Router location={location}>
                {children}
            </Router>
		)}
	</Location>
);

const Account = () => {

    return <EditDetails/>

    return (
        <PrivateRouter>
            <PrivateRoute 
                path="/account/edit-details" 
                component={EditDetails} 
            />
            <EditDetails/>
        </PrivateRouter>
    )
}

export default Account
