import { forEach, filter, round, camelCase } from 'lodash'
import { isAuthenticated } from '../services/auth';
import { calculateTotal } from '../components/Cart/Cart';

export const transformTokenData = (fields) => {
    return {
        name: `${fields.billingFirstName.value} ${fields.billingLastName.value}`,
        firstname: fields.billingFirstName.value,
        lastname: fields.billingLastName.value,
        phone: fields.billingPhone.value,
        company: fields.billingCompany.value
    }
}

export const transformBillingShippingFields = (fields, type) => {
    return {
        email: type == 'billing' && fields[`${type}Email`].value,
        phone: type == 'billing' && fields[`${type}Phone`].value,
        first_name: fields[`${type}FirstName`].value,
        last_name: fields[`${type}LastName`].value,
        company: fields[`${type}Company`].value,
        address_1: fields[`${type}Address1`].value,
        address_2: fields[`${type}Address2`].value,
        city: fields[`${type}City`].value,
        state: fields[`${type}State`].value,
        postcode: fields[`${type}Postcode`].value,
        country: fields[`${type}Country`].value,
    }
} 

export const transformAttributes = (attributes) => {
    let metaData = []

    forEach(attributes, (attr) => {
        metaData.push({
            key: `pa_${attr.tax}`,
            value: attr.slug,
        })
    })

    return metaData
}

export const transformCartItems = (cart) => {
    let cartItems = []

    forEach(cart, (item) => {
        cartItems.push({
            quantity: item.quantity,
            product_id: item.wordpress_id,
            variation_id: item.id,
            meta_data: transformAttributes(item.attributes)
        })
    })

    return cartItems
}

export const transformOrderData = (fields, cart, shippingPrice, user) => {
    const useBillingForShipping = fields && fields.useBillingForShipping.value;
    const { cardNumber, cardExpiry, CVC } = fields.creditCard.value;
 
    return {
        totals: {
            sub_total: calculateTotal(cart, true).toFixed(2),
            shipping_total: shippingPrice,
            gst: ((calculateTotal(cart, true).toFixed(2)) * 0.1).toFixed(2),
            total: (((calculateTotal(cart, true).toFixed(2)) * 1.1) + shippingPrice).toFixed(2)
        },
        billing: transformBillingShippingFields(fields, 'billing'),
        shipping: transformBillingShippingFields(fields, useBillingForShipping ? 'billing' : 'shipping'),
        line_items: transformCartItems(cart), 
        is_wholesale: isAuthenticated(),
        customer_note: fields.customerNote.value,
        payment_details: {
            card_name: fields[`cardName`].value,
            card_number: cardNumber,
            card_cvn: CVC,
            card_expiry: cardExpiry,
        },
        customer_id: isAuthenticated() ? user.customer_id : false,
    }
}