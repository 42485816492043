import React from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { kebabCase } from 'lodash'
import ProgressiveImage from "react-progressive-image";

import { Layout, Block } from '../components'

import { container, padding, bgImage, bgIcon } from '../styles/global';
import { grey } from '../styles/colors';
import { media } from '../styles/utils';

class Studio extends React.Component {

	state = {
	}

	componentWillMount = () => {
        this.setState({
             //data: JSON.parse(this.props.data.allWordpressInfopages.nodes[0].acf_json)	
             data: this.props.data.wpPage.template
        })
    }

    componentDidMount() {
        window.addEventListener('hashchange', this.onHashChange, false);

    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.onHashChange, false);
    }
    
    onHashChange = (e) => {
        const { hash } = location

        document.querySelector('#background').scrollIntoView({
            behavior: 'smooth'
        });
    }
    
    renderHeading = () => {
        const { data } = this.state

        if(!data.tagline) return

        return (
            <Container>
                <HeadingWrapper>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.tagline}}
                    />
                </HeadingWrapper>
            </Container>
        )        
    }
    
    renderBlocks = (blocks) => {
        if (!blocks) return;
        
		return blocks.map((block, i) => {  
			return (
				<Block
					layout={block.acf_fc_layout || block.__typename || block.fieldGroupName}
                    key={i}
					{...block}
				/>
			)
		})
	}

    renderSections = () => {
        const { wpPage } = this.props.data
    
        const items = wpPage.template?.sections?.sections?.map((item, i) => {

            return (
                <Section 
                    id={item.showSectionInSubNav && kebabCase(item.navTitle)}
                    key={i}
                >
                    {this.renderBlocks(item.blocks)}
                </Section>
            )
        })

        return (
            <Sections>{items}</Sections>
        )
    }
    
	render() {       
        return (
			<Layout
                footer={true}
                meta={this.props.data?.wpPage?.seo}
            >
				<Wrapper>
                    {this.renderHeading()}
                    {this.renderSections()}
				</Wrapper>
			</Layout>	
		)
	}
}

export const maxWidth = css`
    max-width: 940px;

    ${media.tablet`
        max-width: none;
    `}
`

export const indentedBlock = css`
    ${maxWidth};
    padding-left: 150px;
    width: 100%;
    box-sizing: border-box;

    ${media.tablet`
        padding-left: 0;
    `}
`

// Shared

const Heading = styled.div``
const Subheading = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
	transition: opacity 0.45s ease;
	background-image: url(${props => props.image});
	${bgImage}
`

// Layout

const Wrapper = styled.div`	
	display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 100%;
    padding-top: 110px;
    padding-bottom: 300px;

    ${media.phone`
        padding-top: 40px;
        padding-bottom: 128px;
    `}
`

// Heading

const Container = styled.div`
    ${container};
    max-width: none;
    justify-content: center;
`

const HeadingWrapper = styled.div`
    ${container};
    justify-content: flex-end;

    ${Heading} {
        font-size: 70px;
        font-weight: 500;
        width: 100%;
        padding-left: 120px;
        ${maxWidth}
        box-sizing: border-box;   
    }

    ${media.phone`
        ${padding}
        margin-bottom: 48px;

        ${Heading} {
            padding-left: 0;
            font-size: 28px;
        }
    `}
`

// Sections

const Sections = styled.div`
    width: 100%;
`

const Section = styled.div`
    padding-top: 98px;

    ${media.tablet`
        padding-top: 48px;
    `}
    
    ${media.phone`
        padding-top: 0px;
    `}
`

export const query = graphql`
	query {
            wpPage(slug: { eq: "studio" }) {
                seo {
                    ...SeoData
                }
                template {
                    ... on WpTemplate_Studio {
                        templateName
                        sections {
                          sections {
                            blocks {
                                __typename
                              ... on WpTemplate_Studio_Sections_sections_Blocks_Title {
                                content
                                displayBlock
                              }
                              ... on WpTemplate_Studio_Sections_sections_Blocks_Images {
                                displayBlock
                                fieldGroupName
                                useSmallImage
                                position
                                largeImage {
                                  altText
                                  mediaItemUrl
                                }
                                smallImage {
                                  altText
                                  mediaItemUrl
                                }
                              }
                              ... on WpTemplate_Studio_Sections_sections_Blocks_Text {
                                content
                                displayBlock
                                twoColumns
                                fieldGroupName
                              }
                              ... on WpTemplate_Studio_Sections_sections_Blocks_TextGrid {
                                displayBlock
                                fieldGroupName
                                textItems {
                                  text
                                }
                              }
                              ... on WpTemplate_Studio_Sections_sections_Blocks_AwardsList {
                                displayBlock
                                fieldGroupName
                                awardItems {
                                  awards
                                  year
                                  project {
                                    ... on WpProject {
                                      id
                                      title
                                    }
                                  }
                                }
                              }
                            }
                            navTitle
                            showSectionInSubNav
                          }
                          fieldGroupName
                        }
                        headingTagline {
                          fieldGroupName
                          studio
                        }
                      }
                }
            }
	}
`

export default Studio

