import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer';

import { bgImage } from '../../styles/global'
import { WP_MEDIA_BASE_URL, IMGIX_BASE_URL } from '../../constants'
import { isClient } from '../../styles/utils'

const ShopifyBgImage = (props) => {
	const { className, image, children, objectFit, preload, fadeIn, ...rest } = props
    const [loaded, setLoaded] = useState(false)
    
    // const { ref, inView } = useInView({
    //     threshold: 0,
    //     triggerOnce: true,
    //     rootMargin: '2000px'
    // });

    if (!image) return false

    const imageOriginalURL = image?.src;

    //const imageURL = imageOriginalURL?.replace?.(WP_MEDIA_BASE_URL, IMGIX_BASE_URL)
    const imageURL = imageOriginalURL

	return (
		<Wrapper
            // ref={ref}
			className={['bg-image', className].join(' ')}
			{...props}
            objectFit={objectFit}
            loaded={fadeIn ? loaded : true}
		>
			{imageURL && (
				<Image
                    className="lazyload"
                    as='img'
					src={imageURL}
					alt={image?.alt}
                    {...rest}
                    htmlAttributes={{
                        onLoad: () => {
                            setLoaded(true)
                        },
                    }}
				/>
			)}
            {props.children}
		</Wrapper>
	)
}

const Image = styled.div``

const Wrapper = styled.div`
	position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    ${Image} {
		//position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
        width: 100%;
        //height: 100%;
        opacity: 1;
        transition: opacity 0.45s ease;
    }

	${Image} {
        object-fit: cover;

        ${props => {
            if (props.objectFit)
                return css`
                    object-fit: ${props.objectFit};
                `
        }}

        ${props => {
            if (props.imageCrop)
                return css`
                    object-position: ${props.imageCrop};
                `
        }}
	}

    /* Animation */
    
    opacity: 0;
    transition: opacity 0.25s ease;

    ${props => {
        if (props.loaded)
            return css`
                opacity: 1;
            `
    }}
`

export default ShopifyBgImage