import React, { Component } from 'react'
import Helmet from 'react-helmet'

class MetaTags extends Component {
    
    static defaultProps = {
        title: 'Archier',
	}
	
    render() {

        const { 
            title, 
            metaDesc, 
            opengraphDescription,
            opengraphImage,
            opengraphTitle,
            twitterDescription, 
            twitterImage,
            twitterTitle,
        } = this.props;
		
        return (
            <Helmet
                title={title}
                meta={[
                    { name: 'description', content: metaDesc},
                    { property: 'og:image', content: opengraphImage?.sourceUrl },
                    { property: 'og:title', content: opengraphTitle },
                    { property: 'og:description', content: opengraphDescription },
                    { property: 'twitter:card', content: 'summary' }, 
                    { property: 'twitter:image', content: twitterImage?.sourceUrl, }, 
                    { property: 'twitter:title', content: twitterTitle },
                    { property: 'twitter:description', content: twitterDescription}
                ]}
            />
        )
    }
}

export default MetaTags