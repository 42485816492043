import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { maxWidth } from '../../../pages/studio';

class BlockTitle extends React.Component {

	state = {
	
	}
	
	render() {
        const { content } = this.props;
        
		return (
			<Container>
				<Heading>{content} —</Heading>
			</Container>
		)
	}
}

const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${container}

	${media.tablet`
		${padding}
	`}
`

const Heading = styled.div`
	${maxWidth};
	width: 100%;

	font-size: 38px;
	line-height: 44px;
	font-weight: 500;

	${media.phone`
		font-size: 24px;
	`}
`

BlockTitle.wrapper = css`
	display: flex;
	justify-content: center;
	margin-bottom: 40px;

`

export default BlockTitle
