import React from "react"
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { Layout, Header, Splash } from './components'

class Transition extends React.PureComponent {
	render() {
		const { children, location, hideHeader } = this.props
		
		const duration = 0.35;
		const variants = {
			initial: {
				opacity: 0,
			},
			animate: {
				opacity: 1,
				transition: {
					duration: duration,
					delay: duration,
					when: 'beforeChildren',
				},
			},
			exit: {
				opacity: 0,
				transition: { duration: duration },
			},
		}
		
	
		return (
			<>
				{!hideHeader && (
					<Header
						location={location}
					/>
				)}

				<Splash/>

				<AnimatePresence>
					<Wrapper
						key={location.pathname}
						{...variants}
					>
						{children}	
					</Wrapper>
				</AnimatePresence>
			</>
		)
	}
}

const Wrapper = styled(motion.div)`
	width: 100%;
	display: flex;
	justify-content: center;
`

export default Transition