import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Link, navigate } from 'gatsby'
import NumberFormat from 'react-number-format'
import FullHeight from 'react-div-100vh';

import { Spinner } from '../'

import { fetchCart, getCart, addToCart, updateQuantity } from '../../store/cart';
import { fetchSettings, updateSettings, getSettings } from '../../store/settings'
import { getCartQuantity, getCartTotal } from './utils'

import { media, width } from '../../styles/utils'
import { container, bgImage, bgIcon, padding, hoverState } from '../../styles/global';
import { grey, black } from '../../styles/colors';
import { getNumberScaleProps, getPriceProps } from '../../utils/product';
import { isAuthenticated } from '../../services/auth';

const Cart = (props) =>  {
    const { cart, settings } = props;   

    const fetchData = () => {
        props.fetchCart();
        props.fetchSettings();
    }

    useEffect(() => {fetchData()}, []);
    
    const toggleCart = () => {
        const { updateSettings } = props;

        updateSettings({
            cartActive: false
        })
    }  

    const goToCheckout = () => {
        const { cart } = props;

        toggleCart();
        navigate(cart.webUrl)
    }
    
    const renderCart = () => {
        const { cart, updateQuantity } = props;
        const cartQuantity = getCartQuantity(cart);        

        return (
            <>
                <Heading>{width.phone() ? 'Your Cart' : `${cartQuantity} ${cartQuantity == 1 ? 'item' : 'items'} added to bag`}</Heading>
                {renderCartItems(cart, toggleCart, updateQuantity)}
            </>
        )
    }   

    const renderSubtotals = () => {
        const { cart } = props;
        return (
            <Subtotals>
                <Total>
                    Number of Items <span>{getCartQuantity(cart)}</span>
                </Total>
                <Total>
                    Sub Total Amount  
                    <NumberFormat 
                        value={calculateTotal(cart)} 
                        prefix={'$'}
                        displayType={'text'} 
                        {...getNumberScaleProps()}     
                    />
                </Total>
            </Subtotals>
        )
    }

    const renderFooter = () => {
        const { cart } = props;

        return (
            <Footer>
                <Back 
                    onClick={toggleCart}
                >
                    {`Go back`}
                </Back>

                <Checkout
                    onClick={goToCheckout}
                    hasItems={cart?.lineItems?.length > 0}
                >
                    {'Checkout'}
                </Checkout>
            </Footer>
        )        
    }

    return (
        <>
            <Wrapper
                active={settings.cartActive}
            >
                <Container>
                    <Header>
                        <Label onClick={toggleCart}>
                            {`Return to Products`}
                        </Label>
                    </Header> 

                    {renderCart()}

                    <Line
                        marginBottom={true}
                    />   

                    {renderSubtotals()}
            
                    {!width.phone() && (
                        <Checkout
                            onClick={goToCheckout}
                            hasItems={settings.cartActive && cart?.lineItems?.length > 0}
                        >
                            {'Checkout'}
                        </Checkout>
                    )}

                    {width.phone() && renderFooter()}
                </Container>
            </Wrapper>

            <Background
                active={settings.cartActive}
                onClick={toggleCart}
            />
        </>
    )
}


export const renderCartItems = (cart, toggleCart, updateQuantity, inCheckout) => {
    let cartItems;

    if (cart?.lineItems?.length > 0) {
        cartItems = cart.lineItems.map((item, i) => {
            return (
                <CartItem
                    key={i}
                >
                    <Link
                        onClick={toggleCart}
                        to={`/product/${item.variant.product.handle}`}
                    >
                        <Image
                            image={item.variant.image.src}
                        />
                    </Link>

                    <Info>

                        {/* Title */}

                        <Link
                            onClick={toggleCart}
                            to={`/product/${item.variant.product.handle}`}
                        >
                            <Heading>{item.title}</Heading>
                        </Link>

                        {/* Attributes */}
                      
                        {item.variant?.selectedOptions?.map((item, i) => (
                            <Subheading
                                key={i}
                            >
                                {item.value}
                            </Subheading>
                        ))}

                        {/* Subtotals */}

                        <Subtotals>
                            <Total>
                                {isAuthenticated() ? 'Trade price' : 'Price'}
                                <NumberFormat 
                                	{...getPriceProps(item.variant.priceV2)}
                                    {...getNumberScaleProps()}    
                                />
                            </Total>

                            {updateQuantity && (
                                <Total>
                                    Quantity 
                                    <Spinner cartItem={item}/>
                                </Total>
                            )}

                            {inCheckout && (
                                <Total>
                                    Quantity 
                                    <Label>{item.quantity}</Label>
                                </Total>
                            )}
                    
                            <Total>
                                Item Subtotal 
                                <NumberFormat 
                                    value={getPriceProps(item.variant.priceV2).value * item.quantity} 
                                    prefix={'$'}
                                    displayType={'text'} 
                                    {...getNumberScaleProps()}  
                                />
                            </Total>
                        </Subtotals>
                        
                    </Info>

                    {updateQuantity && (
                        <Remove
                            onClick={() => updateQuantity({
                                ...item,
                                quantity: 0
                            })}
                        />
                    )}
                </CartItem>
            )
        }); 
    }   

    return (
        <CartItems>
            {cartItems}
        </CartItems>
    )
}

export const calculateTotal = (cart, exGST) => {
    let cartTotal = getCartTotal(cart, exGST);
    return cartTotal;
}  

const Wrapper = styled(FullHeight)`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0 auto;
    height: 100vh;
    width: 490px;
    background: white;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    overflow: hidden;
    box-shadow: -8px 0 12px 0 rgba(0,0,0,0.04);

    margin-bottom: 16px;
    z-index: 100;

    transition: transform 0.35s ease;
    transform: translateX(100%);
    pointer-events: none;
    opacity: 0;

    ${props => {
        if (props.active) return css`
            transform: translateX(0);
            pointer-events: all;
            opacity: 1;
        `
    }}

    ${media.tablet`
        width: 100vw;
        transition: opacity 0.3s ease-in-out;
        transform: translateX(0);

        ${props => {
            if (props.active) return css`
                transition: opacity 0.15s ease-in-out;
            `
        }}
    `}

    ${media.phone`
        width: 100vw;
        height: 100vh;
        top: 0;
        border-left: none;
    `}
`

// Shared

const Line = styled.div`
    border-bottom: 1px solid #CFCFCF;
`

const Heading = styled.div``
const MobileHeading = styled.div`
    display: none;

    ${media.phone`
        display: flex !important;
    `}
`

const Image = styled.div`
    background-image: url(${props => props.image});
    ${bgImage}
`

const Subheading = styled.div``
const Description = styled.div``
const Label = styled.div``

const Panel = css`
    display: flex;
    justify-content: space-between;
`

// Container

const Container = styled.div`
    ${container}
    ${padding}
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    > ${Heading} {
        font-size: 38px;
        font-weight: 500;
        line-height: 44px;
    }

    ${media.phone`
        padding-bottom: 15px;
        padding-top: 16px;

        > ${Heading} {
            text-align: center;
            font-size: 16px;
        }
    `}
`

// Background

const Background = styled.div`
    z-index: 4;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${black};
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.35s ease;

    ${props => {
        if (props.active) return css`
            opacity: 0.2;
            pointer-events: all;
        `
    }}

    ${media.phone`
        display: none;
    `}
`

// Cart header

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1px;
    margin-bottom: 79px;

    ${Label} {
        ${hoverState};
        cursor: pointer;
    }

    ${media.phone`
        display: none;
    `}
`

// Subtotal

const Total = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    &:not(:last-child) {
       margin-bottom: 12px;
    } 
`

const Subtotals = styled.div`
    margin-top: 24px;
`

// Cart Items

const CartItems = styled.div`
    flex: 1;
    padding-top: 23px;
    margin-top: 12px;
    max-height: 100%;
    overflow-y: scroll;

    /* Scroll Bar Overlap Fix */
    
    width: calc(100% + 32px);
    padding-right: 32px;
    box-sizing: border-box;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 32px;
        border-top: 1px solid #CFCFCF;
    }
` 

// Cart Item

const Info = styled.div``
const Remove = styled.div``

const CartItem = styled.div`
    display: flex;
    font-size: 14px;
    position: relative;
    border-bottom: 1px solid #CFCFCF;
    padding-bottom: 28px;   

    a:first-child {
        margin-bottom: 12px;
    }

    ${Subheading} {
        font-size: 16px;
        margin-bottom: 5px;
        opacity: 0.5;
    }

    ${Info} {
        flex: 1;
        display: flex;
        flex-direction: column;

        ${Heading} {
            font-weight: bold;
            font-size: 20px;
            line-height: 21px;
            font-weight: 500;
        }

        * {
            user-select: none;
        }
    }

    ${Image} {
        height: 120px;
        width: 120px;
        margin-right: 24px;

        ${media.tablet`
            height: 80px;
            width: 80px;
        `}
    }

    ${Remove} {
        position: absolute;
        top: 0;
        right: 0;
        background-image: url(${require('../../assets/icons/cart-remove.svg').default});
        ${bgImage};
        height: 12px;
        width: 12px;
        ${hoverState}
        cursor: pointer;
    }

    ${Subtotals} {
        margin-top: auto;
        padding-top: 24px;

        ${Total} {
            justify-content: flex-start;

            > *:first-child {
                margin-left: 12px;
            }
        }
    }
    
    &:not(:last-child) {
       margin-bottom: 24px;

        ${media.phone`
            margin-bottom: 24px;
        `}
    } 
`


// Checkout

const Checkout = styled.div`
    ${hoverState}
    background: ${black};
    color: white;
    height: 44px;
    width: 100%;
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    pointer-events: ${props => props.hasItems ? 'all' : 'none'};
    opacity: ${props => props.hasItems ? 1 : 0.6};
    cursor: ${props => props.hasItems ? 'pointer' : 'default'};
`

// Footer (Mobile)

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > * {
        height: 48px;
        width: auto;
        flex: 0 1 calc(50% - 5px);
        border: 1px solid black;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const Back = styled.div``

const mapDispatchToProps = dispatch => ({
    fetchCart() {
        dispatch(fetchCart())
    },
    fetchSettings() {
        dispatch(fetchSettings())
    },
    addToCart(cartItem) {
        dispatch(addToCart(cartItem))
    },
    updateQuantity(cartItem) {
        dispatch(updateQuantity(cartItem))
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    ...getCart(state),
    settings: getSettings(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart);