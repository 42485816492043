import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { maxWidth, indentedBlock } from '../../../pages/studio';

class BlockTextGrid extends React.Component {

	state = {
	
    }
    
    renderItems = () => {
        const { textItems } = this.props;
    
        return textItems?.map((item, i) => {
            return (
                <Text
                    dangerouslySetInnerHTML={{__html: item.text}}  
                />
            )
        })
    }
    
	render() {
		return (
            <Container>
                <Grid>
                    {this.renderItems() }
                </Grid>
            </Container>
		)
	}
}

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    ${padding};
    ${container}
`

const Grid = styled.div`
    display: flex;
    flex-flow: row wrap;
	${indentedBlock}
`

const Text = styled.div`
	font-size: 20px;
    line-height: 24px;
    margin-right: 22px;

    box-sizing: border-box;
    flex: 0 1 calc(50% - 22px);
    margin-bottom: 78px;

    ${media.phone`
		font-size: 16px;
		line-height: 22px;
		columns: 1;
        flex: 0 1 auto;
        margin-bottom: 22px;
	`}
`

BlockTextGrid.wrapper = css`
    display: flex;
    justify-content: center;
`

export default BlockTextGrid
