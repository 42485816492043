import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components'

import { container, padding } from '../styles/global';
import { media } from '../styles/utils';

class FourOhFour extends React.Component {

	state = {

	}

	render() {        

		return (
			<Layout>
				<Container>

                    <Content
                        dangerouslySetInnerHTML={{__html: `
                            Sorry this page cannot be found          
                        `}}
                    />


				</Container>
			</Layout>	
		)
	}
}


const Container = styled.div`
	${container}
    ${padding}
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    align-self: center;
    margin-top: -200px;

    ${media.phone`
        margin-top: -48px;
    `}
`

const Content = styled.div`
    font-size: 24px;
    max-width: 500px;
    text-align: center;

    ${media.phone`
        font-size: 16px;
    `}
`


export default FourOhFour

