import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

class BlockFAQ extends React.Component {

	state = {
	
	}
	
	render() {
        const { content } = this.props;
        
		return (
            <Container>
	
			</Container>
		)
	}
}


const Container = styled.div`
    display: flex;
	justify-content: flex-end;
	${padding};
`

const Text = styled.div`

`

BlockFAQ.wrapper = css`

`

export default BlockFAQ
