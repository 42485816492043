import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'

import { MetaTags, Cart, Footer } from '../'
import { media } from '../../styles/utils';
import Transition from '../../transition';
import { useLocation } from 'react-use';

const Layout = ({ children, meta, footer, hideMobileHeader, hideHeader, footerExtendWidth }) => {
	const location = useLocation();

	return (
		<>
			<MetaTags
				{...meta}
			/>

			<Helmet>
				<meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
				<meta name="p:domain_verify" content="05a7f396f2ed14881554765ccb38ef31" />
				<meta name="google-site-verification" content="WoK1B46KA61LCLYbrbW4y9XUvgVFyiT3lbXoQ3_VH_w" />
				<script>
					{`
						!function(e){if(!window.pintrk){window.pintrk = function () {
						window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
						n=window.pintrk;n.queue=[],n.version="3.0";var
						t=document.createElement("script");t.async=!0,t.src=e;var
						r=document.getElementsByTagName("script")[0];
						r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
						pintrk('load', '2613675557321', {em: '<user_email_address>'});
						pintrk('page');
					`}
				</script>
				<script async src="https://www.googletagmanager.com/gtag/js?id=AW-738089821"></script>
				<script>
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());

						gtag('config', 'AW-738089821');
					`}
				</script>
				<noscript>
					{'<img height="1" width="1" style="display:none;" alt=""src="https://ct.pinterest.com/v3/?event=init&tid=2613675557321&pd[em]=<hashed_email_address>&noscript=1" />'}
				</noscript>
			</Helmet>


			<Wrapper
				footer={footer}
				hideMobileHeader={hideMobileHeader}
			>
				<Transition
					hideHeader={hideHeader}
					location={location}
				>
					{children}
				</Transition>
			</Wrapper>

			{footer && (
				<Footer
					footerExtendWidth={footerExtendWidth}
				/>
			)}

			<Cart />
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
	will-change: opacity;

	${props => {
		if (props.footer) return css`
			min-height: calc(100vh - 43px);
		`
	}}

	${props => {
		if (!props.hideMobileHeader) return css`
			${media.tablet`
				margin-top: 60px;
			`}
		`
	}}
`

export default Layout
