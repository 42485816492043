import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import ShopifyBgImage from '../../../components/BackgroundImage/ShopifyBgImage'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { getImageCrop } from '../../../utils';

class BlockProductImages extends React.Component {

	state = {
	
	}

	renderImages = () => {
		const { image, images, imageLayout} = this.props;
		const imagesToMap = [image, ...images];

		return imagesToMap.map((image, i) => {
			//console.log(image)
			if (!image) return;

			image = image.image ? image.image : image;
			
			//if (!image.sizes) return;

			return (
				<BGImage 
					key={i}
					as={ShopifyBgImage}
					className={'product-image'}
					size={image.width > image.height ? 'full_width' : '2x_half_width'}
					disableSrcSet
					disableQualityByDPR
					width={imageLayout == 'full_width' ? '100%' : '600px'}
					image={image} 
				/> 
			)
		})
	}
	
	render() {        
		return (
			<Container>
				{this.renderImages()}
			</Container>
		)
	}
}

BlockProductImages.defaultProps = {
	images: [],
}

// Layout

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
`

// Images

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
	flex: 1 0 100%;

	/* Size */

	${props => {
        if (props.size == 'full_width') return css`
       		//padding-bottom: 70%;
        `
    }}

    ${props => {
        if (props.size == '2x_half_width') return css`
			//padding-bottom: 100%;
			flex: 0 1 calc(50% - 10px);

			${media.phone`
				flex: 0 1 100%;
			`}
        `
    }}
`

const Text = styled.div`
	
`

BlockProductImages.wrapper = css`
	margin-bottom: 18px;
`

export default BlockProductImages
