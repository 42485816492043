import React, { useState, useRef } from 'react';
import { useSetState, useMount } from 'react-use';
import { connect } from 'react-redux';
import { Link, graphql } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import { filter, find, forEach, camelCase } from 'lodash'
import Form from 'react-dynamic-form'
import ProgressiveImage from "react-progressive-image"

import { Layout, MetaTags, Input } from '../../components'

import { fetchUser, updateUser, getUser } from '../../store/user'
import { getCart, fetchCart } from '../../store/cart';
import { postData } from '../../services/api';

import { container, padding, bgIcon, button } from '../../styles/global';
import { media, isClient } from '../../styles/utils';
import { black } from '../../styles/colors';
import { inputStyle, red, error } from '../../components/Input/Input';
import { handleLogin, getUserData } from '../../services/auth';
import { required, password } from '../../utils/validators';
import { editDetails, editAddress } from '../../data/account';
import { formHeading, formContent, formSidebar } from '../../pages/checkout';
import { maxWidth } from '../../pages/studio';
import { transformTradeData } from '../../utils/trade';

const EditDetails = (props) => {
	const editAddressForm = useRef(null);
	const editDetailsForm = useRef(null);

	const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
		error: false,
	})

	useMount(() => {
        props.fetchUser()
	})

	const getPopulatedFields = () => {
		const user = props.user;
		
        if (user) {
			const addressData = {};
			
            forEach(user, (value, key) => {	
                addressData[`${camelCase(key)}`] = value;		
			})
			
            return addressData
        }
	}

	const renderFormSection = (section, fields, index) => {
		return (
			<Section
				key={index}
			>
				{section.heading && (
					<Heading>{section.heading}</Heading>
				)}

				{section.description && (
					<Description>{section.description}</Description>
				)}

				{fields}
			</Section>
		)
	}

	const handleAddressSubmit = (fields, resetForm) => {
		const { user } = props

		const data = transformTradeData(fields, user)
		
		updateForm({submitting: true})

        postData('/pda/update-user-addresses', data)
            .then(res => {
                if (res.success) {
                    updateForm({
                        error: null,
                        submitting: false
					})      
					
					getUserData({customer_id: data.customer_id})
						.then(res => {
							console.log(res)
							if (res.success !== false) {									
								props.updateUser(res.customer)
							} 
						})
     
                } else {
                    updateForm({
                        error: res.errors[0],
                        submitting: false
                    })
                }
                
                resetForm()
            })
            .catch(error =>  {
                updateForm({
                    error: 'Server Error',
                    submitting: false
                })
                resetForm()
            })  
	}

	const handleDetailsSubmit = (fields, resetForm) => {	
		const { user } = props

		const data = {
			customer_id: user.customer_id,
			account_first_name: fields['firstName'].value,
			account_last_name: fields['lastName'].value,
			account_email: fields['email'].value,
			password_current: fields['currentPassword'].value,
			password_new: fields['newPassword'].value,
		}
			

		updateForm({submitting: true})

        postData('/pda/update-user-details', data)
            .then(res => {
                if (res.success) {
                    updateForm({
                        error: null,
                        submitting: false
                    })

					getUserData({customer_id: data.customer_id})
						.then(res => {
							if (res.success !== false) {									
								props.updateUser(res.customer)
							} 
						})
     
                } else {
                    updateForm({
                        error: res.errors[0],
                        submitting: false
                    })
                }
                
                resetForm()
            })
            .catch(error =>  {
                updateForm({
                    error: 'Server Error',
                    submitting: false
                })
				resetForm()
			})  
		
	}
	

	const renderAddressSidebar = () => {
		const { cart } = props;

		return (
			<Sidebar>
				<Heading>Update Address</Heading>
				<Description
					dangerouslySetInnerHTML={{__html: `
						Update your saved billing and shipping details here. These details will be used to prefill the checkout for any future purchases.
					`}}
				/>
			</Sidebar>
		)
	}
	
	const renderAddressForm = () => {
        const { error, submitting, formComplete } = formState;
		
		return (
			<FormWrapper>
				<Form
					ref={editAddressForm}
					data={editAddress}
					useSections={true}
					renderSection={renderFormSection}
					renderInput={<Input/>}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleAddressSubmit}
					populatedFields={getPopulatedFields()}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					theme={'outline-black'}
					onClick={() => {
						editAddressForm.current && editAddressForm.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Submit'}
				</Button>
			</FormWrapper>
		)
	}

	const renderDetailsSidebar = () => {
		const { cart } = props;

		return (
			<Sidebar>
				<Heading>Update Details</Heading>
				<Description
					dangerouslySetInnerHTML={{__html: `
						Update your user details and password.
					`}}
				/>
			</Sidebar>
		)
	}
	
	const renderDetailsForm = () => {
        const { error, submitting, formComplete } = formState;
		
		return (
			<FormWrapper>
				<Form
					ref={editDetailsForm}
					data={editDetails}
					useSections={true}
					renderSection={renderFormSection}
					renderInput={<Input/>}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleDetailsSubmit}
					populatedFields={getPopulatedFields()}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					theme={'outline-black'}
					onClick={() => {
						editDetailsForm.current && editDetailsForm.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Submit'}
				</Button>
			</FormWrapper>
		)
	}

	return (
        <Layout
            footer={true}
        >
			<Container>
				<Heading>Account Settings</Heading>
				<Content>
					{renderDetailsSidebar()}
					{renderDetailsForm()}
				</Content>
				<Content>
					{renderAddressSidebar()}
					{renderAddressForm()}
				</Content>
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	bgImage;
	transition: opacity 0.45s ease;
`

const Button = styled.div`
    ${button}
`


// Layout

const Container = styled.div`
	${container}
	${padding}
	${maxWidth}
	flex-direction: column;

	> ${Heading} {
		${formHeading}
	}
`

const Content = styled.div`
	${formContent}
`

// Sidebar

const Sidebar = styled.div`
	${formSidebar}

	${Heading} {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 5px;
	}	

	> ${Description} {
		margin-top: 12px;
		font-size: 16px;
		line-height: 19px;
	}
`

// Form

const FormWrapper = styled.div`
	flex: 1 0 calc(50% - 20px);
	display: flex;	
	flex-direction: column;	

	${Button} {
		margin-top: 29px;
	}

	${media.tablet`
		max-width: calc(100vw - 350px);
	`}

	${media.phone`
		max-width: none;
	`}
`

const Section = styled.div`
	width: 100%;
	margin-bottom: 42px;	

	${Heading} {
		font-size: 20px;
		font-weight: 500;
	}	
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
        width: 100%;
        
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 17px;

		&.terms {
			.field-wrapper {
				border: none !important;
			}
		}

		&.creditCard {
			margin-bottom: 42px;

			.field > * {
				width: 100%;

				#cardNumber {
					flex: 1 0 60%;
				}

				#expiryDate {
					flex: 1 0 20%;
				}

				#CVC {
					flex: 1 0 20%;
				}
			}
		}
	}
`

const ResetPassword = styled(Link)`
    margin-top: 30px;
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
`

const ThankYou = styled.div`
	margin-top: 32px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
  background-image: url(${require('../../assets/icons/loading.svg').default});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`

const mapDispatchToProps = dispatch => ({
    fetchUser() {
        dispatch(fetchUser())
    },
    updateUser(data) {
        dispatch(updateUser(data))
	},
})

const mapStateToProps = (state, props) => ({
	...getUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);
