import { uniq, find, filter, forEach } from 'lodash'

export const toTitleCase = s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

const createOption = (taxonomy, attribute) => {
    return {
        name: taxonomy.name,
        slug: taxonomy.slug,
        attributes: [
            ...attribute
        ]
    }
}

export const allOptionsSelected = (options) => {
    let selected = true;

    forEach(options, (option) => {
        if (!option) {
            selected = false
        }
    })

    return selected
}

export const generateInitialSelectState = (options, defaults) => {
    let initialState = []

    options.forEach((option, i) => {
        //const prefill = defaults ? find(defaults, {key : option.slug}) : false;

        initialState[i] = {
            name: option.name,
            value: false,
        }
    })   
    
    return initialState
}   

export const getOptions = (variants) => {
    let options = []

    variants?.forEach((variant) => {
        if (!variant?.attributes?.length) return

        variant.attributes.forEach((attribute) => {
            let option = find(options, {slug: attribute.tax});

            const tax = {
                name: attribute.tax_name,
                slug: attribute.tax,
            }

            const attr = {
                name: attribute.name,
                slug: attribute.slug,
            }

            // Add attribute to existing option or create new

            if (option) {   
                if (!find(option.attributes, {slug : attribute.slug})) { // check dupes
                    option.attributes.push(attr)
                }
            } else {
                option = createOption(tax, [attr])
            }

            options.push(option)
        })
    })
    
    return uniq(options)
}

// Filter variants by attribute

export const filterByAttribute = (variants, slug) => {
    return filter(variants, (variant) => {
        let match;

        forEach(variant.selectedOptions, (attr) => {
            if (attr.value === slug) {
                match = true
            }
        })

        return match
    })
}

// Match selected attributes to variants

export const matchSelectedAttributes = (variant, selectedOptions) => {
    return filter(variant.attributes, (attr) => {
        let match;

        forEach(selectedOptions, (selectedAttr) => {
            if (selectedAttr.value == attr.slug) {
                match = true
            }
        })

        return match
    })
}