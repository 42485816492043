const modalInitialState = {
    searchActive: false,
    navActive: false,
}

const modal = (state = modalInitialState, action) => {
    switch (action.type) {
        case 'TOGGLE_SEARCH':
            return {
                navActive: false,
                searchActive: !state.searchActive,
            }
        case 'TOGGLE_NAV':
            return {
                searchActive: false,
                navActive: !state.navActive,
            }
        case 'CLOSE':
            return {
                searchActive: false,
                navActive: false,
            }
        default:
            return state
    }
}

export default modal
