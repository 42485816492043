import React, { Component } from 'react'
import styled from 'styled-components';

import { updateQuantity } from '../../store/cart';
import { hoverState } from '../../styles/global';
import { Link } from 'gatsby';
import { media } from '../../styles/utils';

class AccountNav extends Component {
    
    render() {
        return ( 
            <Wrapper>
                <NavLink to={'/account/orders'}>
                    Orders
                </NavLink>

                <NavLink to={'/account/edit-details'}>
                    Edit Details / Password
                </NavLink>

                <NavLink to={'/account/edit-address'}>
                    Edit Addresses
                </NavLink>
            </Wrapper>
        )
    }
    
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const NavLink = styled(Link)`
    margin-bottom: 8px;
    font-size: 16px;

    ${media.tablet`
        font-size: 14px;
        margin-bottom: 4px;
    `}
`


export default AccountNav
