import React, { useRef, useState, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'

import { Layout, Block, BackgroundImage } from '../../../components'
import { withHeader } from '../../../components/Header/HeaderContext';

import { media, width, isClient, breakpoints } from '../../../styles/utils'
import { bgImage, container, padding, hoverState } from '../../../styles/global';
import { border, black, grey } from '../../../styles/colors';

const BlockHeroImage = ({ title, image, tagline, color = 'white' }) => {
  return (
    <Hero
      theme={color}
    >
      <StickyText>
        <Heading>{title}</Heading>

        {tagline && (
          <Subheading>{tagline}</Subheading>
        )}
      </StickyText>

      {image?.mediaItemUrl && (
        <BackgroundImage
          image={image.mediaItemUrl}
          width={'3000px'}
          disableSrcSet
          disableQualityByDPR
        />
      )}
    </Hero>
  )
}

const LEFT_SPACING = '43vw';

const Heading = styled.div``
const Subheading = styled.div``


const StickyText = styled.div`
    height: 100vh;
    overflow: hidden;
    padding: 30px 0 40px;
    padding-left: ${LEFT_SPACING};
    box-sizing: border-box;

    @media (max-width: 768px) {
        height: 40vh;
    }

    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.tablet`
        ${padding}
        padding-bottom: 16px;
    `}  

    ${media.phone`
        padding-bottom: 0;
        padding-top: 15px;
    `}
`

const Hero = styled.div`
    height: 120vh;
    width: 100vw;
    position: relative;
    background: rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        height: 50vh;
    }

    .bg-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    ${Heading} {
        font-size: 80px;
        color: white;
        font-weight: 500;
        word-spacing: 100vw;

        ${media.phone`
            font-size: 31px;
            line-height: 35px;
        `}
    }

    ${Subheading} {
        font-weight: 500;
        font-size: 38px;
        line-height: 44px;
        color: white;

        ${media.phone`
            font-size: 20px;
            line-height: 37px;
        `}
    }

    ${props => {
        if (props.theme) return css`
            ${Heading},
            ${Subheading} {
                color: ${props.theme}
            }
        `
    }}
`


export default BlockHeroImage