import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, graphql, navigate } from 'gatsby'
import { useSetState, useMount } from 'react-use';
import styled, { css, keyframes } from 'styled-components'
import { filter, find, forEach, camelCase } from 'lodash'
import Form from 'react-dynamic-form'
import ProgressiveImage from "react-progressive-image"
import NumberFormat from 'react-number-format';
import geodist from 'geodist';

import { Layout, MetaTags, Input } from '../components'
import { fetchUser, updateUser, getUser } from '../store/user'
import { getCart, fetchCart, emptyCart } from '../store/cart';

import { container, padding, bgIcon, button } from '../styles/global';
import { media, isClient } from '../styles/utils';
import { black } from '../styles/colors';
import { formData } from '../data/checkout';
import { inputStyle, red, error } from '../components/Input/Input';

import { postData } from '../services/api';
import { transformOrderData } from '../utils/checkout';
import { renderCartItems, calculateTotal } from '../components/Cart/Cart';
import { getNumberScaleProps } from '../utils/product';
import { maxWidth } from './studio';
import { isAuthenticated } from '../services/auth';

const API_KEY = 'AIzaSyCXLBVEXJyiLwh3cL5KSCsbztNL-7OZF6c';
const MAPS_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
const NEAR_RADIUS = 50; // KMs
const SHIPPING_ZONE_AUS = ['australiaMelbourneMetro', 'australiaOther']; // Metro 150, Regional 200

const Checkout = (props) => {
	const checkoutForm = useRef(null);
	const [shippingPrice, updateShippingPrice] = useState(null)
	const [populatedFields, setPopulatedFields] = useState(false)

	const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
		error: false,
	})

	useMount(() => {
		props.fetchCart()
		props.fetchUser()
	})
	
	useEffect(() => {
		if (props.user && !populatedFields) {
			getPopulatedFields()
		}
	}, [props.user])

	// useEffect(() => {
	// 	if (props.cart && !props.cart.length) navigate('/products')
	// }, [props.cart])

	const calculateAUShipping = (postcode, calculateShipping, state) => {
		const manualStates = ['WA', 'NT']
		const origin = {lat: -37.8152065, lng: 144.963937}; // Melbourne, AU
		updateShippingPrice(false)

		// Geocode postcode to retrieve coordinates

		if (manualStates.includes(state)) {
			calculateShipping(state)	
		} else {
			postData('/pda/postcode-check', {postcode: postcode})
				.then(res => {
					if (res.success == true) {	
						const distance = geodist([res.lat, res.lng], [origin.lat, origin.lng], { unit: 'km' })	
						calculateShipping(SHIPPING_ZONE_AUS[distance < NEAR_RADIUS ? 0 : 1])	
					}
				})
		}

	}

	const handleFieldChange = (field, fieldState) => {
		const type = fieldState.useBillingForShipping.value ? 'billing' : 'shipping';

		if (field.valid && field.name == `${type}Postcode`) {
			const country = fieldState[`${type}Country`]
			const state = fieldState[`${type}State`]

			if (country.value == 'AU') {
				calculateAUShipping(field.value, calculateShipping, state)
			} else {
				calculateShipping(country?.value)
			}	
		}

		if (field.valid && field.name == `${type}Country`) {
			const postcode = fieldState[`${type}Postcode`]
			const state = fieldState[`${type}State`]

			if (field.value == 'AU') {
				calculateAUShipping(postcode.value, calculateShipping, state?.value)
			} else {
				calculateShipping(field.value)
			}
		}


		if (field.valid && field.name == `${type}State`) {
			const country = fieldState[`${type}Country`]
			const postcode = fieldState[`${type}Postcode`]

			if (country.value == 'AU') {
				calculateAUShipping(postcode?.value, calculateShipping, field.value)
			} else {
				calculateShipping(country?.value)
			}
		}	
	}

	const calculateShipping = (country) => {
		const { cart } = props;

		let total = 0;

		if (cart && cart.length > 0) {
			cart.forEach((elm, i) => {
				const cartItem = cart[i];
				const shipping = cartItem.shipping;
				total = total + cartItem.quantity * shipping[country]
			});
		}
		
		updateShippingPrice(total)
	}
	
	const renderSubtotals = () => {
		const { cart } = props;

		return (
			<Subtotals>
				<Total>
					Sub Total Amount (ex GST) 
					<NumberFormat 
						value={calculateTotal(cart, true)} 
						prefix={'$'}
						displayType={'text'} 
						{...getNumberScaleProps()}     
					/>
				</Total>

				<Total>
					Shipping Amount 
					<NumberFormat 
						value={shippingPrice} 
						prefix={'$'}
						displayType={'text'} 
						{...getNumberScaleProps()}     
					/>
				</Total>

				<Total>
					GST    
					<NumberFormat 
						value={(calculateTotal(cart, true)) * 0.1} 
						prefix={'$'}
						displayType={'text'} 
						{...getNumberScaleProps()}     
					/>
				</Total>

				<Total>
					Total   
					<NumberFormat 
						value={(calculateTotal(cart, true) ) * 1.1 + shippingPrice} 
						prefix={'$'}
						displayType={'text'} 
						{...getNumberScaleProps()}     
					/>
				</Total>
			</Subtotals>
		)
	}

	const renderSidebar = () => {
		const { cart } = props;

		return (
			<Sidebar>
				<Heading>Your Items</Heading>
				{renderCartItems(cart, null, null, true)}
				{renderSubtotals()}
			</Sidebar>
		)
	}

	const handleSubmit = (fields, resetForm) => {
		const { cart, emptyCart, user } = props;
		const data = transformOrderData(fields, cart, shippingPrice, user)

        updateForm({submitting: true})
        
		postData('/pda/order', data)
			.then(res => {
				console.log(res)
				if (res.success == true) {
					updateForm({
						error: null,
						submitting: false
					})
                    props.emptyCart()
					navigate('/thank-you')
					emptyCart()
				} else {
					
					updateForm({
						error: res.message,
						submitting: false
					})
				}
				
				resetForm()
			})
			.catch(error =>  {
				updateForm({
					error: 'Server Error',
					submitting: false
				})
				resetForm()
			})  
	}
	
	const renderFormSection = (section, fields, index) => {
		return (
			<Section
				key={index}
			>
				{section.heading && (
					<Heading>{section.heading}</Heading>
				)}

				{section.description && (
					<Description>{section.description}</Description>
				)}

				{fields}
			</Section>
		)
	}

	const getPopulatedFields = () => {
		if (!isAuthenticated()) return;
		const user = props.user
		
        if (user) {
			const addressData = {};
			
            forEach(user, (value, key) => {	
                addressData[`${camelCase(key)}`] = value;		
			})
			
			if (addressData.billingCountry && addressData.billingPostcode) {
				const country = addressData.billingCountry
				const postcode = addressData.billingPostcode

				if (country == 'AU') {
					calculateAUShipping(postcode, calculateShipping)
				} else {
					calculateShipping(country)
				}	
			}

            setPopulatedFields(addressData)
        }
	}

	const renderForm = () => {
		const { error, submitting, formComplete } = formState;
		
		return (
			<FormWrapper>
				<Form
					ref={checkoutForm}
					data={formData}
					useSections={true}
					renderSection={renderFormSection}
					renderInput={<Input/>}
					moveToInvalidField={true}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleSubmit}
					onFieldChange={handleFieldChange}
					populatedFields={populatedFields}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					theme={'outline-black'}
					onClick={() => {
						checkoutForm.current && checkoutForm.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Complete Purchase'}
				</Button>
			</FormWrapper>
		)
	}

	return (
		<Layout
			footer={true}
		>
			<Container>
				<Heading>Checkout Purchase</Heading>
				<Content>
					{renderSidebar()}
					{renderForm()}
				</Content>
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	bgImage;
	transition: opacity 0.45s ease;
`

const Button = styled.div`
	${button}
`


// Layout

export const formHeading = css`
	word-spacing: 100vw;
	font-size: 70px;
	font-weight: 500;
	line-height: 71px;
	margin-top: 109px;
	padding-left: 40%;

	${media.tablet`
		padding-left: 15%;
	`}

	${media.phone`
		font-size: 28px;
		word-spacing: 0;
		padding-left: 0px;
		margin-top: 40px;
	`}
`

const Container = styled.div`
	${container}
	${padding}
	${maxWidth}
	flex-direction: column;

	> ${Heading} {
		${formHeading}
	}
`

export const formContent = css`
	display: flex;
	margin-top: 98px;
	padding-bottom: 155px;

	${media.phone`
		margin-top: 24px;
		flex-direction: column;
		padding-bottom: 96px;
	`}
`

const Content = styled.div`
	${formContent}
`

// Sidebar

export const formSidebar = css`
	flex: 1 0 calc(50% - 20px);
	margin-right: 40px;
	margin-bottom: 78px;

	${Heading} {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 5px;
	}	

	${media.tablet`
		margin-right: 24px;
	`}
	
`

const Sidebar = styled.div`
	${formSidebar}
`

// Subtotals

const Total = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    &:not(:last-child) {
       margin-bottom: 12px;
    } 
`

const Subtotals = styled.div`
	margin-top: 19px;
`

// Form

const FormWrapper = styled.div`
	flex: 1 0 calc(50% - 20px);
	display: flex;	
	flex-direction: column;	

	${Button} {
		margin-top: 29px;
	}

	${media.tablet`
		max-width: calc(100vw - 350px);
	`}

	${media.phone`
		max-width: none;
	`}
`

const Section = styled.div`
	width: 100%;
	margin-bottom: 42px;	

	${Heading} {
		font-size: 20px;
		font-weight: 500;
	}	
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
		width: 100%;
		
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 17px;

		&.terms {
			.field-wrapper {
				border: none !important;
			}
		}

		&.creditCard {
			margin-bottom: 42px;

			.field > * {
				width: 100%;

				#cardNumber {
					flex: 1 0 60%;
				}

				#expiryDate {
					flex: 1 0 20%;
				}

				#CVC {
					flex: 1 0 20%;
				}
			}
		}
	}
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
`

const ThankYou = styled.div`
	margin-top: 32px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
    background-image: url(${require('../assets/icons/loading.svg').default});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`


export const query = graphql`
	query CheckoutQuery {
		wp {
			options {
				pageSlug
				pageTitle
				footerMenu {
					footerMenu {
						title
						link {
							title
							url
						}
					}
				}
				tradeApplicationOptions {
					tradeApplicationNotificationEmail
					tradeApplicationUseAdminNotificationEmail
				}
				tradeLogin {
					loginText
				}
			}
		}
	}
`

const mapDispatchToProps = dispatch => ({
	fetchCart() {
        dispatch(fetchCart())
    },
    emptyCart() {
        dispatch(emptyCart())
    },
	fetchSettings() {
        dispatch(fetchSettings())
    },
    fetchUser() {
        dispatch(fetchUser())
    },
    updateUser(data) {
        dispatch(updateUser(data))
	},
	emptyCart() {
        dispatch(emptyCart())
    },
})

const mapStateToProps = (state, props) => ({
	...getCart(state),
	...getUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
