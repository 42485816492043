import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';

import { updateQuantity } from '../../store/cart';
import { hoverState } from '../../styles/global';

class Spinner extends Component {

    componentDidMount = () => {
      
    }

    updateQuantity = (modifier) => {
        const { updateQuantity, cartItem } = this.props;
        // console.log(modifier, cartItem)

        updateQuantity({
            ...cartItem,
            quantity: modifier == '+' ? cartItem.quantity + 1 : cartItem.quantity - 1
        })
    }
    
    render() {
        const { cartItem } = this.props;
        return ( 
            <Wrapper>
                <Control
                    onClick={() => this.updateQuantity('-')}
                >
                    {'<'}
                </Control>

                <Heading>{cartItem.quantity}</Heading>

                <Control
                    onClick={() => this.updateQuantity('+')}
                >
                    {'>'}
                </Control>
            </Wrapper>
        )
    }
    
}

const Wrapper = styled.div`
    display: flex;
`

const Heading = styled.div`
    margin: 0 6px 0 5px;
`

const Control = styled.div`
    ${hoverState};
    user-select: none; 
    opacity: 0.3;  
    font-weight: 500; 
    cursor: pointer;
`

const mapDispatchToProps = dispatch => ({
    updateQuantity(cartItem) {
        dispatch(updateQuantity(cartItem))
    }
})

export default connect(null, mapDispatchToProps)(Spinner);
