import React from 'react'
import fonts from './src/styles/fonts.css'
import wrapWithProvider from "./wrap-with-provider"
import Transition from "./src/transition"
require("smooth-scroll")('a[href*="#"]')

// Wrap Redux provider

export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = function ({ location }) {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function` && location.pathname !== '/prefab/') {
    fbq("track", "ViewContent");
  }
};