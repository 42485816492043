import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"

import { BackgroundImage } from '../../../components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { getImageCrop } from '../../../utils';

class BlockImageText extends React.Component {

	state = {
	
    }
    
    resolveImageSize = () => {
        const { image, layout } = this.props;
        if (!image) return;

        const imageSizes = {
            'imagetext': image.mediaItemUrl,
            'fullwidthimage': image.mediaItemUrl
        }

        return imageSizes[layout]
    }

    renderText = () => {

        const useText = this.props.useText || this.props.use_text
        const textType = this.props.textType || this.props.text_type
        const shortText = this.props.shortText || this.props.short_text
        const quoteCredit = this.props.quoteCredit || this.props.quote_credit
        const imagePosition = this.props.imagePosition || this.props.image_position

        if (!useText) return;

        return (
            <TextWrapper
                position={imagePosition}
            >
                {useText && textType =='paragraph' && (
                    <Paragraph 
                        dangerouslySetInnerHTML={{__html: shortText}}  
                    />
                )}

                {useText && textType =='quote' && (
                    <Quote>
                        <QuoteText
                            dangerouslySetInnerHTML={{__html: shortText}}  
                        />
                        <QuoteCredit>{quoteCredit}</QuoteCredit>
                    </Quote>
                )}
            </TextWrapper>
        )        
    }

    renderImage = () => {
        const { image, imagePosition, layout, wrapVisibility, scalingContained } = this.props;

        //console.log(this.props)

        const imageUrl = image?.mediaItemUrl || image?.url

        if(!imageUrl) return

        const component = (
            <BGImage  
                as={BackgroundImage}
                image={imageUrl} 
                position={imagePosition}
                width={layout == 'fullwidthimage' || 'full_width_image' ? '1500px' : '500px'}
                landscape={image.height < image.width}
                layout={layout}
                contained={scalingContained || this.props.scaling_contained}
                disableSrcSet
                disableQualityByDPR
                // preload
            />
        )
        

        if (layout == 'fullwidthimage') {
            return wrapVisibility(component)
        } else {
            return component
        }
    }
    
	
	render() {
        const { layout } = this.props;

        return (
            <Container
                layout={layout}
            >
                {this.renderImage()}
                {this.renderText()}
            </Container>
		)
	}
}


// Image

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    width: 100%;
	height: 100%;
    padding-bottom: 57.14%;
    transition: opacity 0.45s ease;

    /* Position */

    > img {
        display: none;
    }

    ${props => {
        if (props.position == 'right') return css`
            order: 2;
            margin-left: auto;
        `
    }}

    /* Sizing */

    ${props => {
        if (props.layout == 'WpProject_Contentblocks_Blocks_ImageText') return css`
            max-width: 680px;
            padding-bottom: 78.83%;

            @media (max-width: 1280px) {
                max-width: 50vw;
            }

            ${media.tablet`
                padding-bottom: 140% !important;
            `}
        `
    }}

    ${props => {
        if (props.layout == 'imagetext' && props.landscape) return css`
            height: 420px !important;
            padding-bottom: 0;
        `
    }}

    ${props => {
        if (props.contained) return css`
            > * {
                object-fit: contain !important;
            }
        `
    }}

    ${media.tablet`
        height: auto;
        padding-bottom: 66%;
        max-width: none;
        order: 0;
    `}
`

// Text

const TextWrapper = styled.div`
    margin: 0 54px;
    flex: 1 0 auto;

    ${media.tablet`
        margin: 0;  
        margin-top: 20px;  
        width: 100%;
    `}

    /* Position */

    ${props => {
        if (props.position == 'right' || typeof props.position == 'undefined') return css`
            margin: 0 54px;

            ${media.tablet`
            margin: 0;  
            margin-top: 20px;  
            width: 100%;
        `}
        `
    }}
`

const Paragraph = styled.div`
    margin-top: 54px;
    font-size: 20px; 
    line-height: 26px;
    max-width: 430px;

    ${media.tablet`
        max-width: none;
        margin-top: 16px;
    `}
`

// Quote

const Quote = styled.div`
    display: flex;
    flex-direction: column;
`

const QuoteText = styled.div`
    font-weight: 500;
    font-size: 32px;
    line-height: 1.19em;
    max-width: 430px;

    ${media.tablet`
        font-size: 24px;
    `}
`

const QuoteCredit = styled.div`
    margin-top: 20px;
    font-size: 20px;

    ${media.tablet`
        margin-bottom: 12px;
    `}
`

// Container

const Container = styled.div`
    display: flex;
    align-items: center;

    ${props => {
        if (props.layout == 'imagetext') return css`
            ${container};
            max-width: 1220px;
            align-self: center;
        `
    }}

    ${props => {
        if (props.layout == 'fullwidthimage') return css`
            flex-direction: column;

            /* Contain text */

            ${TextWrapper} {
                ${container}
                max-width: 1220px;
                align-self: center;
                justify-content: flex-end;
                margin-top: 54px;
            }
        `
    }}

    ${media.tablet`
        flex-direction: column;
    `}
`

BlockImageText.wrapper = css`
    display: flex;
    flex-direction: column;
`

export default BlockImageText
