import { isAuthenticated } from "../services/auth";

export const getPricingData = (variant, product) => {
    if (!product) return;
    
    const simpleProduct = product.product_type == 'simple';

    // Determine standard & wholesale prices

    const wholesalePrice = variant && variant.price_wholesale !== "" && variant.price_wholesale;
    const standardPrice = variant && variant.price;

    // Create dynamic pricing data 
    
    return {
        variant,
        wholesale: {
            value: !variant || simpleProduct ? product.min_price_wholesale : wholesalePrice || standardPrice,
            minPrice: product.min_price_wholesale,
            maxPrice: product.max_price_wholesale
        },
        standard: {
            value: !variant || simpleProduct ? product.min_price : standardPrice,
            minPrice: product.min_price,
            maxPrice: product.max_price,
        },
    }
}

export const getPriceProps = (pricing, exGST) => {
    //const price = isAuthenticated() ? pricing.wholesale : pricing.standard;
    
    return {
        value: pricing?.amount,
        prefix: pricing?.minVariantPrice !== pricing?.maxVariantPrice ? 'From $' :'$',
    } 
}


export const getNumberScaleProps = () => {
    return {
        displayType: 'text',
        thousandSeparator: true, 
        fixedDecimalScale: true,  
        decimalScale: 2
    }
}