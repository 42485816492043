import React, { useState, useRef } from 'react';
import { useSetState, useMount } from 'react-use';
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import { filter, find, forEach } from 'lodash'
import Form from 'react-dynamic-form'
import ProgressiveImage from "react-progressive-image"

import { Layout, MetaTags, Input, Block } from '../components'

import { container, padding, bgIcon, button } from '../styles/global';
import { media, isClient } from '../styles/utils';
import { black } from '../styles/colors';
import { formData } from '../data/trade';
import { inputStyle, red, error } from '../components/Input/Input';

import { postData } from '../services/api';
import { transformTradeData } from '../utils/trade';
import { formHeading, formContent, formSidebar } from './checkout';
import { maxWidth } from './studio';

const TradeApplication = (props) => {

    const graphData = useStaticQuery(query)
    //const data = JSON.parse(graphData.allWordpressInfopages.nodes[0].acf_json)
		const data = graphData.wpPage.template

	const tradeForm = useRef(null);
	const [shippingPrice, updateShippingPrice] = useState(null)

	const [formState, updateForm] = useSetState({
		submitting: false, 
		formComplete: false,
		error: false,
	})

	const renderFAQs = () => {

		return (
			<FAQs>
				<Heading>FAQs</Heading>
				<Block
					layout={'accordion'}
					content={data.faq}
				/>
			</FAQs>
		)
	}
	
	const renderSidebar = () => {
		return (
			<Sidebar>
				<Heading>{data.subheading}</Heading>
				<Description
					dangerouslySetInnerHTML={{__html: data.description}}
				/>
				{renderFAQs()}
			</Sidebar>
		)
    }


	const handleSubmit = (fields, resetForm) => {
		const data = transformTradeData(fields)

		updateForm({submitting: true})

		postData('/pda/trade-apply', data)
			.then(res => {
				if (res.success) {
					updateForm({
						error: null,
                        submitting: false,
                        formComplete: true
					})
				} else {
					updateForm({
						error: res.message,
						submitting: false
					})
				}
				
				resetForm()
			})
			.catch(error =>  {
				updateForm({
					error: `Server Error`,
					submitting: false
				})
				resetForm()
			})  
    }
	
	const renderFormSection = (section, fields, index) => {
		return (
			<Section
				key={index}
			>
				{section.heading && (
					<Heading>{section.heading}</Heading>
				)}

				{section.description && (
					<Description>{section.description}</Description>
				)}

				{fields}
			</Section>
		)
	}

	const renderForm = () => {
		const { error, submitting, formComplete } = formState;
		
		return (
			<FormWrapper>
				<Form
					ref={tradeForm}
					data={formData}
					useSections={true}
					renderSection={renderFormSection}
					renderInput={<Input/>}
					moveToInvalidField={true}
					styles={formStyles}
					renderSubmit={false}
					onSubmit={handleSubmit}
				/>

				{error && (
					<Error>{error}</Error>
				)}

				<Button
					theme={'outline-black'}
					onClick={() => {
						tradeForm.current && tradeForm.current.handleSubmit()
					}}
				>
					{submitting ? <Loading/> : 'Submit'}
				</Button>
			</FormWrapper>
		)
    }
    
    const renderThankYou = () => {
		const { error, submitting, formComplete } = formState;
		
		return (
			<ThankYou>
				Thanks for your application! We will be in touch
			</ThankYou>
		)
	}

	return (
        <Layout
            footer={true}
            meta={data && data.seo}
        >
			<Container>
				<Heading>Trade Application</Heading>
				<Content>
                    {renderSidebar()}			
					{formState.formComplete ? renderThankYou() : renderForm()}
				</Content>
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	bgImage;
	transition: opacity 0.45s ease;
`

const Button = styled.div`
	${button}
`


// Layout

const Container = styled.div`
	${container}
	${padding}
	${maxWidth}
	flex-direction: column;


	> ${Heading} {
		${formHeading}
	}
`

const Content = styled.div`
	${formContent}
`

// Sidebar

const Sidebar = styled.div`
	${formSidebar}

	${Heading} {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 5px;
	}	
	
	> ${Description} {
		margin-top: 12px;
		font-size: 16px;
		line-height: 19px;
	}
`

// FAQs

const FAQs = styled.div`
	margin-top: 54px;

	${Heading} {
		margin-bottom: 12px;
	}
`

// Form

const FormWrapper = styled.div`
	flex: 1 0 calc(50% - 20px);
	display: flex;	
	flex-direction: column;	

	${Button} {
		margin-top: 29px;
	}

	${media.tablet`
		max-width: calc(100vw - 350px);
	`}

	${media.phone`
		max-width: none;
	`}
`

const Section = styled.div`
	width: 100%;
	margin-bottom: 42px;	

	${Heading} {
		font-size: 20px;
		font-weight: 500;
	}	
`

const formStyles = css`
	flex-direction: column;
	align-items: flex-start;
    display: flex;	
	position: relative;
	
	/* Field overrides */

	.dynamic-fields {
		width: 100%;
		
		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	.dynamic-field {
		margin-bottom: 17px;

		&.terms {
			.field-wrapper {
				border: none !important;
			}
		}

		&.creditCard {
			margin-bottom: 42px;

			.field > * {
				width: 100%;

				#cardNumber {
					flex: 1 0 60%;
				}

				#expiryDate {
					flex: 1 0 20%;
				}

				#CVC {
					flex: 1 0 20%;
				}
			}
		}
	}
`

const Error = styled.div`
	font-size: 16px;
	margin-top: 12px;
	color: red;
`

const ThankYou = styled(Error)`
	color: black;
	font-size: 19px;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Loading = styled.div`
	width: 20px;
	height: 20px;
    background-image: url(${require('../assets/icons/loading.svg').default});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`


export const query = graphql`
	query {
		wpPage(slug: { eq: "trade-application" }) {
			template {
				... on WpTemplate_TradeApplication {
					templateName
					description {
						description
					}
					faq {
						faq {
							content
							heading
						}
					}
					subHeading {
						subheading
					}
				}
			}
		}
	}
`


export default TradeApplication


