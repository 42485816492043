import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { forEach, reject, upperFirst } from 'lodash'
import { required, email, phone, number, password, excludeShipping, postcode, ABN } from '../utils/validators'
import { RegionDropdown, CountryDropdown } from 'react-country-region-selector'
import { width } from '../styles/utils';
import { Checkbox, Input, Select } from '../components';
import { inputStyle, red, error } from '../components/Input/Input';
import { getBillingShippingFields } from './checkout';

export const formData = {
    sections: [
        {
            heading: 'Your Application',
            fields: [
                {
                    type: 'text',
                    name: 'billingFirstName',
                    label: 'First Name *',
                    validator: required
                },
                {
                    type: 'text',
                    name: 'billingLastName',
                    label: 'Last Name *',
                    validator: required,
                },
                {
                    type: 'email',
                    name: 'billingEmail',
                    label: 'Email Address *',
                    validator: email,
                    errorMessage: 'Please enter a valid email'
                },
                {
                    type: 'tel',
                    name: 'billingPhone',
                    label: 'Phone *',
                    validator: required, // phone check?
                    errorMessage: 'Please enter a valid phone number'
                },
            ]
        },
        {
            heading: 'Account Information',
            fields: [
                {
					type: 'password',
					name: 'password',
					label: 'Password',
					validator: password, 
                    errorMessage: 'Min 8 characters with one capital letter and one symbol (#?!@$%^&*-)',
				},
				{
					type: 'password',
					name: 'passwordConfirm',
					label: 'Confirm Password',
					validator: (value, fields) => {
                        if (fields && value == fields['password'].value) return true
                        return false
                    }, 
					errorMessage: 'Passwords do not match',
				},
            ]
        },
        {
            heading: 'Business Details',
            fields: [
                {
                    type: 'text',
                    name: 'businessName',
                    label: 'Business Name *',
                    validator: required, 
                },
                {
                    name: 'businessType',
                    validator: required,
                    component: ({value, onChangeValue, validator}) => {
                        return (
                            <Select
                                value={value || 'Select Business Type *'}
                                onChange={(val) => onChangeValue(val, validator)}
                                validator={validator}
                                options={[
                                    {
                                        label: 'Select Business Type *',
                                        value: 'none',
                                        disabled: false
                                    },
                                    {
                                        label: 'Architects',
                                        value: 'architects'
                                    },
                                    {
                                        label: 'Builders',
                                        value: 'builders'
                                    },
                                    {
                                        label: 'Construction',
                                        value: 'construction'
                                    },
                                    {
                                        label: 'Electricians',
                                        value: 'electricians'
                                    },
                                    {
                                        label: 'Hotels',
                                        value: 'hotels'
                                    },
                                    {
                                        label: 'Interior Designers',
                                        value: 'interior-designers'
                                    },
                                    {
                                        label: 'Project Managers',
                                        value: 'project-managers'
                                    },
                                    {
                                        label: 'Pubs & Clubs',
                                        value: 'pubs-clubs'
                                    },
                                    {
                                        label: 'Resorts',
                                        value: 'resorts'
                                    },
                                    {
                                        label: 'Stylists',
                                        value: 'stylists'
                                    }
                                    
                                ]}
                            />
                        )
                    }
                },
                {
                    type: 'text',
                    name: 'abn',
                    label: 'ABN (Australian Business Number) *',
                    validator: ABN,
                    errorMessage: 'Please enter a valid ABN (11 digits)',
                },
            ],
        },
        {
            heading: 'Billing Details',
            fields: [
                ...getBillingShippingFields('billing', ['firstName', 'lastName', 'email', 'phone']),
                {
                    name: 'useBillingForShipping',
                    defaultValue: true,
                    component: ({value, onChangeValue, validator}) => {
                        return (
                            <Checkbox
                                value={value}
                                label={'Use same address for shipping'}
                                onChange={(val) => onChangeValue(val, validator)}
                            />
                        )
                    }
                },
            ],
        },
        {
            heading: 'Shipping Fields',
            hide: (fieldState) => {
                const useBilling = fieldState['useBillingForShipping'];
                return useBilling && useBilling.value == true ? true : false;
            },
            fields: [
                ...getBillingShippingFields('shipping'),
            ],
        },
    ],
}
