import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { forEach, filter, find } from 'lodash'

import { isAuthenticated } from '../../services/auth'

import { grey } from '../../styles/colors';
import { container, padding, bgImage } from '../../styles/global';
import { getOptions, filterByAttribute, generateInitialSelectState, allOptionsSelected, matchSelectedAttributes } from './utils';
import { media } from '../../styles/utils';

class VariantSelectors extends React.Component {

	state = {
		activeOptions: null,
		variants: this.props.variants,
		defaults: this.props.defaults,
	}

	componentWillMount() {
		this.setInitialSelectState()
	}

	setInitialSelectState = () => {
		const { variants, defaults } = this.state;
		const { options, additionalFields } = this.props;

		let additional = [];

		if (typeof additionalFields?.value === 'string') {
			additionalFields.value = JSON.parse(additionalFields.value)
		}
		additional = additionalFields?.value?.fields || []

		const items = [...options, ...additional]

		this.setState({
			activeOptions: generateInitialSelectState(items, defaults),
		}, () => {
			this.fetchVariant()
		})
	}

	fetchVariant = () => {
		console.log('fetching variant')
		const { activeOptions } = this.state;

		// Add variant match to state if all options selected
		if (allOptionsSelected(activeOptions)) {
			const variantMatch = this.matchVariants();

			// Match any attributes 

			if (variantMatch.length) {
				const customAttributes = activeOptions.slice(this.props.options.length, activeOptions.length).map((option) => {
					return {
						key: option.name,
						value: option.value,
					}
				})
				this.props.onMatch({
					...variantMatch[0],
					customAttributes 
				})
			}
		} else {
			this.props.onMatch(null)
		}
	}

	handleSelectChange = (selectIndex, attr, option) => {
		const { activeOptions } = this.state;

		let newOptions = activeOptions;

		activeOptions[selectIndex].value = attr; 

		this.fetchVariant(); // Check if variant match returned if all options selected

		this.setState({
			activeOptions: newOptions,
		})

		this.props.onChange(newOptions);
	}

	matchVariants = (attribute, selectIndex) => {
		const { activeOptions } = this.state;
		const { variants } = this.props;

		let results = variants;

		// Filter variants by currently selected attributes 
		const actualOptions = activeOptions.slice(0, this.props.options.length)

		forEach(actualOptions, (activeOption, i) => {
			if (activeOption.value && selectIndex !== i) {
				results = filterByAttribute(results, activeOption.value)
			}
		})

		// Then test against passed attribute 

		if (attribute) {
			results = filterByAttribute(results, attribute.slug)
		}

		return results
	}

	renderOptions = () => {
		const { activeOptions } = this.state;

		return this.props.options.map((option, selectIndex) => {
			const selectState = activeOptions[selectIndex] || false;

			const selectOptions = option.values.map((attribute, i) => {
				// Stock level checking (not in use)
				//const variantMatch = this.matchVariants(attribute, selectIndex);
				//const noStock = variantMatch.length == 1 && variantMatch[0].quantity <= 0;	

				return (
					<option
						key={i}
						value={attribute}
					//disabled={!noStock && variantMatch.length ? false : true}
					>
						{attribute}
						{/* {noStock ? ' — Out of stock' : ''} */}
					</option>
				)
			})

			return (
				<SelectContainer
					key={selectIndex}
				>
					<Title>
						{selectState?.value && selectState.value !== 'false' ? `${option.name} - ${selectState.value}` : `Select a ${option.name}`}
					</Title>

					<Select
						value={selectState?.value}
						onChange={(e) => this.handleSelectChange(selectIndex, e.target.value, option)}
					>
						<option
							disabled={!selectState?.value}
							value={false}
						>
							{!selectState?.value ? `Select a ${option.name}` : `Reset selection`}
						</option>

						{selectOptions}
					</Select>
				</SelectContainer>
			)
		})
	}

	renderAdditionals = () => {
		const { activeOptions } = this.state;
		const { additionalFields } = this.props;

		if (!additionalFields) return null;

		if (typeof additionalFields.value === 'string') {
			additionalFields.value = JSON.parse(additionalFields.value)
		}

		return additionalFields.value?.fields?.map((option, index) => {
			const selectIndex = this.props.options.length + index;
			const selectState = activeOptions[selectIndex] || false;

			const selectOptions = option.options.map((attribute, i) => {
				// Stock level checking (not in use)
				//const variantMatch = this.matchVariants(attribute, selectIndex);
				//const noStock = variantMatch.length == 1 && variantMatch[0].quantity <= 0;	

				return (
					<option
						key={i}
						value={attribute}
					//disabled={!noStock && variantMatch.length ? false : true}
					>
						{attribute}
						{/* {noStock ? ' — Out of stock' : ''} */}
					</option>
				)
			})

			return (
				<SelectContainer
					key={selectIndex}
				>
					<Title>
						{selectState?.value && selectState.value !== 'false' ? `${option.label} - ${selectState.value}` : `Select a ${option.label}`}
					</Title>

					<Select
						value={selectState?.value}
						onChange={(e) => this.handleSelectChange(selectIndex, e.target.value, option)}
					>
						<option
							disabled={!selectState?.value}
							value={false}
						>
							{!selectState?.value ? `Select a ${option.label}` : `Reset selection`}
						</option>

						{selectOptions}
					</Select>
				</SelectContainer>
			)
		})

	}

	render() {
		const { activeOptions } = this.state;

		return (
			<Container>
				{activeOptions && this.renderOptions()}
				{ this.renderAdditionals() }
			</Container>
		)
	}
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
`

// Shared

const Title = styled.div``

// Selects

const SelectContainer = styled.div`
	position: relative;
	height: 44px;
	display: flex;
	align-items: center;

	border: 1px solid #9F9F9F;
	border-radius: 0;
	padding-top: 1px;
	padding-left: 16px;
	font-size: 16px;
	
	background: white;
	box-sizing: border-box;
	user-select: none;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translateY(-50%);
		height: 8px;
		width: 8px;
		background-image: url(${require('../../assets/icons/select-arrow.svg').default});
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	${media.phone`
		max-width: none;
	`}
`

const Select = styled.select`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0; 
	padding: 0;
	min-height: 44px;

	&:focus {
		outline: none;
	}
`

export default VariantSelectors

