import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate, Link, useStaticQuery, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import styled, { css } from 'styled-components'
import posed, { PoseGroup } from 'react-pose';
import FullHeight from 'react-div-100vh';

import { fetchSettings, updateSettings, getSettings } from '../../store/settings'

import { media, isClient } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'
import { black } from '../../styles/colors';
import { withHeader } from './HeaderContext';
import { useMount, useUnmount } from 'react-use';
import { isAuthenticated } from '../../services/auth';

const Header = (props) => {
	const { headerBelow, splashActive } = props
	const [menuCollapsed, setMenuCollapsed] = useState(false)
	const [mobileMenuActive, setMobileMenu] = useState(false)

	// Footer Links

	const graphData = useStaticQuery(query)
	const data = {} // JSON.parse(graphData.wordpressGlobal.acf_json)

	useMount(() => {
		props.fetchSettings()
		window.addEventListener('scroll', handleScroll);

		if (location?.search?.indexOf('cart') !== -1) {
			props.updateSettings({
				cartActive: true,
			})
		}
	})

	useUnmount(() => {
		window.removeEventListener('scroll', handleScroll);
	})

	const toggleCart = () => {
		const { updateSettings, settings, setHeaderContext } = props;
		if (!settings.cartActive && mobileMenuActive) setMobileMenu(false)

		updateSettings({
			cartActive: !settings.cartActive,
		})
	}

	const handleScroll = () => {
		window.requestAnimationFrame(() => {
			const scrollY = window.scrollY;

			if (scrollY > 1000 && !menuCollapsed) {
				setMenuCollapsed(true)
			}

			if (scrollY < 1000 && menuCollapsed) {
				setMenuCollapsed(false)
			}
		})
	}

	const toggleMenu = () => {
		setMenuCollapsed(!menuCollapsed)
	}

	const toggleMobileMenu = () => {
		setMobileMenu(!mobileMenuActive)
	}

	const renderLogo = () => {
		return (
			<LogoWrapper>
				<Logo
					to={'/'}
				/>
				{ /*
				<LogoLine />
		*/ }
			</LogoWrapper>
		)
	}

	const renderMobileMenu = () => {
		return (
			<MobileMenu
				active={mobileMenuActive}
			>
				<Logo
					to={'/'}
				/>

				<Close
					onClick={toggleMobileMenu}
				/>

				<List>
					{renderMenuItems()}
					{renderCartItem()}
				</List>

				{renderFooterNav()}
			</MobileMenu>
		)
	}

	const renderCollapsedMenu = () => {
		const { headerTheme } = props;

		return (
			<Menu
				active={menuCollapsed}
				type={'collapsed'}
				pose={menuCollapsed ? 'open' : 'closed'}
			>
				<MenuItem
					className={'active'}
					theme={headerTheme}
					to=''
				>
					<MenuItemLine />
					Menu
				</MenuItem>
			</Menu>
		)
	}

	const renderCartItem = () => {
		const { settings, headerTheme } = props;

		return (
			<MenuItemWrapper>
				<CartItem
					onClick={toggleCart}
					className={settings.cartActive && 'active'}
					theme={headerTheme}
				>
					<MenuItemLine />
					Cart
				</CartItem>
			</MenuItemWrapper>
		)
	}


	const renderFooterNav = () => {
		const items = data.footer_menu && data.footer_menu.map((item, i) => {
			return (
				<MenuItem
					key={i}
					to={item.url}
					onClick={toggleMobileMenu}
				>
					{item.title}
				</MenuItem>
			)
		})
		return (
			<FooterNav>
				{items}

				{!isAuthenticated() && false && (
					<MenuItem
						to={'/trade-application'}
						onClick={toggleMobileMenu}
					>
						Trade Application
					</MenuItem>
				)}

				{!isAuthenticated() && false && (
					<MenuItem
						to={'/login'}
						onClick={toggleMobileMenu}
					>
						Trade Login
					</MenuItem>
				)}

				{isAuthenticated() && (
					<MenuItem
						to={'/account'}
						onClick={toggleMobileMenu}
					>
						Account
					</MenuItem>
				)}

				{isAuthenticated() && (
					<MenuItem
						onClick={() => logout()}
					>
						Logout
					</MenuItem>
				)}

			</FooterNav>
		)
	}

	const renderSubNav = (menuItem) => {
		const { headerTheme, location } = props;
		if (!menuItem.items || !menuItem.items.length) return;

		// Detect if parent URL active

		const active = isClient() && menuItem.items.length && location.pathname.includes(menuItem.link)

		const items = menuItem.items.map((item, i) => {
			return (
				<MenuItem
					key={i}
					to={item.link}
					activeClassName={'active'}
					theme={headerTheme}
				>
					{item.title}
				</MenuItem>
			)
		})

		return (
			<SubNav
				pose={active ? 'visible' : 'hidden'}
			>
				{items}
			</SubNav>
		)
	}

	const renderMenuItems = () => {
		const { headerTheme, headerBelow } = props;

		const menu = [
			{
				title: 'Projects',
				link: '/'
			},
			{
				title: 'Prefab by Archier',
				link: '/prefab'
			},
			{
				title: 'Store',
				link: '/products',
				/*
				items: [
					{
						title: 'Trade',
						link: isAuthenticated() ? '/account' : '/login'
					},
				]
				*/
			},
			{
				title: 'In Progress',
				link: '/in-progress'
			},
			{
				title: 'Studio',
				link: '/studio',
				items: [
					{
						title: 'Background',
						link: '/studio/#background'
					},
					{
						title: 'Process',
						link: '/studio/#process'
					},
					{
						title: 'People',
						link: '/studio/#people'
					},
					{
						title: 'Awards',
						link: '/studio/#awards'
					},
				]
			},
			{
				title: 'Contact',
				link: '/contact'
			},
		]

		return menu.map((item, i) => {
			return (
				<MenuItemWrapper
					key={i}
				>
					<MenuItem
						to={item.link}
						//onClick={item.onClick}
						activeClassName={!item.onClick && 'active'}
						theme={headerTheme}
						partiallyActive={item.items && true}
						onClick={() => mobileMenuActive && setMobileMenu(false)}
					>
						<MenuItemLine />
						{item.title}
					</MenuItem>

					{renderSubNav(item)}
				</MenuItemWrapper>
			)
		})
	}

	const renderMenu = () => {
		const { headerTheme, headerBelow } = props;

		return (
			<Menu
				onMouseEnter={menuCollapsed ? toggleMenu : null}
				onMouseLeave={menuCollapsed ? toggleMenu : null}
				pose={!menuCollapsed ? 'open' : 'closed'}
				headerBelow={headerBelow}
			>
				{renderMenuItems()}
				{ /* renderCartItem() */}
			</Menu>
		)
	}

	return (
		<Container
			splashActive={splashActive}
			headerBelow={headerBelow}
		>
			{renderLogo()}
			{renderMenu()}
			{renderCollapsedMenu()}

			{/* Mobile */}

			<Hamburger
				onClick={toggleMobileMenu}
			/>

			{renderMobileMenu()}
		</Container>
	)
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const List = styled.div``

// Layout

const Container = styled.div`
	${media.tablet`
		position: fixed;
		background: white;
		height: 60px;
		z-index: 10;
		width: 100%;
		top: 0;

		/* Below Page */

		${props => {
			if (props.headerBelow) return css`
				z-index: 0;
			`
		}}
	`}

	/* Hide until splash finished */

	opacity: 1;

	${props => {
		if (props.splashActive) return css`
			opacity: 0;
		`
	}}
`

// Logo

const Logo = styled(Link)`	
	display: flex;
	background-image: url(${require('../../assets/images/ARCHIER_LOGO_BLACK-2024.svg').default});
	width: 214px;
	height: 37px;
	${bgImage}
	cursor: pointer;
	z-index: 2;

	${media.tablet`
		//background-image: url(${require('../../assets/images/logo-mobile-black.svg').default});
	`}
`

const LogoLine = styled.div`
	width: 28px;
	height: 6px;
	background: ${black};

	position: absolute;
	right: 57px;
	bottom: 30px;

	transition: transform 0.4s ease;
	transform: translateY(50px);
	z-index: 1;
`

const LogoWrapper = styled.div`
	position: fixed;
	left: 30px;
	bottom: 0;
	padding-bottom: 30px;
	overflow: hidden;
	z-index: 10;

	&:hover {
		${LogoLine} {
			transform: translateY(0);
		}
	}

	${media.tablet`
		position: absolute;
		top: 15px;
		left: 24px;

		${LogoLine} {
			display: none;
		}

		${Logo} {
			width: 156px;
			height: 27px;
		}
	`}

	${media.phone`
		left: 11px;
	`}
`

// Menu

const AnimatedMenu = posed.div((props) => {
	const collapsed = props.type == 'collapsed';

	return {
		open: {
			opacity: 1,
			delay: collapsed ? 350 : 0,
			delayChildren: collapsed ? 0 : 200,
			staggerChildren: 50,
			pointerEvents: 'all',
			transition: {
				duration: 350,
			}
		},
		closed: {
			opacity: 0,
			delay: collapsed ? 50 : 250,
			staggerChildren: collapsed ? 0 : 50,
			staggerDirection: -1,
			pointerEvents: 'none',
			transition: {
				duration: 250,
			}
		}
	}
})


const Menu = styled(AnimatedMenu)`
	display: flex;
	flex-direction: column;
	z-index: 11;

	position: fixed;
	left: 30px;
	top: 30px;	
	cursor: pointer;

	/* Collapsed */

	${props => {
		if (props.type == 'collapsed') return css`
			z-index: 9;
		`
	}}

	${props => {
		if (props.type == 'collapsed' && !props.active) return css`
			${MenuItemLine} {
				transition: width 0.25s ease;
				width: 0 !important;
			}
		`

		if (props.type == 'collapsed' && props.active) return css`
			${MenuItemLine} {
				transition: 
					width 0.5s 0.25s ease,
					background 0.4s ease;
			}
		`
	}}

	/* Below Page */

	${props => {
		if (props.headerBelow) return css`
			z-index: 0;
		`
	}}

	/* Hide on tablet and below */

	${media.tablet`
		display: none;
	`}
`

// Menu Item

const MenuItemLine = styled.div`
	width: 20px;
	height: 4px;
	background: ${black};
	transform: translateY(-11px);

	transition: all 0.4s ease;
	z-index: 1;
	margin-right: 6px;
`

const MenuItemWrapper = posed.div({
	open: {
		opacity: 1,
		transition: {
			duration: 350,
		}
	},
	closed: {
		opacity: 0,
		transition: {
			duration: 200,
		}
	}
})

const menuItem = css`
	font-size: 32px;
	line-height: 38px;
	font-weight: 500;
	
	display: flex;
	align-items: flex-end;

	${MenuItemLine} {
		width: 0;
		opacity: 0;
	}

	&:hover {
		${MenuItemLine} {
			opacity: 1;
			width: calc(20px + 2px);
		}
	}

	&.active {
		${MenuItemLine} {
			opacity: 1;
			width: calc(20px + 2px);
		}
	}

	/* Theme */
	transition: all 0.4s ease;

	${props => {
		if (props.theme == 'white') return css`
			color: white;

			${MenuItemLine} {
				background: white;
			}
		`
	}}

	${media.phone`
		line-height: 44px;
	`}
`

const MenuItem = styled(Link)`
	${menuItem}
`

const CartItem = styled.div`
	${menuItem}
`

// Subnav

const AnimatedSubNav = posed.div({
	visible: {
		height: 'auto',
		opacity: 1,
		transition: {
			duration: 350,
		}
	},
	hidden: {
		height: 0,
		opacity: 0,
		transition: {
			duration: 200,
		}
	}
})

const SubNav = styled(AnimatedSubNav)`
	display: flex;
	flex-direction: column;
	padding-left: 30px;
	overflow: hidden;

	${MenuItem} {
		font-size: 24px;
		color: #7B7B7B;
		line-height: 31px;

		&:first-child {
			margin-top: 4px;
		}

		&:last-child {
			margin-bottom: 4px;
		}
	}

	${media.tablet`
		padding-left: 0;
	`}
`

// Hamburger

const Hamburger = styled.div`
	display: flex;
	background-image: url(${require('../../assets/icons/hamburger.svg').default});
	width: 37px;
	height: 23px;
	${bgImage}
	cursor: pointer;
	z-index: 2;

	position: absolute;
	right: 24px;
	top: 14px;

	display: none;

	${media.tablet`
		display: flex;
		right: 11px;
	`}

	${media.phone`
		right: 11px;
	`}
`

// Mobile Menu

const Close = styled.div`
	background-image: url(${require('../../assets/icons/hamburger-close.svg').default});
	width: 24px;
	height: 23px;
	position: absolute;
	right: 24px;

	${media.phone`
		right: 11px;
	`}
`

const FooterNav = styled.div``

const MobileMenu = styled(FullHeight)`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: black;
	z-index: 11;
	padding: 15px 24px 10px;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${Logo} {
		background-image: url(${require('../../assets/images/ARCHIER_LOGO_WHITE-2024.svg').default});
		width: 156px;
		height: 27px;
		position: absolute;
		top: 15px;
		left: 11px;
	}

	${Close} {
		position: absolute;
		top: 15px;
		right: 11px;
	}

	${List} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-top: auto;

		${MenuItem},
		${CartItem} {
			color: white;
			display: flex;
			justify-content: center;
		}

		${MenuItemLine} {
			display: none;
		}
	}

	${FooterNav} {
		margin-top: auto;
		margin-bottom: 14px;

		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		
		${MenuItem} {
			color: white;
			display: flex;
			justify-content: center;
			margin-bottom: 10px;

			font-size: 16px;
			line-height: 1.5em;

			&:not(:last-child) {
				margin-right: 16px;
			}
		}
	}

	/* Animation */
	transition: all 0.25s ease;
	opacity: 0;
	pointer-events: none;

	${props => {
		if (props.active) return css`
			pointer-events: all;
			opacity: 1;
			transition: all 0.35s ease;
		`
	}}
`

const query = graphql`
fragment SeoData on WpPostTypeSEO {
	title
	metaDesc
	opengraphDescription
	opengraphTitle
	opengraphImage {
		sourceUrl
	}
	twitterTitle
	twitterDescription
	twitterImage {
		sourceUrl
	}
}
    query HeaderQuery {
			wp {
				options {
					pageSlug
					pageTitle
					footerMenu {
						footerMenu {
							title
							link {
								title
								url
							}
						}
					}
					tradeApplicationOptions {
						tradeApplicationNotificationEmail
						tradeApplicationUseAdminNotificationEmail
					}
					tradeLogin {
						loginText
					}
				}
			}
    }
`

const mapDispatchToProps = dispatch => ({
	fetchSettings() {
		dispatch(fetchSettings())
	},
	updateSettings(setting) {
		dispatch(updateSettings(setting))
	},
})

const mapStateToProps = (state, props) => ({
	settings: getSettings(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(withHeader(Header));
