import { combineReducers } from 'redux'
import localforage from 'localforage'
import { find, reject } from 'lodash';
import { matchAttributes } from '../components/Cart/utils';
import moment from 'moment'
import Client from 'shopify-buy';

const FETCH_CART_REQUEST = 'product/FETCH_CART_REQUEST'
const FETCH_CART_SUCCESS = 'product/FETCH_CART_SUCCESS'
const FETCH_CART_FAILURE = 'product/FETCH_CART_FAILURE'
const FETCH_CART_CACHED = 'product/FETCH_CART_CACHED'

const UPDATE_CART_REQUEST = 'product/UPDATE_CART_REQUEST'
const UPDATE_CART_SUCCESS = 'product/UPDATE_CART_SUCCESS'
const UPDATE_CART_FAILURE = 'product/UPDATE_CART_FAILURE'
const UPDATE_CART_CACHED = 'product/UPDATE_CART_CACHED'

const EMPTY_CART_REQUEST = 'checkout/EMPTY_CART_REQUEST'
const EMPTY_CART_SUCCESS = 'product/EMPTY_CART_SUCCESS'
const EMPTY_CART_FAILURE = 'product/EMPTY_CART_FAILURE'


let defaultCart = [ // This should be empty in production

]


const initialiseClient = () => {
    const client = Client.buildClient({
        domain: process.env.GATSBY_CHECKOUT_DOMAIN,
        storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
    });
    return client;
}

const initialiseCheckout = async () => {
    const checkoutId = await localforage.getItem('checkoutId')

    const client = initialiseClient();

    if (checkoutId) {
        try {
            console.log('checkout reloaded')
            const checkout = await client.checkout.fetch(checkoutId)
            if (!checkout) {
                throw new Error('Checkout not found')
            }
            return checkout
        } catch (e) {
            console.log(e)
        }
    }

    // Create an empty checkout
    const checkout = await client.checkout.create()
    localforage.setItem('checkoutId', checkout.id)

    return checkout
}

const cart = (state = false, action) => {
    switch (action.type) {

        case FETCH_CART_SUCCESS:
            return action.response

        case UPDATE_CART_SUCCESS:
            /*
            localforage.setItem('cartArchier', {
                lastFetched: Date.now(),
                data: action
            })  
            */
            return action.response

        case EMPTY_CART_SUCCESS:
            return action.response

        default:
            return state
    }
}

const reducer = combineReducers({
    cart
})

export const fetchCart = () => (dispatch) => {
    initialiseCheckout().then((checkout) => {
        dispatch({
            type: FETCH_CART_SUCCESS,
            response: checkout
        })
    })
    /*
    localforage.getItem('cartArchier').then(function(cart) {
        if (cart) {
            let days = moment().diff(moment(cart.lastFetched), 'days');
            if (days > 2) localforage.removeItem('cartArchier')

            if (days <= 2) {
                dispatch({
                    type: FETCH_CART_SUCCESS,
                    response: cart.data
                })
            } else {
                dispatch({
                    type: FETCH_CART_SUCCESS,
                    response: null
                })
            }
        } else {
            dispatch({
                type: FETCH_CART_SUCCESS,
                response: null
            })
        }
    })
    */

}

export const emptyCart = () => (dispatch) => {
    dispatch({ type: EMPTY_CART_REQUEST })

    localforage.removeItem('cartArchier')

    dispatch({
        type: EMPTY_CART_SUCCESS,
        response: null
    })
}

export const addToCart = (cartItem) => dispatch => {
    dispatch({ type: UPDATE_CART_REQUEST })

    localforage.getItem('checkoutId').then(checkoutId => {

        const client = initialiseClient();

        const lineItemsToAdd = [
            {
                variantId: cartItem.storefrontId,
                quantity: 1,
                customAttributes: cartItem.customAttributes
            }
        ];

        console.log(lineItemsToAdd)

        client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
            dispatch({
                type: UPDATE_CART_SUCCESS,
                response: checkout
            })
        }).catch(e => {
            console.log(e)
        })

    })

}

export const updateQuantity = (cartItem) => dispatch => {

    dispatch({
        type: UPDATE_CART_REQUEST
    })


    localforage.getItem('checkoutId').then(checkoutId => {

        const client = initialiseClient();

        const lineItemsToUpdate = [
            {
                id: cartItem.id,
                quantity: cartItem.quantity,
            }
        ];

        client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then((checkout) => {
            dispatch({
                type: UPDATE_CART_SUCCESS,
                response: checkout
            })
        })

    })

    return;

    localforage.getItem('cartArchier').then((cart) => {
        if (cart && cart.data) {
            let oldCart = cart.data;
            let newCart;

            let oldItem = find(oldCart, (o) => {
                return o.id == cartItem.id && matchAttributes(cartItem, o)
            });

            console.log(oldItem)

            if (cartItem.quantity < 1) {
                newCart = Object.assign([], reject(oldCart, (o) => {
                    return o.id == cartItem.id && matchAttributes(cartItem, o)
                }))
            } else {
                oldItem.quantity = cartItem.quantity;
                newCart = Object.assign([], oldCart)
            }

            dispatch({
                type: UPDATE_CART_SUCCESS,
                response: newCart
            })

        } else {
            dispatch({
                type: UPDATE_CART_FAILURE
            })
        }

    })

}

export const getCart = state => state.cart

export default reducer
