import React, { useState } from 'react'
import { Router } from "@reach/router"

import { fetchData } from '../services/api';

import DefaultPage from '../templates/default-page'
import Project from '../templates/project'
//import Product from '../templates/product'

import { useSearchParam, useMount } from 'react-use';

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
	const postID = useSearchParam('id');

	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {				
				let data = response;

				console.log(data)

				setData(data)
				setPostType(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			'page': DefaultPage,
			'projects': Project,
			//'product': Product,
		}

		const Component = templates[postType];

		return (
			<Component
				previewData={data}
				pageContext={{
					...data
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

