import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'
import { maxWidth, indentedBlock } from '../../../pages/studio';

class BlockAwardsList extends React.Component {

	state = {
	
    }
    
    renderItems = () => {
        const { awardItems } = this.props;
    
        const listItems = awardItems?.map((item, i) => {
            return (
                <Item>
                    <Year>{item.year}</Year>
                    <Project>{item.project?.title}</Project>
                    <Award
                        dangerouslySetInnerHTML={{__html: item.awards}}  
                    />
                </Item>   
            )
        })

        return (
            <List>
                <ListHeader>
                    <Year>Year</Year>
                    <Project>Project</Project>
                    <Award>Awards</Award>
                </ListHeader>

                {listItems}
            </List>
        )
    }
	
	render() {
		return (
            <Container>
                <Heading>Awards</Heading>
                {this.renderItems()}
            </Container>
		)
	}
}

// Container

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${padding};
    ${container}
`

const Heading = styled.div`
    font-size: 70px;
    font-weight: 500;
    ${indentedBlock};

    ${media.phone`
        font-size: 28px;
        margin-top: 24px;
   `}
`

// List 

const Year = styled.div`
    flex: 0 1 10%;

    ${media.phone`
        flex: 0 1 20%;
    `}
`

const Project = styled.div`
    flex: 0 1 30%;
`

const Award = styled.div`
    flex: 0 1 60%;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    ${indentedBlock};
    margin-top: 61px;

    ${media.phone`
        margin-top: 32px;
    `}
`

const ListHeader = styled.div`
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #959595;

    ${Year},
    ${Project},
    ${Award} {
        font-size: 14px;
        color: #959595;
    }
`

const Item = styled.div`
    display: flex;
    padding-top: 10px;
    border-bottom: 1px solid #959595;

    ${Year},
    ${Project},
    ${Award} {
        font-size: 14px;
        line-height: 20px;
    }

    ${Project} {
        font-weight: 600;
    }
`

BlockAwardsList.wrapper = css`
    display: flex;
    justify-content: center;
`

export default BlockAwardsList
