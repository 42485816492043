import React, { useRef, useState, useLayoutEffect } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { filter, find } from 'lodash'
import ProgressiveImage from "react-progressive-image"
import VisibilitySensor from '../components/VisibilitySensor/visibility-sensor'
import posed, { PoseGroup } from "react-pose"
import FullHeight from 'react-div-100vh';

import { Layout, Block, BackgroundImage } from '../components'
import { withHeader } from '../components/Header/HeaderContext';

import { media, width, isClient, breakpoints } from '../styles/utils'
import { bgImage, container, padding, hoverState } from '../styles/global';
import { border, black, grey } from '../styles/colors';
import { useMount, useWindowScroll, useUnmount, useWindowSize, useLocation } from 'react-use';

const Project = (props) => {
    const data = props.pageContext
    const hero = React.useRef()

    const [heroTheme, setHeroTheme] = useState(black)
    const [heroScrolled, setHeroScrolled] = useState(false)
    const [fixedTitleColor, setTitleColour] = useState(black)
    const [projectPreview, setProjectPreview] = useState(data.nextProject)
    const location = useLocation()
    const {x, y} = useWindowScroll();
    const {width, height} = useWindowSize();
    
    // On Mount

    useMount(() => {
        handleScroll()

        const colorTheme = data.menuColour?.colourTheme || data.acf?.colour_theme

        setHeroTheme(colorTheme == 'white' ? 'white' : black)

        props.setHeaderContext({
            headerTheme: colorTheme
        })
    })

    // Scroll Listenr 

    useLayoutEffect(() => {   
        handleScroll()
    }, [x, y])
    
    // Resize Listenr 

    useLayoutEffect(() => {   
        handleResize()
    }, [width, height])

    // Update headerTheme on navigate

    useUnmount(() => {
        setTimeout(() => {
            props.setHeaderContext({
                headerTheme: 'black'
            })
        }, 0);
    })

    const goBack = () => {
        if (isClient() && window.history.length) {
            history.back(-1);
        } else {
            navigate('/')
        }
    }

    const navigateToProject = (slug) => {
        props.setHeaderContext({
            headerTheme: 'black'
        })

        navigate(`/projects/${slug}`)
    }
    
    const handleScroll = () => {
        const { setHeaderContext } = props;

        const colourTheme = data.menuColour?.colourTheme || data.acf?.colour_theme
        
		window.requestAnimationFrame(() => {
            let heroHeight = hero.current.clientHeight;
            const scrollY = window.scrollY;
            
            if (scrollY > heroHeight && !heroScrolled) {
                setHeroScrolled(true)

                setHeaderContext({
                    headerTheme: 'black',
                    headerBelow: width <= breakpoints.tablet && false
                })
            }

            if (scrollY < heroHeight && heroScrolled) {
                setHeroScrolled(false)

                setHeaderContext({
                    headerTheme: colourTheme,
                    headerBelow: width <= breakpoints.tablet && true
                })
            }
		})
    }
    
    const handleResize = () => {
        const { setHeaderContext, headerBelow } = props;
        
		window.requestAnimationFrame(() => {
            if (width <= breakpoints.tablet && !headerBelow) {
                setHeaderContext({
                    headerBelow: true
                })
            }

            if (width >= breakpoints.tablet && headerBelow) {
                setHeaderContext({
                    headerBelow: false
                })
            }
		})
	}

    const toggleProject = (project) => {
		setProjectPreview(project)
    }
    
    const toggleThemeOnScroll = (visible) => {
        if (heroScrolled) {
            props.setHeaderContext({
                headerTheme: visible ? 'white' : 'black'
            })
        }

        setTitleColour(visible ? 'white' : black)
    }

    const wrapVisibility = (block) => {
        return (    
            <VisibilitySensor
                partialVisibility={true}
                offset={{
                    top: 70, bottom: 0
                }}
                minTopPercent={1}
                onChange={(visible) => toggleThemeOnScroll(visible)}
            >
                {block}
            </VisibilitySensor>
        )
    }
    
    const renderHero = () => {    

        return (
            <Hero
                ref={hero}
                theme={heroTheme}
            >
                <StickyText>
                    <Heading>{data.title}</Heading>

                    {data.heroImage?.tagline && (
                        <Subheading>{data.heroImage?.tagline}</Subheading>
                    )} 
                </StickyText>

                {(data.heroImage?.heroImage?.mediaItemUrl || data.acf?.hero_image?.url) && (
                    <BackgroundImage
                        image={data.heroImage?.heroImage?.mediaItemUrl || data.acf?.hero_image?.url}
                        width={'3000px'}
                        disableSrcSet
                        disableQualityByDPR
                    />
                )}
            </Hero>
        )
    }

    const renderFixedTitle = () => {
        return (
            <FixedTitle
                visible={heroScrolled}
                color={fixedTitleColor}
            >
                {data.title}
            </FixedTitle>
        )
    }

    const renderMetadata = () => {
        const metadata = data.metaData?.metadata || data.acf?.metadata
        if (!metadata) return
    
        const items = metadata.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading>{`${item.title}: `}</Heading>
                    <Value>{item.value}</Value>
                </Item>
            )
        })
        
        return (
            <MetaData>{items}</MetaData>
        )
    }
    
    const renderSummary = () => {
        return (
            <Summary>
                {renderMetadata()}
                <Description
                    dangerouslySetInnerHTML={{__html: data.description?.description || data.acf?.description}}  
                />
            </Summary>
        )
    }

    const renderBlocks = () => {
        const dataBlocks = data.contentBlocks?.blocks || data.acf?.blocks;
		if (!dataBlocks) return;

		const blocks = dataBlocks.map((block, i) => {  
            const contentBlock = (
                <Block
					layout={block.acf_fc_layout || block.__typename}
                    key={i}
                    wrapVisibility={wrapVisibility}
					{...block}
				/>
            )

            return contentBlock 
        })
        
        return <Blocks>{blocks}</Blocks>
    }

    const renderProjectPreview = () => {
        const project = projectPreview;
        if (!project) return;

        // Render Project Preview

        const acf = {} // JSON.parse(project.acf_json);
        
        return (
            <ProjectPreview
                key={project.slug}
                onClick={() => navigate(`/projects/${project.slug}`)}
            >
                <BackgroundImage
                    image={project.heroImage?.heroImage?.mediaItemUrl}
                    sizes={'900px'}
                />

                <Heading>{project.title}</Heading>

                {project.types && (project.types.length > 0) && (
                    <Subheading>{project.types[0].name}</Subheading>
                )}
            </ProjectPreview>
        )
    }
    
    const renderNextPrev = () => {
        if (!data.prevProject && !data.nextProject) return;
        
        const projectNav = [
            {
                type: 'Prev',
                project: data.prevProject
            },
            {
                type: 'Next',
                project: data.nextProject
            }
        ]

        const navItems = projectNav.map((item, i) => {
            if (!item || !item.project) return;

            return (
                <ProjectNavItem
                    onClick={() => navigateToProject(item.project.slug)}
                    active={item.project.slug == projectPreview.slug}
                    onMouseEnter={() => toggleProject(item.project)}		
                    onMouseLeave={() => item.type == 'Prev' && toggleProject(data.nextProject)}
                >
                    <Heading>{item.type}</Heading>
                    <Subheading>Project</Subheading>
                </ProjectNavItem>
            )
        })

        return (
            <NextPrev>
                <ProjectNav>{navItems}</ProjectNav>
                <PoseGroup>
                    {renderProjectPreview()}
                </PoseGroup>
            </NextPrev>
        )
    }
       		
    return (
        <Layout
            meta={props.data?.wpProject?.seo}
            footer={true}
            hideMobileHeader={true}
        >
            <Container>
                {renderHero()}
                {renderFixedTitle()}

                <Content>
                    {renderSummary()}
                    {renderBlocks()}
                    {renderNextPrev()}
                </Content>
                
                <Close 
                    onClick={goBack}
                />
            </Container>
        </Layout>
    )
}

const LEFT_SPACING = '43vw';

// Shared

const Wrapper = styled.div``
const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
`

// Layout

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 478px;
    overflow-x: hidden;

    ${media.tablet`
        padding-bottom: 181px;
    `}

    ${media.phone`
        padding-bottom: 80px;
    `}
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
`

// Hero

const StickyText = styled.div`
    height: 100vh;
    overflow: hidden;
    padding: 30px 0 40px;
    padding-left: ${LEFT_SPACING};
    box-sizing: border-box;

    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
        height: 40vh;
    }

    ${media.tablet`
        ${padding}
        padding-bottom: 16px;
    `}  

    ${media.phone`
        padding-bottom: 0;
        padding-top: 15px;
    `}
`

const Hero = styled.div`
    height: 120vh;
    width: 100vw;
    position: relative;
    background: rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        height: 50vh;
    }

    .bg-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    ${Heading} {
        font-size: 80px;
        color: white;
        font-weight: 500;
        word-spacing: 100vw;

        ${media.phone`
            font-size: 31px;
            line-height: 35px;
        `}
    }

    ${Subheading} {
        font-weight: 500;
        font-size: 38px;
        line-height: 44px;
        color: white;

        ${media.phone`
            font-size: 20px;
            line-height: 37px;
        `}
    }

    ${props => {
        if (props.theme) return css`
            ${Heading},
            ${Subheading} {
                color: ${props.theme}
            }
        `
    }}
`

// Fixed Title

const FixedTitle = styled.div`
    position: fixed;
    top: 30px;
    left: ${LEFT_SPACING};
    z-index: 2;

    font-weight: 500;
    font-size: 42px;

    /* Animation */

    opacity: 0;
    color: ${props => props.color};
    transition: opacity 0.4s ease;

    ${props => {
        if (props.visible) return css`
            transition: 
                opacity 0.35s ease,
                color 0.15s ease;

            opacity: 1;
        `
    }}

    ${media.phone`
        display: none;
    `}
`


// Summary 

const Summary = styled.div`
    display: flex;
    padding-top: 70px;
    max-width: 922px;
    margin-bottom: 73px;
    ${padding}
    
    ${Description} {
        font-size: 20px;
        line-height: 24px;
    }

    ${media.tablet`
        max-width: none;
        padding-top: 18px;
        margin-bottom: 24px;
    `}

    ${media.phone`
        flex-direction: column;
    `}
`

// Meta Data

const Item = styled.div``
const Value = styled.div``

const MetaData = styled.div`
    flex: 1 0 322px;
    margin-right: 37px;

    ${Item} {
        display: flex;
        padding: 7px 0;

        border-top: 1px solid ${border};
        font-size: 14px;
        line-height: 18px;

        ${Heading},
        ${Value} {
            display: contents;
        }
        
        ${Heading} {
            font-weight: 600;
        }
    }   

    ${media.phone`
        flex: 1 0 auto;
        margin-bottom: 14px;
        margin-right: 0;
    `}
`

// Blocks

const Blocks = styled.div`
    width: 100%;

    ${media.tablet`
        ${padding}
        box-sizing: border-box;
    `}
`

// Prev / Next Projects

const NextPrev = styled.div`
    ${container}
    ${padding}
    flex-direction: column;
    max-width: 971px;
    align-self: flex-end;
`

// Project Nav

const ProjectNav = styled.div`
    display: flex;
    margin-top: 270px;
    margin-bottom: 38px;

    ${media.tablet`
        margin-top: 180px;
    `}

    ${media.phone`
        margin-top: 80px;
    `}
`

const ProjectNavItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 98px;
    cursor: pointer;

    ${Heading},
    ${Subheading} {
        font-weight: 500;
        font-size: 80px;
        line-height: 80px;
        transition: opacity 0.65s ease;

        ${media.tablet`
            font-size: 32px;
            line-height: 34px;
        `}
    }

    /* Animation */

    ${Heading} {
        opacity: 0.2;
    }

    ${Subheading} {
        opacity: 0;
        
        ${media.tablet`
            opacity: 0.2;
        `}
    }

    ${props => {
        if (props.active) return css`
            ${Heading},
            ${Subheading} {
                opacity: 1;
            }
        `
    }}
`

// Project Preview

const AnimatedProject = posed.div({
    enter: { 
        opacity: 1,
    },
    exit: { 
        opacity: 0,
    },
})

const ProjectPreview = styled(AnimatedProject)`
    cursor: pointer;

    .bg-image {
        width: 100%;
        height: 602px;

        ${media.tablet`
            height: auto;
            padding-bottom: 76%;
        `}
    }

	${Heading} {
		font-size: 28px;
		font-weight: 500;
		margin-top: 20px;
	}

	${Subheading} {
		font-size: 20px;
		color: ${grey};
		margin-top: 4px;
    }
` 

// Close

const Close = styled.div`
	background-image: url(${require('../assets/icons/close-white.svg').default});
	${bgImage};
	width: 32px;
	height: 32px;

	position: absolute;
	right: 24px;
	top: 32px;
    ${hoverState};
    z-index: 2;

    ${media.phone`
        width: 24px;
        height: 24px;
        right: 10px;
    	top: 14px;
    `}
`

export const query = graphql`
	query ($id: String!) {
		wpProject(id: { eq: $id } ) {
            seo {
                ...SeoData
            }
			title
            heroImage {
                heroImage {
                    mediaItemUrl
                }
            }
            contentBlocks {
                blocks {
                  __typename
                  ... on WpProject_Contentblocks_Blocks_ImageText {
                    displayBlock
                    fieldGroupName
                    image {
                      sourceUrl
                    }
                    imagePosition
                    quoteCredit
                    shortText
                    textType
                    useText
                  }
                  ... on WpProject_Contentblocks_Blocks_FullWidthImage {
                    displayBlock
                    fieldGroupName
                    image {
                      sourceUrl
                    }
                    quoteCredit
                    scalingContained
                    shortText
                    textType
                    useText
                  }
                }
              }
		}
	}
`

export default withHeader(Project)



