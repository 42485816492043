import { isAuthenticated } from "../../services/auth";
import { getPriceProps } from "../../utils/product";
import { forEach, filter } from 'lodash'

export const getCartQuantity = (cart) => {
    let quantity = 0;

    if (cart?.lineItems?.length > 0) {
        cart.lineItems.forEach((elm, i) => {
            const cartItem = elm;
            quantity = quantity + cartItem.quantity
        });
    }

    return quantity
}

export const getCartTotal = (cart, exGST) => {
    let total = 0;

    if (cart?.lineItems?.length > 0) {
        return cart.paymentDueV2.amount;
        
        cart.forEach((elm, i) => {
            const cartItem = cart[i];
            total = total + cartItem.quantity * getPriceProps(cartItem.pricing, exGST).value
        });
    }

    return total
}

export const matchAttributes = (newItem, oldItem) => {
    if (!newItem.attributes) return true;

    const match = filter(newItem.attributes, (attr) => {
        let match;

        forEach(oldItem.attributes, (attribute) => {
            if (attribute.slug == attr.slug && attribute.tax == attr.tax) {
                match = true
            }
        })

        return match
    })

    return match.length == newItem.attributes.length
}
